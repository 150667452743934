import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { Config } from 'protractor';
import { AuthService } from '../auth/auth.service';
import { Init } from '../interfaces/init';
import { FeedbackService } from '../services/feedback.service';
import { UrListService } from '../url/url.service';
import { Ajax } from '../utils/ajax';
// import Bowser from "bowser";
import * as Bowser from "bowser";
import Swal from 'sweetalert2';
import { Config } from '../interfaces/config';
import { TitlesService } from 'src/app/core/services/titles.service';
import { EventosService } from 'src/app/core/services/eventos.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  formFeedBack: FormGroup; //Formulario para FeedBack
  category: number;
  title: string;
  remarks: string;
  hasCategories: boolean = false; 
  objCategories: Categories = [];
  msgAlert: string = 'Espere un momento estamos cargando la información.'
  error_obs: boolean = false;
  txt_obs: string = '';
  title_feedback = 'feedback';

  constructor(
    public auth: AuthService,
    public FeedbackService: FeedbackService,
    private listUrl: UrListService,
    private titles: TitlesService,
    public EventosService: EventosService
  ) {
    this.formFeedBack = new FormGroup({
      categoryFeed: new FormControl(this.category ,[
        Validators.required
      ]),
      titleFeed: new FormControl(this.title ,[
        Validators.required,
        Validators.maxLength(255)
      ]),
      remarksFeed: new FormControl(this.remarks ,[
        Validators.minLength(25),
        Validators.maxLength(255)
      ])
    });

    //let location = window.location.pathname;

    //  setTimeout(()=>{
	  //    	let location_actual = window.location.pathname;
	  //    	if(location_actual == location){
            this.registrar_evento();
    //      }
    //  	}, 15000
    //);

  }

  registrar_evento(){
    this.EventosService.evento_set(false, this.titles,'feedback').then(respo => {}).catch(error => {});
 }

  ngOnInit() {
    this.titles.setTitle(this.title_feedback);
    const initAjax: Init = {
      method: 'post',
      url: this.listUrl.url('get_categorias'),
      auth: this.auth
    };
    const configAjax: Config = {
      visible: false
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.entities.length > 0) {
        this.objCategories.push({id: '', nombre: 'Seleccione Categoria'});
        for (const key in resp.entities) {
          if (Object.prototype.hasOwnProperty.call(resp.entities, key)) {
            const elem = resp.entities[key];
            this.objCategories.push(elem);
            this.hasCategories = true;
          }
        }
      } else {
        this.hasCategories = false;
        this.msgAlert = 'No tiene categorias que mostrar!'
      }
    }).catch(error => {
      return error;
    });
  }

  get categoryFeed(): any { 
    return this.formFeedBack.get('categoryFeed');
  }
  get titleFeed(): any { 
    return this.formFeedBack.get('titleFeed');
  }
  get remarksFeed(): any { 
    return this.formFeedBack.get('remarksFeed');
  }

  hideModal() {
    this.FeedbackService.closeModal.hide();
  }

  changeCategory() {
    let exist: boolean = false;
    for (const key in this.objCategories) {
      if (Object.prototype.hasOwnProperty.call(this.objCategories, key)) {
        const cat = this.objCategories[key];
        if (cat.id == this.categoryFeed.value) {
          exist = true;
          break;
        }
      }
    }

    if (!exist) {
      this.categoryFeed.setValue('');
    }
  }

  changeStr(value: string) {
    if (value == 'tit') {
      let tit = String(this.titleFeed.value);
      this.titleFeed.setValue(tit.trim());
    } else if (value == 'obs') {
      let obs = String(this.remarksFeed.value);
      let obstrim = obs.trim();
      if (obstrim.length < 25) {
        this.error_obs = true;
        this.txt_obs = 'Caracteres minimos permitidos 25.';
      } else {
        this.error_obs = false;
        this.txt_obs = '';
      }
      this.remarksFeed.setValue(obstrim);
    }
  }

  sendFeedBack() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    let so = 'Nombre del Navegador y Version: ' + browser.getBrowserName() + ', ' + browser.getBrowserVersion() + '. Sistema Operativo y Version: ' + browser.getOSName() + ', ' + browser.getOSVersion() + '. Plataforma: ' + browser.getPlatformType();
    const initAjax: Init = {
      method: 'post',
      url: this.listUrl.url('set_feedback'),
      auth: this.auth,
      body: {
        emisor: this.auth.user.idusuario,
        categoria: this.categoryFeed.value,
        so: so,
        titulo: this.titleFeed.value,
        observaciones: this.remarksFeed.value
      }
    };
    const configAjax: Config = {
      numInten : 1,
      titleLoading: `Enviando FeedBack`,
      titleSuccess: '',
      titleErrorNormal: 'Error',
      textErrorNormal: 'Ha ocurrido un Error.',
      confirmButtonTextError: 'Cerrar',
      validatejson: (resp: any, obj: Ajax) => {
        return new Promise((success, error) => {
          if (resp.success === false) {
            obj.declareConfig({
              alertaError : Swal.mixin({
                icon: 'error',
                title: 'Ocurrio un error',
                text: resp.message,
                showConfirmButton: false,
                confirmButtonText: 'Cerrar',
              })
            });
            error(resp);
          } else {
            success(resp);
          }
        });
      }
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success) {
        this.hideModal();
      }
    }).catch(error => {
      return error;
    });
  }

}

export interface Category {    ​​​
  id: number | string;
  nombre: string;
}

export interface Categories extends Array<Category> {
  [key: number]: Category;
}