import { Injectable, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Aplication, Aplications } from 'src/app/core/interfaces/aplications';
import { Company, Companys } from 'src/app/core/interfaces/company';
import { Config } from 'src/app/core/interfaces/config';
import { Init } from 'src/app/core/interfaces/init';
import { Sucursal } from 'src/app/core/interfaces/sucursal';
import { UrListService } from 'src/app/core/url/url.service';
import { Ajax } from 'src/app/core/utils/ajax';
import { Search } from 'src/app/core/utils/search';
import { Confir } from 'src/app/core/viewloader/view-loader';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseService {

  closeModal: any;
  sucursal_active = false;
  form_comapany = new FormGroup({
    'rif': new FormControl('', Validators.compose([Validators.required])),
    'razon_social': new FormControl('', Validators.compose([Validators.required])),
    'direccion_fiscal': new FormControl('', Validators.compose([Validators.required]))
  });
  form_sucursal = new FormGroup({
    'nombre_sucu':  new FormControl('', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(45)])),
    'direccion_entrega':  new FormControl('', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(200)])),
    'tlf_sucu':  new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(12)])),
    'codigo_postal':  new FormControl('', Validators.compose([Validators.required, Validators.maxLength(10)])),
    'hostname_remote':  new FormControl('', Validators.compose([Validators.required, Validators.maxLength(70)])),
    'user_remote':  new FormControl('', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(45)])),
    'password_remote':  new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(16)])),
    'dbname_remote':  new FormControl('', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(45)])),
    'port_remote':  new FormControl('', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(10)])),
    'dbname_local':  new FormControl('', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(45)])),
    'nombre_user':  new FormControl('', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(45)])),
    'apellido_user':  new FormControl('', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(65)])),
    'correo_user':  new FormControl('', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(45)])),
    'dni':  new FormControl('', Validators.compose([Validators.required])),
    'contrasena_user':  new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(16)])),
    'aplications':  new FormArray([]),
    'start' : new FormControl('', Validators.compose([Validators.required])),
    'end' : new FormControl('', Validators.compose([Validators.required])),
    'plan_actual' : new FormControl('0'),
    'status' : new FormControl('', Validators.compose([Validators.required])),
    'tz' : new FormControl('0'),
  });
  form_aplications = new FormGroup({
    'app': new FormControl('', Validators.compose([Validators.required, this.unique_app_add()])),
    'trans': new FormControl('', Validators.compose([Validators.required, Validators.maxLength(10)])),
    'transacciones_adicionales': new FormControl('', Validators.compose([Validators.maxLength(10)])),
    'restricciones': new FormControl(true),
    'vencimientos': new FormControl(true),
    'fecha_exp': new FormControl({value: new Date()})
  });
  pk: number;
  aplicacionList: Aplications = [];
  aplicacionList2 = [];
  edit: boolean;
  edit_company: Company;
  sucursal: Sucursal;  
  alert = new Confir();
  name_sucursal: string = '';
  name_empresa: string = '';
  id_sucursal: number = 0;
  id_empresa: number = 0;
  date_inicia: any;
  date_vence: any;
  planes: any;
  zonashorarias: any;

  even_newsuconfig: EventEmitter<any> = new EventEmitter();
  configs: any = []; //Muestra la lista de configuraciones especiales
  form_configs = new FormGroup({
    'asigna_suconfig': new FormControl(true),
  });
  idPlan: number;
  plan: any;
  editPlan: boolean;
  selected = '';

  constructor(public auth: AuthService,public listUrl: UrListService) {  // Estos campos se rellenan para el modal info-enterprise, como ejemplo:
  
  }

  get nombre_sucu() { return this.form_sucursal.get('nombre_sucu'); }
  get direccion_entrega() { return this.form_sucursal.get('direccion_entrega'); }
  get tlf_sucu() { return this.form_sucursal.get('tlf_sucu'); }
  get codigo_postal() { return this.form_sucursal.get('codigo_postal'); }
  get hostname_remote() { return this.form_sucursal.get('hostname_remote'); }
  get user_remote() { return this.form_sucursal.get('user_remote'); }
  get password_remote() { return this.form_sucursal.get('password_remote'); }
  get dbname_remote() { return this.form_sucursal.get('dbname_remote'); }
  get port_remote() { return this.form_sucursal.get('port_remote'); }
  get dbname_local() { return this.form_sucursal.get('dbname_local'); }
  get nombre_user() { return this.form_sucursal.get('nombre_user'); }
  get apellido_user() { return this.form_sucursal.get('apellido_user'); }
  get correo_user() { return this.form_sucursal.get('correo_user'); }
  get dni() { return this.form_sucursal.get('dni'); }
  get contrasena_user() { return this.form_sucursal.get('contrasena_user'); }
  get start() { return this.form_sucursal.get('start'); }
  get end() { return this.form_sucursal.get('end'); }
  get plan_actual() { return this.form_sucursal.get('plan_actual'); }
  get tz() { return this.form_sucursal.get('tz'); }
  get status() { return this.form_sucursal.get('status'); }
  get apparray() { return this.form_sucursal.get('aplications') as FormArray; }

  get_aplication_form(): FormArray {
    return this.form_sucursal.get('aplications') as FormArray;
  }

  checkAppSelect(app: string){
    return this.aplicacionList2.indexOf(app);
  }

  add_aplicacions(value1='', value2='', number_max=9999, disabled=false, value3='', value4=true, value5=true, value6=''){
    const apps = this.get_aplication_form();
    if (apps.length <= number_max) {
      apps.push(new FormGroup({
        'app': new FormControl({value: value1, disabled: disabled}, Validators.compose([Validators.required, this.unique_app()])),
        'trans': new FormControl({value: value2, disabled: disabled}, Validators.compose([Validators.required, Validators.maxLength(10)])),
        'transacciones_adicionales': new FormControl({value: value3, disabled: disabled}, Validators.compose([Validators.maxLength(10)])),
        'restricciones': new FormControl({value: value4, disabled: disabled}),
        'vencimientos': new FormControl({value: value5, disabled: disabled}),
        'fecha_exp': new FormControl({value: value6==''?new Date():new Date(value6), disabled: true})
      }));
      if (this.aplicacionList2.indexOf(value1) === -1) {
        this.aplicacionList2.push(value1);
      }
    }
  }

  aplicationsDefault(){
    const aplications = this.get_aplication_form();
    aplications.controls = [];
    this.add_aplicacions();
    this.form_sucursal.controls.dbname_local.enable();
  }

  select_csucursal(key: any) {
    this.form_sucursal.reset();
    this.sucursal = null;
    if (key !== '') {
      this.selected = key;
      this.form_sucursal.enable();
      this.sucursal = this.edit_company.sucursales[key];
      this.get_list_suconfig(this.sucursal.idsucursal);
      this.form_sucursal.controls.nombre_sucu.setValue(this.sucursal.nombre_sucu);
      this.form_sucursal.controls.plan_actual.setValue(this.sucursal.plan);
      this.form_sucursal.controls.direccion_entrega.setValue(this.sucursal.direccion_entrega);
      this.form_sucursal.controls.tlf_sucu.setValue(this.sucursal.tlf_sucu);
      this.form_sucursal.controls.codigo_postal.setValue(this.sucursal.codigo_postal);
      this.form_sucursal.controls.status.setValue(this.sucursal.estatus);
      this.form_sucursal.controls.tz.setValue(this.sucursal.zona_horaria_id);
      this.form_sucursal.controls.password_remote.setValidators([Validators.minLength(6), Validators.maxLength(16)]);
      this.form_sucursal.controls.contrasena_user.setValidators([Validators.minLength(6), Validators.maxLength(16)]);
      if (this.sucursal.bd_remote != null) {
        this.form_sucursal.controls.hostname_remote.setValue(this.sucursal.bd_remote.hostname);
        this.form_sucursal.controls.user_remote.setValue(this.sucursal.bd_remote.user);
        this.form_sucursal.controls.dbname_remote.setValue(this.sucursal.bd_remote.dbname);
        this.form_sucursal.controls.port_remote.setValue(this.sucursal.bd_remote.port);
      }
      if (this.sucursal.bd_local) {
        this.form_sucursal.controls.dbname_local.setValue(this.sucursal.bd_local.dbname)
        this.form_sucursal.controls.dbname_local.disable();
      }
      this.form_sucursal.controls.nombre_user.setValue(this.sucursal.usuario.nombre_user);
      this.form_sucursal.controls.apellido_user.setValue(this.sucursal.usuario.apellido_user);
      this.form_sucursal.controls.correo_user.setValue(this.sucursal.usuario.correo_user);
      this.form_sucursal.controls.start.setValue(new Date(this.sucursal.fecha_inicia));
      this.form_sucursal.controls.start.disable();
      this.form_sucursal.controls.end.setValue(new Date(this.sucursal.fecha_vence));
      this.form_sucursal.controls.end.disable();
      this.form_sucursal.controls.dni.setValue(this.sucursal.usuario.dni);
      const aplications = this.get_aplication_form();
      aplications.controls = [];
      for (const key in this.sucursal.aplicaciones) {
        if (this.sucursal.aplicaciones[key].transacciones == '-1') {
          this.add_aplicacions(
            this.sucursal.aplicaciones[key].idapp, 
            'Ilimitado', 
            this.sucursal.aplicaciones.length, false, 
            this.sucursal.aplicaciones[key].transacciones_adicionales,
            false,
            this.sucursal.aplicaciones[key].vencimientos=='1'?true:false,
            this.sucursal.aplicaciones[key].fecha_exp);
        } else {
          this.add_aplicacions(
            this.sucursal.aplicaciones[key].idapp, 
            this.sucursal.aplicaciones[key].transacciones, 
            this.sucursal.aplicaciones.length, false, 
            this.sucursal.aplicaciones[key].transacciones_adicionales,
            this.sucursal.aplicaciones[key].restricciones=='1'?true:false,
            this.sucursal.aplicaciones[key].vencimientos=='1'?true:false,
            this.sucursal.aplicaciones[key].fecha_exp);
        }
      }
    } else {
      this.form_sucursal.disable();
    }
  }

  select_company() {
    this.form_comapany.controls.rif.setValue(this.edit_company.rif);
    this.form_comapany.controls.razon_social.setValue(this.edit_company.razon_social);
    this.form_comapany.controls.direccion_fiscal.setValue(this.edit_company.direccion_fiscal);
  }

  select_csucursal_app(key: any) {
    this.sucursal = null;
    if (key != '') {
      this.sucursal = this.edit_company.sucursales[key];
    }
  }

  remove_aplications(key: number){
    const apps = this.get_aplication_form();
    const index = this.aplicacionList2.indexOf(apps.controls[key].value.app);
    if (index) {
      if (index > -1) {
        this.aplicacionList2.splice(index, 1);
      }
    }
    apps.removeAt(key);
  }

  unique_app(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null  => {
      const controladores = control.parent;
      if (typeof controladores !== 'undefined' && typeof controladores.parent !== 'undefined') {
        const controls = this.get_aplication_form();
        if (control.value != '') {
          const repeat_controls = Search.filter(controls.controls, (x: FormGroup, e) => {
            if (x.controls.app.value == e) {
              return true;
            }
            return false;
          }, control.value);
          if (repeat_controls.length > 1) {
            control.setValue('');
            return {forbiddenName: {value: control.value}};;
          } else {
            if (this.aplicacionList2.indexOf(control.value) === -1) {
              this.aplicacionList2.push(control.value);
            }
            return null;
          }
        }
      }
      return null;
    };
  }

  unique_app_add(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null  => {
      if ( typeof this.sucursal !== 'undefined' && this.sucursal != null) {
        if (control.value != '' && typeof this.sucursal.aplicaciones !== 'undefined') {
          const repeat_controls = Search.filter(this.sucursal.aplicaciones, (x: Aplication, e) => {
            if (x.idapp == e) {
              return true;
            }
            return false;
          }, control.value);
          if (repeat_controls.length >= 1) {
            control.setValue('');
            this.alert_valid_app();
            return {forbiddenName: {value: control.value}};
          } else {
            if (this.aplicacionList2.indexOf(control.value) === -1) {
              this.aplicacionList2.push(control.value);
            }
            return null;
          }
        }
      }else{
        if (control.value) {
          control.setValue('');
          Swal.fire({
            icon: 'error',
            title: 'Error al seleccionar aplicación',
            html: 'Debe Seleccionar una sucursal primero'
          });
          return {forbiddenName: {value: control.value}};
        }else{
          return null;
        }
      }
      return null;
    };
  }

  eliminar_company(company: Company): Promise<any> {
    return new Promise((resolve, reject) => {
      this.alert.confir(`Quiere inactivar la empresa ${company.razon_social}`, `Inactivar Empresa`).then(() => {
        this.detele_company(company.idempresas).then(resp => {
          resolve(resp);
        });
      });
    });
  }

  clear_databases(sucursals: Array<any>, empresa_delete: Array<any>): Promise<any> {
    return new Promise((resolve, reject) => {
      const body: any = {};
      if (empresa_delete.length) {
        body.empresas_id = empresa_delete;
      }
      if (sucursals.length) {
        body.sucursals_id = sucursals;
      }
      this.alert.confir('Realmente quieres borrar esta informacion ? ', 'Limpiar Data').then(() => {
        this.set_data('clear_datas', body, resolve, {});
      });
    });
  }

  set_data(url: string, data: any, func: any, config: Config, meth: any = 'post', tipo: any = 'json') {
    const init: Init = {
      url: this.listUrl.url(url),
      auth: this.auth,
      body: data,
      method: meth,
      httpOptions: {
        responseType: tipo
      }
    }
    const ajax = new Ajax(init, config);
    ajax.call().then(resp => {
      func(resp, this);
    }).catch(error => {
    });
  }

  detele_sucursal(pk): Promise<any> {
    return new Promise((resolve, reject) => {
      this.set_data('remove_sucursal', {sucursal_id: pk}, resolve, {});
    });
  }

  detele_company(pk): Promise<any> {
    return new Promise((resolve, reject) => {
      this.set_data('remove_empresa', {empresa_id: pk}, resolve, {});
    });
  }

  register_sucursal(): Promise<any> {
    return new Promise((resolve) => {
      if (this.edit) {
        this.form_sucursal.controls.contrasena_user.setValidators([Validators.minLength(6), Validators.maxLength(16)]);
        this.form_sucursal.controls.password_remote.setValidators([Validators.minLength(6), Validators.maxLength(16)]);
      } else {
        this.form_sucursal.controls.contrasena_user.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(16)]);
        this.form_sucursal.controls.password_remote.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(16)]);
      }
      this.form_sucursal.controls.contrasena_user.updateValueAndValidity();
      this.form_sucursal.controls.password_remote.updateValueAndValidity();
      console.log(this.form_sucursal);
      if (this.form_sucursal.valid && this.form_sucursal.status === 'VALID') {
        let body = this.form_sucursal.getRawValue();
        if (typeof body.aplications !== 'undefined') {
          body.aplications = body.aplications.reduce((a: any,b: any) => {
            if (typeof b.fecha_exp !== 'undefined' && b.fecha_exp !== '') {
              // b.fecha_exp = b.fecha_exp.toISOString().slice(0, 19).replace('T', ' ');
              if (typeof body.start === 'undefined' && typeof body.end === 'undefined') {
                b.fecha_exp = this.sucursal.fecha_vence.toISOString().slice(0, 19).replace('T', ' ');
              } else {
                b.fecha_exp = body.end.toISOString().slice(0, 19).replace('T', ' ');
              }
            }
            b.restricciones = b.restricciones?1:0;
            b.vencimientos = b.vencimientos?1:0;
            //Usado para las restricciones de gestion de usuarios vendedor
            if (b.app == 36) {
              if (b.restricciones == 0) {
                b.trans = -1;
              }
            }
            a.push(b);
            return a;
          }, []);
        }
        if (this.edit && this.sucursal && this.edit_company) {
          if (typeof body.start === 'undefined' && typeof body.end === 'undefined') {
            body.start = this.sucursal.fecha_inicia;
            body.end = this.sucursal.fecha_vence;
          } else {
            body.start = body.start.toISOString().slice(0, 19).replace('T', ' ');
            body.end = body.end.toISOString().slice(0, 19).replace('T', ' ');
          }
          body.usuario_id = this.sucursal.usuario.idusuario;
          body.sucursal_id = this.sucursal.idsucursal;
          body.db_remote_id = this.sucursal.bd_remote.iddatabase;
          body.apps_remove = [];
          body.apps_add = [];
          const controls = this.get_aplication_form();
          let apps_b = this.sucursal.aplicaciones.reduce((a: any,b: Aplication) => {
            a.push(b.idapp);
            return a;
          }, []);
          let apps_a = controls.controls.reduce((a: any,b: FormGroup) => {
            a.push(b.controls.app.value);
            if (!apps_b.includes(b.controls.app.value)) {
              body.apps_add.push(b.value);
            }
            return a;
          }, []);
          Search.forEachAll(apps_b, (element, key, result, next) => {
            if (!apps_a.includes(element)) {
              body.apps_remove.push(element);
            }
            next();
          }, (all) => {
            this.set_data('update_sucursal', body, resolve, {});
          }, true);
        } else {
          body.start = body.start.toISOString().slice(0, 19).replace('T', ' ');
          body.end = body.end.toISOString().slice(0, 19).replace('T', ' ');
          body.company_id = this.pk;
          this.set_data('register_sucursal', body, resolve, {});
        }
      } else {
        this.alert_valid();
      }
    });
  }

  alert_valid() {
    Swal.fire({
      icon: 'error',
      title: 'Error Formulario',
      html: 'Requiere Llenar todos los campos'
    });
  }

  alert_valid_app() {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      html: 'La Aplicacion ya esta en uso'
    });
  }

  alert_valid_select() {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      html: 'Selecione una sucursal valida'
    });
  }

  alert_error_delete_sucursal() {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      html: 'Error al eliminar sucursal'
    });
  }

  register_company(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.edit) {
        if (this.form_comapany.valid && this.form_comapany.status === 'VALID') {
          let body = this.form_comapany.value;
          body.company_id = this.edit_company.idempresas;
          this.set_data('update_compnay', body, resolve, {});
        } else {
          this.alert_valid();
        }
      } else {
        if (this.sucursal_active) {
          if (this.form_comapany.valid && this.form_comapany.status === 'VALID' && this.form_sucursal.valid && this.form_sucursal.status === 'VALID') {
            let body = Object.assign(this.form_comapany.value, this.form_sucursal.value);
            body.start = body.start.toISOString().slice(0, 19).replace('T', ' ');
            body.end = body.end.toISOString().slice(0, 19).replace('T', ' ');
            this.set_data('register_sucursal', body, resolve, {});
          } else {
            this.alert_valid();
          }
        } else {
          if (this.form_comapany.valid && this.form_comapany.status === 'VALID') {
            this.set_data('register_company', this.form_comapany.value, resolve, {});
          } else {
            this.alert_valid();
          }
        }
      }
    });
  }


  register_aplicacion(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.sucursal) {
        if (this.form_aplications.valid && this.form_aplications.status === 'VALID') {
          let body: any = {sucursal_id: this.sucursal.idsucursal};
          body.aplications = [this.form_aplications.getRawValue()];
          body.aplications[0].fecha_exp = body.aplications[0].fecha_exp.toISOString().slice(0, 19).replace('T', ' ');
          body.aplications[0].restricciones = body.aplications[0].restricciones?1:0;
          body.aplications[0].vencimientos = body.aplications[0].vencimientos?1:0;
          body.db_id = this.sucursal.bd_remote.iddatabase;
          this.set_data('register_aplication', body, resolve, {});
        } else {
          this.alert_valid();
        }
      } else {
        this.alert_valid_select();
      }
    });
  }

  get_aplications() {
    const config : Config = {
      visible: false,
      autoReNew: true
    }
    this.set_data('get_aplications', {}, this.sucess_aplications, config);
  }

  sucess_aplications(resp: any, obj: any) {
    obj.aplicacionList = resp.entity;
  }

  get_list_suconfig(sucursal_id){
		const init: Init = {
			method: 'post', 
			auth: this.auth,
			url: this.listUrl.url('get_all_suconfig_list'),
			body: {sucursal_id}
		};
		const config: Config = {visible: false};
		const ajax = new Ajax(init, config);
		ajax.call().then(resp => {
      this.configs = resp['sucursales']['configs'];
		}).catch(err => {

		});
	}

}
