import { Component, OnInit } from '@angular/core';
import { NotificacionService } from 'src/app/core/notifications/notificacion.service';
import { session } from 'src/app/core/constantes/sessiones';
import { Cache } from 'src/app/core/utils/cache';
import { Ajax } from 'src/app/core/utils/ajax';
import { Init } from 'src/app/core/interfaces/init';
import { UrListService } from 'src/app/core/url/url.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Config } from 'src/app/core/interfaces/config';
import Swal from 'sweetalert2';
import { Search } from 'src/app/core/utils/search';
import { ViewLoader } from 'src/app/core/viewloader/view-loader';
import { Title } from '@angular/platform-browser';
import { EventosService } from 'src/app/core/services/eventos.service';
import { TitlesService } from 'src/app/core/services/titles.service';

@Component({
  selector: 'app-notification-compo',
  templateUrl: './notification-compo.component.html',
  styleUrls: ['./notification-compo.component.scss'],
})
export class NotificationCompoComponent extends ViewLoader implements OnInit {
   all_notification: any;
   inputsearch: any;

   displaySearch: any;
   getScroll: any;
   scrollToTop: any;
   loadMore: any;

   title : string = 'Notificaciones';
   resp_field = 'entities';
   fields_search = ['notificacion_id', 'notificacion','contenido'];
   field_norepeat = (resp: any) => {
     return resp.notificacion_id;
   };
   remote = false;

   limit_show = 40;
   not_item = "No hay Notificaciones actualmente.";
   not_search = "No se encontraron resultados para tu busqueda.";

  confirmar = Swal.mixin({
    icon: 'question',
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Confirmar',
    confirmButtonAriaLabel: 'Cancelar',
    cancelButtonText: 'Cancelar',
    cancelButtonAriaLabel: 'Cancelar'
  });

  constructor(public NotificacionService: NotificacionService,
  	public auth: AuthService,
  	private listUrl: UrListService,
  	private titles: TitlesService,
    public EventosService: EventosService) { 

      super();
	    this.search_in_back = false;
	    this.of = 0;
	    this.to = 500;
	    this.numItem = 500;
	    this.msg_init = this.not_item;
	    this.msg_search = this.not_search;
	    this.msg_show = this.msg_init;

      this.initLoader = {
        method: 'post',
        auth: this.auth,
        url: this.listUrl.url('todas_notificaciones'),
        body: {
          'usuario_id' :  this.auth.user.idusuario}
        };
      this.configLoader = {
        visible: false,
        titles: this.titles,
        autoReNew: true
      };

      this.initScrolin = Object.assign({}, this.initLoader);
      this.initSearch = Object.assign({}, this.initLoader);
      this.configSearch = {titleSuccess: '', visible: true, force_update: true};
      this.confiScrolin = Object.assign({}, this.configLoader);
      this.load_items();

    //  let location = window.location.pathname;
    this.registrar_evento();
      setTimeout(()=>{
	    //  	let location_actual = window.location.pathname;
	    //  	if(location_actual == location){this.registrar_evento();}
          this.NotificacionService.even_nuevos_msj.emit({});
      	}, 15000
      );
	}

  registrar_evento(){
    this.EventosService.evento_set(false, this.titles,'notificaciones_ver').then(respo => {}).catch(error => {});
  }

  getId(){
    return '53';
  }

  ngOnInit() {
    this.titles.setTitle(this.title);
  }

  cargarDatos(){
    this.initLoader = {
        method: 'post',
        auth: this.auth,
        url: this.listUrl.url('todas_notificaciones'),
        body: {'usuario_id' :  this.auth.user.idusuario}
      };
      this.configLoader = {
        visible: false,
        titles: this.titles,
        autoReNew: true
      };

      this.initScrolin = Object.assign({}, this.initLoader);
      this.initSearch = Object.assign({}, this.initLoader);
      this.configSearch = {titleSuccess: '', visible: true, force_update: true};
      this.confiScrolin = Object.assign({}, this.configLoader);
      this.load_items();
  }

  /*async todas_notificaciones(visible=true){ 
        const initAjax: Init = {
            method: 'post',
            url: this.listUrl.url('todas_notificaciones'),
            auth: this.auth,
            body: {
            	'usuario_id' :  this.auth.user.idusuario ,      
            }
        };
        const configAjax: Config = {
            visible: visible
        };

        const ajax = new Ajax(initAjax, configAjax);
        ajax.call().then(respo => {
            if(respo.success){
              console.log(respo.entities);
               // this.NotificacionService.all_notificacion = respo.entities;
              this.NotificacionService.all_notificacion = respo.entities;
              this.all_notification = respo.entities;
            }
        }).catch(error => {
            console.log(error);
        });
    }*/

    mostrar(){
      console.log(this.all_notification);
    }

    deletevalObj(pk,index) {
    const confirDelete = `Realmente desea eliminar la siguiente notificacion?`;
    this.confir(() => {
      this.close_confirmar().then(() => {
        this.removeObj(pk,index);
      });
    }, 'Desea Eliminar?',
    confirDelete);
  }

  confir(action: () => void, title: string, templa: string) {
    this.confirmar.fire({
      preConfirm: action,
      html: templa,
      title: `<strong>${title}</strong>`
    });
  }

  close_confirmar(): Promise<boolean> {
    return new Promise((success, error) => {
      let checkAlert = setInterval(() => {
        if (!this.confirmar.isVisible()) {
          success(true);
          clearInterval(checkAlert);
          checkAlert = null;
        }
      }, 500);
    });
  }

  removeObj(code,index) {
   const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('borrar_notificacion'),
        auth: this.auth,
        body: {
          'id' :  code,
          'usuario_id': this.auth.user.idusuario      
          }
        };
    const configAjax: Config = {
      visible:true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(respo => {
      if(respo.success){
        this.cargarDatos();
    }
    }).catch(error => {
       console.log(error);
     });
    }

  /*busqueda(){
  
    if(this.NotificacionService.all_notificacion != null){
      this.NotificacionService.all_notificacion = Search.filter(this.all_notification,(x, e) => {
                      return Search.any([Search.search(x.notificacion, e)
          ] );

      }, this.inputsearch.toLowerCase());
    }
    else{
        this.NotificacionService.all_notificacion = this.all_notification;this.all_notification
    }  
  }*/
  
}
