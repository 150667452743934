import { Init } from '../interfaces/init';
import { Config } from '../interfaces/config';
import { Ajax } from '../utils/ajax';
import { Search } from '../utils/search';
import { Component, EventEmitter, Input, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { Actions, InputSearchComponent } from '../input-search/input-search.component';

export class Confir {

    confirmar = Swal.mixin({
        icon: 'question',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
          'Confirmar',
        confirmButtonAriaLabel: 'Cancelar',
        cancelButtonText:
          'Cancelar',
        cancelButtonAriaLabel: 'Cancelar'
      });

    con(action: () => void, title: string, templa: string, icon: any) {
        this.confirmar.fire({
          icon,
          preConfirm: action,
          html: templa,
          title: `<strong>${title}</strong>`
        });
    }
    
    close_confirmar(): Promise<boolean> {
        return new Promise((success, error) => {
          let checkAlert = setInterval(() => {
            if (!this.confirmar.isVisible()) {
              success(true);
              clearInterval(checkAlert);
              checkAlert = null;
            }
          }, 500);
        });
      }

    confir(html: string, title: string, icon = 'question'): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.con(() => {
                this.close_confirmar().then(() => {
                    resolve(true);
                });
            }, title, html, icon);
        });
    }

};

export class Utiliti {

    element: any; //Elemento donde se hace scroll
    show_totop = false; //Variable para mostrar el boton de subir al llegar al limite establecido
    limit_show = 200; // Limite para mostrar el scroll
    activar = false; // Variable para activar el proceso. true para activar.

    scrollinit(e){
        if (this.element == undefined && this.activar) {
          this.element = e.target;
        }
        if (this.activar) {
            if (e.target.scrollTop > this.limit_show && !this.show_totop){
              this.show_totop = true;
            }
            if (e.target.scrollTop <= this.limit_show && this.show_totop){
              this.show_totop = false;
            }    
        }
    }

    v_empty(txt: string):Promise<string> {
        return new Promise((rl, rj) => {
            if (typeof txt != 'undefined') {
                let v = txt.replace("&nbsp;", "");
                v = typeof v == 'undefined' ? "" : v;
                if (!v || 0 === v.trim().length) {
                    rj('');
                }
                else {
                    rl(v);
                }
            } else {
                rj('');
            }
        });
    }

}
@Component({
    template: ''
})
export class ViewLoader extends Utiliti {

    item_show: Array<any>; // items que se muestran
    elements_loaded: EventEmitter<any> = new EventEmitter();
    event_refresh: EventEmitter<any> = new EventEmitter();
    event_search: EventEmitter<any> = new EventEmitter(); // evento que avisa cuando se realiza una búsqueda
    item_paginator: Array<any>; // items agrupados y dividios
    item_base: Array<any>; // items bases siempre son fijos hasta que se cargan nuevos
    item_search: Array<any>; // items para buscar, se sumplantan con cada busqueda
    of = 0; // numero de inicio para traer registros
    to = 500; // numero final para el limite de registros
    numItem = 500; // numero maximo de registros por busqueda
    search = ''; // input search

    initLoader: Init; // init del ajax para la carga inicial
    configLoader: Config; // config del ajax para la carga inicial
    initSearch: Init; // init del ajax para la busqueda
    configSearch: Config; // config del ajax para la busqueda
    initScrolin: Init; // init del ajax para cuando dan scroll
    confiScrolin: Config; // config del ajax para cuando dan scroll

    resp_field: string; // campo que respondera los ajax cuando busquen en el back
    to_field = 'to'; // campo que se envia al back para decir el inicio de los registros
    of_field = 'of'; // campo que se envia al back para decir el fin de los registros
    search_field = 'search'; // campo que se envia el back para buscar
    field_norepeat: string | Function;
    lmi = true;
    li = true;
    sb = true;
    uli = true;
    create: boolean; // Campo usado para saber si tiene o no permiso de crear en los catalogos
    regresa: boolean; // Campo usado para saber si el catalogo debe regresar a otra URL
    msg_search: string | null = null; // para Guardar el texto a ser mostrado cuando busque y no encuentre nada
    msg_init: string | null = null; // para guardar texto inicial al querer mostrar items y no tenga registros
    msg_show: string | null = null; //Para guardar el texto a mostrar
    resp_back: any; //Campo para guardar la respuesta del back
    fields_search: Array<string>; // campos que se usaran para la busqueda rapida
    search_in_back: boolean; // Variable para determinar si hacer la busqueda en backend al dar enter o no.
    limit_show : number | null = 100; // limita el numero de items mostrados por lote
    number_page: number; // numero de pagina actual
    number_page_search: number; // numero de pagina cuando buscas
    number_page_max: number = null; // numero de pagina maximo depende del objeto
    remote: boolean = false; //  indica si seran busquedas locales o busquedas remotas, el paginador solo funciona con busquedas locales de momento
    button_load_more: boolean = false; // boton de cargar mas para mostrar mas
    type_paginator: 'CHARGEMORE' | 'PAGINATIONS' = 'CHARGEMORE'; // tipo de paginador
    load_rady = false; // Indica si sera mostrado el cargando
    max_render_memory = 8; // numero maximo de paginas a renderizar de la memoria si es tipo CHARGEMORE
    auto_order_first_camp: string | null = null;
    filter_apli = false;
    filter_base: Array<any> | null = null;

    loader_objet: any;

    @ViewChild(InputSearchComponent)
    private input_search!: InputSearchComponent;

    constructor() {
        super();
        this.item_base = [];
        this.item_search = [];
        this.item_show = [];
        this.search_in_back = true;
    }

    search_events(){
        if (this.input_search) {
            this.input_search.actions.subscribe((resp: Actions) => {
                this.search = resp.search;
                this.search_speed(resp.event);
            });
        }
    }

    array_chunck(items: Array<any>, len: number, order: string | null=null, type_order: 'DESC' | 'ASC' = 'ASC'): Array<any> {
        let items_ready = items;
        if (order) {
            let items_ready = items.sort((a: any, b: any) => {
                if (typeof a[order] === 'string' || typeof a[order] === 'boolean') {
                    if (a[order] > b[order]) {
                        if (type_order == 'ASC') {
                            return 1;
                        } else {
                            return -1;
                        }
                    }
                    if (a[order] < b[order]) {
                        if (type_order == 'ASC') {
                            return -1;
                        } else {
                            return 1;
                        }
                    }
                    return 0;
                } else {
                    if (type_order == 'ASC') {
                        return a - b;
                    } else {
                        return b - a;
                    }
                }
            });
        }
        var chunks = [], i = 0, n = items.length;
        while (i < n) {
          chunks.push(items_ready.slice(i, i += len));
        }
        return chunks;
    }

    array_dischunck(items: Array<any>): Array<any> {
        let items_rady = [];
        for (const arr of items) {
            items_rady = items_rady.concat(arr);
        }
        return items_rady
    }

    add_body(init: Init, to: number, of: number): Promise<Init> {
        return new Promise((rs, rj) => {
            if (this.remote) {
                if (typeof init.body === 'undefined') {
                    init.body = { 
                        [this.to_field]: to,
                        [this.of_field]: of
                    };
                    if (this.search != '') {
                        init.body[this.search_field] = this.search;
                    }
                    rs(init);
                } else {
                    init.body[this.to_field] = to;
                    init.body[this.of_field] = of;
                    if (this.search != '') {
                        init.body[this.search_field] = this.search;
                    }
                    rs(init);
                }
            } else {
                rs(init);
            }
        });
    }

    charge_items(resp: any) {
        if (this.remote == false) {
            const item_show = this.array_chunck(resp, this.limit_show, this.auto_order_first_camp);
            if (item_show.length) { 
                this.number_page = 1;
                this.number_page_max = item_show.length;
                this.item_paginator = [].concat(item_show);
                if (this.item_paginator.length) {
                    if (this.item_paginator.length > 1) {
                        this.button_load_more = true;
                    }
                    this.item_show = [].concat(this.item_paginator[this.number_page - 1]);
                } else {
                    this.item_show = [];
                    this.item_paginator = [];
                }
            } else {
                this.item_show = [].concat(resp);
                this.item_paginator = [];
            }
        } else {
            this.item_show = [].concat(resp);
            this.item_paginator = [];
        }
        this.item_search = [].concat(resp);
        this.item_base = [].concat(resp);
    }

     load_items() {
        if (this.li) {
            this.li = false;
            this.button_load_more = false;
            this.load_rady = true;
            this.add_body(this.initLoader, this.to, this.of).then( rp => {
                const ajax = new Ajax(rp, this.configLoader);
                ajax.call().then(resp => {
                    this.loader_objet = resp;
                    if (resp.success) {
                        if (typeof resp[this.resp_field] != 'undefined') {
                            this.charge_items(resp[this.resp_field]);
                        }
                        if (typeof resp['create'] != 'undefined') {
                            this.create = resp['create'];
                        }
                        if (typeof resp['regresa'] != 'undefined') {
                            this.regresa = resp['regresa'];
                        }
                        this.resp_back = resp;
                    }
                    this.load_rady = false;
                    this.li = true;
                    this.elements_loaded.emit(true);
                }).catch(error => {
                    this.li = true;
                    this.load_rady = false;
                    this.elements_loaded.emit(false);
                });
            });
        }
    }

    fielnorepeat(obj): number {
        if (typeof this.field_norepeat == 'function') {
            return this.field_norepeat(obj);
        } else {
            return obj[this.field_norepeat];
        }
    }

    update_load_items() {
        if (this.uli) {
            this.uli = false;
            const of = 0;
            const to = this.item_base.length;
            this.add_body(this.initLoader, to, of).then( rp => {
                let config = Object.assign({}, this.configLoader);
                config.force_update = true;
                const ajax = new Ajax(rp, config);
                ajax.call().then(resp => {
                    if (resp.success) {
                        
                        if (typeof resp[this.resp_field] != 'undefined') {
                            const number = this.number_page;
                            this.charge_items(resp[this.resp_field]);
                            if (this.search != '') {
                                this.search_speed({keyCode: 12});
                            }
                            if (this.item_show.length) {
                                if (this.type_paginator == 'CHARGEMORE') {
                                    this.number_page = number;
                                    this.render_page();
                                }
                            }
                        }
                    }
                    this.uli = true;
                    this.event_refresh.emit(true);
                  }).catch(error => {
                    this.uli = true;
                  });
            });
        }
        
    }

    render_page() {
        for (var i = 0; i < this.number_page; i++) {
            if (i <= this.max_render_memory) {
                if (i == 0) {
                    this.item_show = [].concat(this.item_paginator[i]);
                } else {
                    if (i <= (this.item_paginator.length - 1) && typeof this.item_paginator[i] !== 'undefined') {
                        this.item_show = this.item_show.concat(this.item_paginator[i]);
                    }
                }
            }
        }
        if (this.number_page >= this.number_page_max) {
            this.button_load_more = false;
            this.number_page = this.number_page_max;
        }
    }

    no_repeat_object(bs: Array<any>, nv: Array<any>):Promise<Array<any>> {
        return new Promise((rl, rj) => {
            Search.forEachAll(nv, (element, key, result, next) => {
                let position = Search.position(bs, (ele, value)=>{
                    return Search.igual(this.fielnorepeat(ele), value);
                }, this.fielnorepeat(element));
                if ( position != -1) {
                    bs[position] = element;
                    next();
                } else {
                    bs.push(element);
                    next();
                }
            }, (all) => {
                rl(bs);
            }, true);
        });
    }

    load_more_item() {
        if (this.lmi && this.remote) {
            this.lmi = false;
            const of = this.item_show.length;
            const to = of + this.numItem;
            this.add_body(this.initScrolin, to, of).then( rp => {
                const ajax = new Ajax(rp, this.confiScrolin);
                ajax.call().then(resp => {
                    if (resp.success) {
                        if (typeof resp[this.resp_field] != 'undefined') {
                            if (this.search == '') {
                                if (typeof resp[this.resp_field] != 'undefined') {
                                    this.no_repeat_object(this.item_base, resp[this.resp_field]).then(elementos => {
                                        this.item_base = elementos;
                                        this.item_show = [].concat(this.item_base);
                                        this.item_search = [].concat(this.item_base);
                                    });
                                }
                            } else {
                                if (typeof resp[this.resp_field] != 'undefined') {
                                    this.no_repeat_object(this.item_search, resp[this.resp_field]).then(elementos => {
                                        this.item_search = elementos;
                                        this.item_show = [].concat(this.item_search);
                                    });
                                }
                            }
                        }
                    }
                    this.lmi = true;
                  }).catch(error => {
                    this.lmi = true;
                  });
            });
        } else {
            if (this.number_page < this.number_page_max) {
                this.number_page += 1;
                if (this.number_page >= this.number_page_max) {
                    this.button_load_more = false;
                    this.number_page = this.number_page_max;
                }
                if (this.type_paginator == 'CHARGEMORE') {
                    this.item_show = this.item_show.concat(this.item_paginator[this.number_page - 1]);
                } else if (this.type_paginator == 'PAGINATIONS') {
                    this.item_show = [].concat(this.item_paginator[this.number_page - 1]);
                }
            }
        }
    }

    load_less_item() {
        if (this.remote == false && this.type_paginator == 'PAGINATIONS') {
            if (this.number_page <= this.number_page_max) {
                this.number_page += 1;
                if (this.number_page < 1) {
                    this.number_page = 1;
                }
                this.item_show = [].concat(this.item_paginator[this.number_page - 1]);
            }
        }
    }

    load_specific_item(num: number) {
        if (this.remote == false && this.type_paginator == 'PAGINATIONS') {
            if (num <= this.number_page_max) {
                if (num >= this.number_page_max) {
                    this.button_load_more = false;
                    num = this.number_page_max;
                }
                if (num < 1) {
                    num = 1;
                }
                this.item_show = [].concat(this.item_paginator[num - 1]);
            }
        }
    }

    ordening(camp: string, type_order: 'DESC' | 'ASC') {
        this.load_rady = true;
            this.button_load_more = false;
            if (this.number_page_search == null) {
                this.number_page_search = this.number_page;
            }
            this.item_paginator = [].concat(this.array_chunck(this.array_dischunck(this.item_paginator), this.limit_show, camp, type_order));
            if (this.item_paginator.length) {
                if (this.item_paginator.length > 1) {
                    this.button_load_more = true;
                }
                this.number_page = 1;
                this.number_page_max = this.item_paginator.length;
                this.item_show = [].concat(this.item_paginator[this.number_page - 1]);
            } else {
                this.item_show = [];
                this.msg_show = this.msg_search;
            }
            this.load_rady = false;
    }

    async search_filter(camps: Array<string> | string | Object, text: string = 'filter', tipe: 'filter' | 'search' = 'filter') {
        try {
            let promesa: Promise<any>;
            if (Array.isArray(camps) || typeof camps !== 'object') {
                text = await this.v_empty(text);
            }
            if (tipe == 'filter') {
                this.search = '';
                this.filter_apli = true;
            }
            this.load_rady = true;
            this.button_load_more = false;
            if (this.number_page_search == null) {
                this.number_page_search = this.number_page;
            }
            if (Array.isArray(camps) || typeof camps === 'string') {
                const searc = [];
                const items = this.filter_base==null?this.item_search:this.filter_base;
                items.some((val, key) => {
                    if (key >= 1){
                        promesa = new Promise((resolve)=> {
                            let campos = camps;
                            let textos = text;
                            let resultados = items.slice(key).filter(el => {
                                if (Array.isArray(campos)) {
                                    return Search.any(campos.map( c => {
                                        return Search.search_keyword(el[c], textos);
                                    }));
                                } else {
                                    return Search.search_keyword(el[campos], textos);
                                }   
                            });
                            resolve(resultados);
                        });
                        return true;  
                    } 
                    if (Array.isArray(camps)) {
                        if (Search.any(camps.map( c => {return Search.search_keyword(val[c], text)}))) {
                            searc.push(val);
                        }
                    } else {
                        if (Search.search_keyword(val[camps], text)) {
                            searc.push(val);
                        }
                    }
                });
                if (tipe == 'filter') {
                    this.filter_base = [].concat(searc);
                }
                this.item_paginator = [].concat(this.array_chunck(searc, this.limit_show, this.auto_order_first_camp)); 
            } else if (typeof camps === 'object') {
                let items = [];
                let firs=true;
                for (const camp in camps) {
                    if (firs) {
                        if (typeof camps[camp] === 'object') {
                            items = camps[camp].filtro(this.item_search, camps[camp].campos);
                        } else {
                            items = [].concat(Search.filter(this.item_search, (x, e) => {
                                return Search.search_keyword(x[camp], e);
                            }, camps[camp]));
                            firs=false;
                        }
                    } else {
                        if (typeof camps[camp] === 'object') {
                            items = camps[camp].filtro(items, camps[camp].campos);
                        } else {
                            items = [].concat(Search.filter(items, (x, e) => {
                                return Search.search_keyword(x[camp], e);
                            }, camps[camp]));
                        }
                    }
                }
                if (tipe == 'filter') {
                    this.filter_base = [].concat(items);
                }
                this.item_paginator = [].concat(this.array_chunck(items, this.limit_show, this.auto_order_first_camp));
            }
            if (this.item_paginator.length) {
                if (this.item_paginator.length > 1) {
                    this.button_load_more = true;
                }
                this.number_page = 1;
                this.number_page_max = this.item_paginator.length;
                this.item_show = [].concat(this.item_paginator[this.number_page - 1]);
            } else {
                this.item_show = [];
                this.msg_show = this.msg_search;
            }
            this.load_rady = false;
            if (promesa) {
                let resultadosAsincronos = await promesa;
                if (tipe == 'filter') {
                    this.filter_base = this.filter_base.concat(resultadosAsincronos);
                }
                this.item_paginator = [].concat(this.array_chunck(this.item_show.concat(resultadosAsincronos), this.limit_show, this.auto_order_first_camp));
                this.number_page_max = this.item_paginator.length;
                this.item_show = [].concat(this.item_paginator[this.number_page - 1]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    clear_filter(tipe: 'filter' | 'search' = 'filter') {
        if (tipe == 'filter') {
            this.filter_apli = false;
            this.search = '';
            this.filter_base = null;
        }
        this.load_rady = true;
        this.button_load_more = false;
        this.item_paginator = [].concat(this.array_chunck(this.filter_base==null?this.item_base:this.filter_base, this.limit_show, this.auto_order_first_camp));
        if (this.item_paginator.length) {
            if (this.number_page < this.number_page_max) {
                this.button_load_more = true;
            }
            if (typeof this.number_page_search !== 'undefined' && this.number_page_search) {
                this.number_page = this.number_page_search;
            }
            this.number_page_max = this.item_paginator.length;
            if (this.type_paginator == 'CHARGEMORE') {
                this.render_page();
            } else {
                this.item_show = [].concat(this.item_paginator[this.number_page - 1]);
            }
        } else {
            this.item_show = [];
            this.msg_show = this.msg_search;
        }
        this.number_page_search = null;
        this.load_rady = false;
    }

    clear_search() {
        this.search = '';
        if (this.remote) {
            this.item_show = [].concat(this.item_base);
            this.item_search = [].concat(this.item_base);
            this.msg_show = this.msg_init;
        } else {
            this.clear_filter('search');
        }
    }

    search_speed(even: any) {
        this.v_empty(this.search).then( rp => {
            if (typeof this.item_search !== 'undefined' && this.item_search.length && this.search != '') {
                if (this.remote) {
                    this.item_show = Search.filter(this.item_search, (x, e) => {
                        return Search.any(this.fields_search.map( c => {
                            return Search.search_keyword(x[c], e);
                        }));
                    }, rp);
                    if (even.keyCode == 13 && this.search_in_back) {
                        this.search_back();
                    }
                    this.msg_show = this.msg_search;
                } else {
                    this.search_filter(this.fields_search, this.search, 'search');
                }
                this.event_search.emit(true);
            }
        }).catch( er => {
            this.clear_search();
        });
    }

    search_back() {
        if (this.sb && this.remote) {
            this.sb = false;
            this.add_body(this.initSearch, this.to, this.of).then( rp => {
                const ajax = new Ajax(rp, this.configSearch);
                ajax.call().then(resp => {
                    if (resp.success) {
                        if (typeof resp[this.resp_field] != 'undefined') {
                            this.item_search = [].concat(resp[this.resp_field]);
                            this.item_show = [].concat(this.item_search);
                        }
                    }
                    this.sb = true;
                });
            }).catch(error => {
                this.sb = true;
            });
        }
    }

    fecha_a_time(date:string, sintime = true){
        if(sintime){//Se filtra para no tomar en cuenta la hora de la fecha
            date = new Date(date).toDateString();
        }
        return new Date(date).getTime();
    }

}