export const session = {
    user: 'ugj',
    tokenAc: 'tku',
    tokenAcTp: 'txutp',
    clientes: 'qlts',
    agenda: 'agns',
    planificacion: 'plncn',
    plan: 'pfnl',
    cuentas: 'qtnas',
    tcliente: 'tqlts',
    bancoNacional: 'bncln',
    bancoPrivado: 'bpvrd',
    tipoCobro: 'tqra',
    filtros: 'fhtlos',
    motivos: 'mtgvs',
    visitas: 'vtqyra',
    productos: 'prtfdcs',
    pedidos: 'pdtos',
    catalog_pedidos: 'qtlpdtos',
    cart_pedidos: 'ktpdtos',
    facturas: 'fxtw',
    cobranzas: 'cbznt',
    facturas_autoventa: 'fxatvnt',
    visitados: 'vtaraso',
    noVisitados: 'ntvgrxso',
    setVisita: 'stvtara',
    visitaActiva: 'vtaratva',
    users: 'ujgs',
    rutas: 'rtqs',
    lastSent: 'lssnt',
    list_pen: 'lpurl',
    monedas: 'crtk',
    moneda_p: 'crkp',
    descuentos: 'nvrmr',
    new_notification: 'nnartar',
    mensajesdiarios: 'mdros',
    keymensajesdiarios: 'kmdros',
    timemensajesdiarios: 'tmdros',
    act_factura: 'tvafxtw',
    backtogo: 'btgo',
    configs: 'cngfo',
    fmones: 'fccty',
    redir: 'redir',
    innodbv: 'ssindv',
    modooffon: 'sleduli',
    init_date: 'intdte',
    all_products: 'lprds',
    type_prices: 'tpeprcs',
    saldore_pedidos: 'sldpds',
    config_autoventa: 'cfgatvt',
    inventario_depo: 'depinv',
    list_product_inven: 'prodinvlist',
    list_inven: 'depinvlist',
    has_fc: 'juuegmdk',
    multiplos_pedidos: 'mltpped',
    lista_tasas: '1stdtca',
    ver_me_cobra: '2mvcrad',
    control_autoventa: 'ctrlatvnt',
    prod_cache_flash: 'flpro123j',
    config_vd: 'config_vd',
    tipo_grafo: 'tipo_grafo',
    precios_iva: 'pivmos',
    recibos: 'rutcba',
    idrecibo: 'ddrutcba',
    multi_docus_recibo: 'mmddttr1',
    list_sucursals: 'suclis',
    select_sucursals: 'suycuysave',
    list_rutas: 'rutlsi',
    select_rutas: 'rutsele',
    list_pagos_sucursal: 'sucpoagolis',
    list_pagos_rutas: 'rutpoagolis',
    select_pagos_sucursal: 'sucpoagoselec',
    select_pagos_rutas: 'rutpoagosele',
    config_bloq_pedi: 'blpdsven'
};

export const session_p = {
    rememberUser: 'mmrujqg',
    usernameSession: 'nmssujgp',
}

export const inndb = {
    namedb: 'bonaventura'
}

export const session_error = {
    respaldo_vel: 'pdse'
}