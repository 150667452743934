import Swal, { SweetAlertOptions } from 'sweetalert2';
import { HttpHeaders } from '@angular/common/http';
import { Init } from '../interfaces/init';
import { Cache } from '../utils/cache';
import { Config } from '../interfaces/config';
import { Estorage } from './storage';


export class Ajax {

    config: Config = {
        timecloseLoading: 1000,
        numInten : 5,
        inten : 1,
        timeEachInten : 500,
        visible : true,
        autoReNew : false,
        autoReNewSlep : 10000,
        titleLoading : '',
        titleError : 'Oops...',
        titleSuccess : '<div class="">La Operación Fue Exitosa</div>',
        textError : 'Algo Salio Mal, quieres reintentar?',
        confirmButtonText : 'Reintentar',
        cancelButtonText : 'Cancelar',
        timeCloseSuccess : 1500,
        validatejson : this.validatej,
        htmlCargar : `<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`,
        showCancelButton : true,
        showConfirmButton : true,
        showCloseButton : false,
        forceNormal : false,
        titleErrorNormal : '',
        textErrorNormal : 'Ocurrió un Error',
        showConfirmButtonError : true,
        confirmButtonTextError : 'Cerrar',
        // background: 'transparent',
        save_all: true,
        time_update: 86400000,//24H
        force_update: false,
        loadingbar: false,
        titles: undefined, //tiltes tiene la variable para el loadingbar
        force_send: false,
        send_category: ''
    };

    success_global: any;
    error_global: any;
    storage: Estorage | null = null;


    
    constructor(public init: Init, config?: Config) {
        if (typeof this.init.httpOptions === 'undefined') {
            this.init.httpOptions = {
                headers: new HttpHeaders()
            };
        } else {
            if (typeof this.init.httpOptions.headers === 'undefined') {
                this.init.httpOptions.headers = new HttpHeaders();
            }
        }

        if (typeof this.init.body === 'undefined') { 
            this.init.body = {};
        }

        this.declareConfig(config);
    }

    declareConfig(config: Config) {
        if (config) {
            if (typeof config.timecloseLoading !== 'undefined') {
                this.config.timecloseLoading = config.timecloseLoading;
            }
            if (typeof config.numInten !== 'undefined') {
                this.config.numInten = config.numInten;
            }
            if (typeof config.timeEachInten !== 'undefined') {
                this.config.timeEachInten = config.timeEachInten;
            }
            if (typeof config.titleLoading !== 'undefined') {
                this.config.titleLoading = config.titleLoading;
            }
            if (typeof config.titleError !== 'undefined') {
                this.config.titleError = config.titleError;
            }
            if (typeof config.titleSuccess !== 'undefined') {
                this.config.titleSuccess = config.titleSuccess;
            }
            if (typeof config.textError !== 'undefined') {
                this.config.textError = config.textError;
            }
            if (typeof config.confirmButtonText !== 'undefined') {
                this.config.confirmButtonText = config.confirmButtonText;
            }
            if (typeof config.cancelButtonText !== 'undefined') {
                this.config.cancelButtonText = config.cancelButtonText;
            }
            if (typeof config.timeCloseSuccess !== 'undefined') {
                this.config.timeCloseSuccess = config.timeCloseSuccess;
            }
            if (typeof config.htmlCargar !== 'undefined') {
                this.config.htmlCargar = config.htmlCargar;
            }
            if (typeof config.visible !== 'undefined') {
                this.config.visible = config.visible;
            }
            if (typeof config.autoReNew !== 'undefined') {
                this.config.autoReNew = config.autoReNew;
            }
            if (typeof config.autoReNewSlep !== 'undefined') {
                this.config.autoReNewSlep = config.autoReNewSlep;
            }
            if (typeof config.validatejson !== 'undefined') {
                this.config.validatejson = config.validatejson;
            }
            if (typeof config.showCancelButton !== 'undefined') {
                this.config.showCancelButton = config.showCancelButton;
            }
            if (typeof config.showConfirmButton !== 'undefined') {
                this.config.showConfirmButton = config.showConfirmButton;
            }
            if (typeof config.showCloseButton !== 'undefined') {
                this.config.showCloseButton = config.showCloseButton;
            }
            if (typeof config.forceNormal !== 'undefined') {
                this.config.forceNormal = config.forceNormal;
            }
            if (typeof config.titleErrorNormal !== 'undefined') {
                this.config.titleErrorNormal = config.titleErrorNormal;
            }
            if (typeof config.textErrorNormal !== 'undefined') {
                this.config.textErrorNormal = config.textErrorNormal;
            }
            if (typeof config.showConfirmButtonError !== 'undefined') {
                this.config.showConfirmButtonError = config.showConfirmButtonError;
            }
            if (typeof config.confirmButtonTextError !== 'undefined') {
                this.config.confirmButtonTextError = config.confirmButtonTextError;
            }
            if (typeof config.save_all !== 'undefined') {
                this.config.save_all = config.save_all;
            }
            if (typeof config.time_update !== 'undefined') {
                this.config.time_update = config.time_update;
            }
            if (typeof config.force_update !== 'undefined') {
                this.config.force_update = config.force_update;
            }
            if (typeof config.params !== 'undefined') {
                this.config.params = config.params;
            } else {
                this.config.params = null;
            }
            if (typeof config.titles !== 'undefined') {
                this.config.titles = config.titles;
                this.config.loadingbar = true;
            }
            if (typeof config.send_category !== 'undefined') {
                this.config.send_category = config.send_category;
            }
            if (typeof config.force_send !== 'undefined') {
                this.config.force_send = config.force_send;
            }
            
        }
        if (typeof this.config.alertaLoading === 'undefined') {
            this.config.alertaLoading = Swal.mixin({
                title: this.config.titleLoading,
                html: this.config.htmlCargar,
                allowOutsideClick: false,
                showConfirmButton: false,
                background: this.config.background
            });
        }
        if (typeof this.config.alertaError === 'undefined') {
            const optionsalertaError: SweetAlertOptions = {
                icon: 'error',
                title: this.config.titleErrorNormal,
                text: this.config.textErrorNormal,
                showConfirmButton: this.config.showConfirmButtonError,
                confirmButtonText: this.config.confirmButtonTextError,
            };
            this.config.alertaError = Swal.mixin(optionsalertaError);
        }
        if (typeof this.config.alertaReintentar === 'undefined') {
            const optionsalertaReintentar: SweetAlertOptions = {
                icon: 'error',
                title: this.config.titleError,
                text: this.config.textError,
                confirmButtonText: this.config.confirmButtonText,
                showConfirmButton: this.config.showConfirmButton,
                showCancelButton: this.config.showCancelButton,
                allowEscapeKey: false,
                allowEnterKey: false,
                cancelButtonText: this.config.cancelButtonText,
                showCloseButton: this.config.showCloseButton,
                preConfirm: (bton) => {
                    this.callReNew();
                }
            };
            this.config.alertaReintentar = Swal.mixin(optionsalertaReintentar);
        }
        if (typeof this.config.alertaSuccess === 'undefined') {
            const optionsalertaSuccess: SweetAlertOptions = {
                icon: 'success',
                title: this.config.titleSuccess,
                showConfirmButton: false,
                timer: this.config.timeCloseSuccess,
                background: this.config.background
                
                
            };
            this.config.alertaSuccess = Swal.mixin(optionsalertaSuccess);
        }

        if (typeof this.config.alertEspera === 'undefined') {
            const optionsalertaEspera: SweetAlertOptions = {
                icon: 'info',
                title: `Se agrego su envio a la lista de ${this.config.send_category}`,
                showConfirmButton: false,
                timer: this.config.timeCloseSuccess,
                background: this.config.background
            };
            this.config.alertEspera = Swal.mixin(optionsalertaEspera);
        }

        if (config) {
            if (typeof config.alertaLoading !== 'undefined' && typeof config.alertaLoading === 'function') {
                this.config.alertaLoading = config.alertaLoading;
            }
            if (typeof config.alertaError !== 'undefined' && typeof config.alertaError === 'function') {
                this.config.alertaError = config.alertaError;
            }
            if (typeof config.alertaSuccess !== 'undefined' && typeof config.alertaSuccess === 'function') {
                this.config.alertaSuccess = config.alertaSuccess;
            }
            if (typeof config.alertaReintentar !== 'undefined' && typeof config.alertaReintentar === 'function') {
                this.config.alertaReintentar = config.alertaReintentar;
            }
        }
        this.storage = new Estorage(this.config.params, this.config.time_update, this.config.force_update, this.config.save_all, this.init.auth);
    }

    validatej(resp: any): Promise<any> {
        return new Promise((success, error) => {
            if (typeof resp.success !== 'undefined' && resp.success) {
                success(resp);
            } else {
                error(resp.message);
            }
        });
    }

    check() {
        if (typeof this.init.url === 'undefined') {
            throw new Error('La url no puede estar vacia');
        }
        if (!this.init.httpOptions.headers.has('X-CSRFToken')) {
            this.init.httpOptions.headers = this.init.httpOptions.headers.set('X-CSRFToken', Cache.getCookie('csrftoken'));
        }
    }

    defaultParams() {
        this.config.inten = 1;
    }

    callReNew() {
        /**
         * llama a las funciones para reintentar una peticion
         */
        this.defaultParams();
        //this.call();
        this.proceso(this.config);
    }

    call(config: null | Config = null): Promise<any> {
        return new Promise((success, error) => {
            this.success_global = success;
            this.error_global = error;
            this.proceso(config);
        });
    }

    proceso(config: null | Config) {
        if (this.config.forceNormal === true || this.init.auth.isLogin() === true) {
            this.declareConfig(config);
            this.open_loading();
            this.storage.get_params().then(cache => {
                this.close_loading().then(() => {
                    this.open_success();
                    this.success_global(cache);
                });
            }).catch((cache_error) => {
                if (this.config.inten <= this.config.numInten) {
                    let timeeach = this.config.timeEachInten;
                    if (this.config.inten === 1) {
                        timeeach = 0;
                    }
                    this.config.inten += 1;
                    if (this.init.auth.isLogin() && this.config.forceNormal === false) {
                        this.init.httpOptions.headers = this.init.httpOptions.headers.set('Authorization', this.init.auth.token.token);
                    }
                    setTimeout(() => {
                        this.peticion().then((response: any) => {
                            if (typeof response.error !== 'undefined' && response.error == '807') {
                                this.close_loading().then(() => {
                                    this.error_global(response.message);
                                });
                                this.init.auth.token_expire();
                            }
                            if (typeof response.error !== 'undefined' && response.error == '700') {
                                this.close_loading().then(() => {
                                    //this.error_global(response.message);
                                });
                                this.init.auth.outdated_p();
                            }
                            if (typeof response.error !== 'undefined' && response.error == '701') {
                                this.close_loading().then(() => {
                                   // this.error_global(response.message);
                                });
                                this.init.auth.outdated_s();
                            }
                            if (typeof response.error !== 'undefined' && response.error == '702') {
                                this.close_loading().then(() => {
                                   // this.error_global(response.message);
                                });
                                this.init.auth.outdated_p();
                            }
                            this.config.validatejson(response, this).then((validate: any) => {
                                this.close_loading().then(() => {
                                    this.open_success();
                                    this.storage.set_params(validate);
                                    this.success_global(validate);
                                });
                            }).catch((errvalidate: any) => {
                                this.close_loading().then(() => {
                                    if (typeof errvalidate === 'object') {
                                        this.open_error(errvalidate.message);
                                        this.error_global(errvalidate);
                                    } else {
                                        this.open_error(errvalidate);
                                        this.error_global(errvalidate);
                                    }
                                });
                            });
                        }).catch(er => {
                            console.log(er);
                            this.proceso(config);
                        });
                    }, timeeach);
                } else {
                    this.close_loading().then(() => {
                        if (this.config.force_send) {
                            this.storage.list_urls(this.init, this.config);
                            this.config.alertEspera.fire();
                        } else {
                            this.open_error_reintentar();
                            if (false) {
                            // if (this.config.autoReNew && !this.config.visible) {
                                setTimeout(() => {
                                    this.callReNew();
                                }, this.config.autoReNewSlep);
                            } else {
                                this.error_global(false);
                            }
                        }
                    });
                }
            });
        } else {
            this.error_global('');
        }
    }

    peticion(): Promise<any> {
        return new Promise((success, error) => {
            if (this.init.method === 'post') {
                this.post(success, error);
            } else if (this.init.method === 'put') {
                this.put(success, error);
            } else if (this.init.method === 'get') {
                this.get(success, error);
            }
        });
    }

    serializeUrl(url: string): string {
        if (typeof this.init.body !== 'undefined') {
            let cont = 1;
            for (const key in this.init.body) {
                if (cont === 1) {
                    cont = cont + 1;
                    url = `${url}?${key}=${this.init.body[key]}`;
                } else {
                    cont = cont + 1;
                    url = `${url}&${key}=${this.init.body[key]}`;
                }
            }
        }
        return url;
    }

    serializearray(obj, url, cont, name) {
        for (const key in obj) {
            if (cont === 1) {
                if (typeof obj[key] === 'object') {
                    for (const i in obj[key]) {
                        url = `${url}${name}[${key}][${i}]=${obj[key][i]}`;
                    }
                } else {
                    url = `${url}${name}[${key}]=${obj[key]}`;
                    cont += 1;
                }
            } else {
                if (typeof obj[key] === 'object') {
                    for (const i in obj[key]) {
                        url = `${url}&${name}[${key}][${i}]=${obj[key][i]}`;
                    }
                } else {
                    url = `${url}&${name}[${key}]=${obj[key]}`;
                    cont += 1;
                }
            }
        }
        return url;
    }


    template(url: string, key: any, value: any): string {
        return url==''?`${key}=${value}`:`${url}&${key}=${encodeURIComponent(value)}`;
    }

    serializeBody(body: any, url: string, key: any, prime=true): string {
        if (typeof body === 'object') {
            for (const pk in body) {
                let k = key;
                if (prime) {
                    k = `[${key}]`;
                }
                k = `${k}[${pk}]`;
                url = this.serializeBody(body[pk], url, k, false);
            }
        } else {
            url = this.template(url, key, body);
        }
        return url
    }
    
    serialize(body: any = false) {
        let url = '';
        if (body) {
            for (const key in body) {
                url = this.serializeBody(body[key], url, key, false);
            }
        }
        return url;
    }

    serializeBody_alternative(value, body){
        if (typeof value === 'object') {
            let body_ready = {};
            for (const pk in value) {
                body_ready[pk] = this.serializeBody_alternative(value[pk], value);
            }
            return Object.assign({}, body_ready);
        } else {
            return value;
        }
    }

    serialice_alternative(body) {
        let body_ready = {};
        if (body) {
            for (const key in body) {
                body_ready[key] = this.serializeBody_alternative(body[key], body);
            }
        }
        return Object.assign({}, body_ready);
    }

    get(success: any, error: any) {
        if (this.init.auth.platform.is('android') && this.init.auth.platform.is('mobileweb') == false) {
            this.init.httpOptions.headers = this.init.httpOptions.headers.set('Content-Type', 'application/json');
            this.init.auth.clianative.setDataSerializer('json');
            this.init.auth.clianative.get(this.init.url.url(), this.init.body, this.get_headers()).then(response => {
                console.log(response);
                success(JSON.parse(response.data));
            }).catch(err => {
                console.log(err);
                error(err);
            });
        } else {
            this.init.auth.cliangular.get(this.serializeUrl(this.init.url.url()), this.init.httpOptions).subscribe(response => {
                success(response);
            }, err => {
                error(err);
            });
        }
        
    }

    get_headers() {
        const keys = this.init.httpOptions.headers.keys();
        let header = {};
        for (const key of keys) {
            header[key] = this.init.httpOptions.headers.get(key);
        }
        return header;
    }

    

    post(success: any, error: any) {
        
        if (this.init.auth.platform.is('android') && this.init.auth.platform.is('mobileweb') == false) {
            this.init.httpOptions.headers = this.init.httpOptions.headers.set('Content-Type', 'application/json');
            this.init.auth.clianative.setDataSerializer('json');
            this.init.auth.clianative.post(this.init.url.url(), this.serialice_alternative(this.init.body), this.get_headers()).then(response => {
                console.log(response);
                success(JSON.parse(response.data));
            }).catch(err => {
                console.log(err);
                error(err);
            });
        } else {
            this.init.httpOptions.headers = this.init.httpOptions.headers.set('Content-Type', 'application/x-www-form-urlencoded');
            if (!this.init.httpOptions.headers.has('Content-Type')) {
            }
            this.init.auth.cliangular.post(this.init.url.url(), this.serialize(this.init.body), this.init.httpOptions).subscribe(response => {
                success(response);
            }, err => {
                // console.log(err);
                error(err);
            });
        }
        
        
    }

    put(success: any, error: any) {
        if (this.init.auth.platform.is('android') && this.init.auth.platform.is('mobileweb') == false) {
            this.init.httpOptions.headers = this.init.httpOptions.headers.set('Content-Type', 'application/json');
            this.init.auth.clianative.setDataSerializer('json');
            this.init.auth.clianative.put(this.init.url.url(), this.serialice_alternative(this.init.body), this.get_headers()).then(response => {
                console.log(response);
                success(JSON.parse(response.data));
            }).catch(err => {
                console.log(err.status);
                error(err);
            });
        } else {
            this.init.auth.cliangular.put(this.init.url.url(), this.serialize(this.init.body), this.init.httpOptions).subscribe(response => {
                success(response);
            }, err => {
                error(err);
            });
        }
        
    }

    open_success() {
        if (!this.config.alertaSuccess.isVisible()) {
            if (this.config.visible) {
                this.config.alertaSuccess.fire();
            }
        }
    }

    close_success() {
        if (this.config.visible) {
            if (this.config.alertaSuccess.isVisible()) {
                this.config.alertaSuccess.close();
            }
        }
    }

    open_loading() {
        if (this.config.visible) {
            if (!this.config.alertaLoading.isVisible()) {
                this.config.alertaLoading.fire();
            }
        }else{
            if (this.config.loadingbar) {
                setTimeout(() => {
                    this.config.titles.loadingbar = true;
                }, 1);
                
            }
        }
    }

    close_loading() {
        return new Promise((success, error) => {
            if (this.config.visible) {
                setTimeout(() => {
                    if (this.config.alertaSuccess.isVisible()) {
                        this.config.alertaLoading.close();
                    }
                    let checkAlert = setInterval(() => {
                        if (!this.config.alertaLoading.isVisible()) {
                            success(true);
                            clearInterval(checkAlert);
                            checkAlert = null;
                        }
                    }, 500);
                }, this.config.timecloseLoading);
            } else {
                if (this.config.loadingbar) {
                    this.config.titles.loadingbar = false;
                }
                success(true);
            }
        });
    }

    open_error_reintentar() {
        if (this.config.visible) {
            if (!this.config.alertaReintentar.isVisible()) {
                this.config.alertaReintentar.fire();
            }
        }
    }
    open_error(message = false) {
        if (this.config.visible) {
            if (!this.config.alertaError.isVisible()) {
                if (message) {
                    this.config.alertaError.fire({text: message});
                } else {
                    this.config.alertaError.fire();
                }
            }
        }
    }

    close_error() {
        if (this.config.visible) {
            if (this.config.alertaError.isVisible()) {
                this.config.alertaError.close();
            }
        }
    }

}
