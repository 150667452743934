import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { rutasUrl } from '../../constantes/rutas';
import Swal from 'sweetalert2';
import { DataVelService } from 'src/app/vel/synchronization/data-vel.service';
import { Cache } from 'src/app/core/utils/cache';
import { session } from 'src/app/core/constantes/sessiones';
import { DatatempService } from 'src/app/vel/datatemp/datatemp.service';

import { Config } from 'src/app/core/interfaces/config';
import { Init } from 'src/app/core/interfaces/init';
import { Ajax } from 'src/app/core/utils/ajax';
import { TitlesService } from 'src/app/core/services/titles.service';
import { UrListService } from '../../url/url.service';
import { SendDataService } from 'src/app/vel/synchronization/send-data.service';

@Component({
  selector: 'app-elegir-modo',
  templateUrl: './elegir-modo.component.html',
  styleUrls: ['./elegir-modo.component.scss'],
})
export class ElegirModoComponent implements OnInit {

  ver_regresar: boolean = false;
  urltdp:any;
  urln360 = rutasUrl.homen360;

  rutavalida= false;
  rutas: any;
  rut = "";
  modo: any = "";

  buscando = false;
  sirutas = true; //True si tiene rutas, false si no tiene

  constructor(
    public auth: AuthService,
    public router: Router,
    public dataVs: DataVelService,
    public DatatempService: DatatempService,
    public titles: TitlesService,
    private listUrl: UrListService,
    public sendsv: SendDataService
  ) { }

  ngOnInit() {
    this.ver_regresar = this.auth.modeoffonline;
    this.urltdp = rutasUrl.hometdp;
    if(this.auth.isRuta()){
      this.rut = this.auth.user.id_ruta;
    }
    this.loadRutas();
  }

  async elegirModo(){
    //modo True para Online, False para offline
    this.getFormatoAutoventa();
    if(this.rut == ''){
      this.alerta("Debe escoger una Ruta");
    }else if(this.modo == ''){
      this.alerta("Debe escoger un Modo");
    }else{
      this.auth.user.rutas.forEach(el => {
        if(el.id_ruta == this.rut){
          this.rutavalida = true;
        }
      });
      if(this.rutavalida){
        this.sendsv.deleteDataCache((call)=> {
          if (call) {
            this.auth.ruta = this.rut;
            this.dataVs.even_cambio_ruta.emit({});
            this.auth.activaModo(Boolean(Number(this.modo)));
            this.cambiarDatosModo();
            this.router.navigate([this.urltdp]);
          }
        },'elegir_modo');
      }else{
        this.alerta("Ruta incorrecta");
      }
    }
  }

  
  
  getFormatoAutoventa() {
    const initAjax: Init = {
      method: 'post',
      url: this.listUrl.url('formato_autoventa_obtener'),
      auth: this.auth
    };
    const configAjax: Config = {
      visible: false
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then((reponse) => {
     Cache.setLocal(session.config_autoventa, reponse.entities);
    }).catch(error => {
    });
   }

  async cambiarDatosModo() {
    await this.DatatempService.set_all_mode();
  }

  loadRutas(){
    // cargar rutas
    if (this.auth.user.rutas.length) {
      this.rutas = this.auth.user.rutas;
    }else{
      this.buscando = true;
      this.get_user_rutas();
    }
  }

  get_user_rutas(){
    const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('get_user_rutas'),
        auth: this.auth,
        body: {}
    };
    const configAjax: Config = {
        visible: false,
        titles: this.titles,
        force_update: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(respo => {
      if (respo.success) {
        if(Array.isArray(respo.salida)){
          let user = Object.assign(this.auth.user);
          user.rutas = respo.salida;
          this.auth.user = user;
          this.rutas = respo.salida;
          if(respo.salida.length == 0){
            this.sirutas = false;
          }
        }
      }
      this.buscando = false;
    }).catch(error => {
      this.buscando = false;
    });
  }

  alerta(msj){
    Swal.mixin({
      icon: 'error',
      title: msj,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Aceptar",
      showCloseButton: true
    }).fire();
  }

  getId(){
    return 58;
  }

}
