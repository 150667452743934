import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Config } from 'src/app/core/interfaces/config';
import { Init } from 'src/app/core/interfaces/init';
import { FeedbackService } from 'src/app/core/services/feedback.service';
import { UrListService } from 'src/app/core/url/url.service';
import { Ajax } from 'src/app/core/utils/ajax';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-registre-category-modal',
  templateUrl: './registre-category-modal.component.html',
  styleUrls: ['./registre-category-modal.component.scss'],
})
export class RegistreCategoryModalComponent implements OnInit {
  formCategory: FormGroup;
  title: string = '';
  name: string;
  activo: string;
  even_add: EventEmitter<any> = new EventEmitter();
  objEstatus = [
    {id: 'Activo', desc: 'Activo'},
    {id: 'Inactivo', desc: 'Inactivo'}
  ];
  error_estado: boolean = false;
  txt_estado: string | null;
  error_desc: boolean = false;
  txt_desc: string | null;
  txtBtn: string = '';

  constructor(
    public auth: AuthService,
    public FeedbackService: FeedbackService,
    private listUrl: UrListService
  ) {
    this.formCategory = new FormGroup({
      nameCat: new FormControl(this.name ,[
        Validators.required,
        Validators.maxLength(255)
      ]),
      actCat: new FormControl(this.activo ,[
        Validators.required
      ])
    });
  }

  ngOnInit() {
    if (this.FeedbackService.isNewCategory) {
      this.title = 'Crear Categoria';
      this.txtBtn = 'Guardar';
      this.formCategory.reset();
      this.activo = 'Activo';
      this.actCat.setValue(this.activo);
    } else {
      this.title = 'Editar Categoria #'+this.FeedbackService.idCategory;
      this.txtBtn = 'Editar';
      if (typeof this.FeedbackService.objCat === 'object' && Object.entries(this.FeedbackService.objCat).length > 0) {
        this.nameCat.setValue(this.FeedbackService.objCat.nombre);
        this.activo = this.FeedbackService.objCat.estado;
        this.actCat.setValue(this.activo);
      } else {
        this.hideModal();
      }
    }
  }

  get nameCat(): any { 
    return this.formCategory.get('nameCat');
  }
  get actCat(): any { 
    return this.formCategory.get('actCat');
  }

  hideModal() {
    this.FeedbackService.closeModal.hide();
  }

  changeEstado() {
    if (this.actCat.value == 'Activo' || this.actCat.value == 'Inactivo'){
      this.error_estado = false;
      this.txt_estado = '';
    } else {
      this.error_estado = true;
      this.txt_estado = 'Valor invalido.';
    }
  }

  changeStr() {
    this.error_desc = false;
    this.txt_desc = '';
    let tit = String(this.nameCat.value);
    this.nameCat.setValue(tit.trim());
  }

  sendData() {
    if (this.FeedbackService.isNewCategory) {
      this.createCategory();
    } else {
      this.updateCategory();
    }
  }

  createCategory() {
    const initAjax: Init = {
      method: 'post',
      url: this.listUrl.url('create_category'),
      auth: this.auth,
      body: {
        estado: this.actCat.value,
        nombre: this.nameCat.value
      }
    };
    const configAjax: Config = {
      numInten : 1,
      titleLoading: `Creando Categoria`,
      titleSuccess: '',
      titleErrorNormal: 'Error',
      textErrorNormal: 'Ha ocurrido un Error.',
      confirmButtonTextError: 'Cerrar',
      validatejson: (resp: any, obj: Ajax) => {
        return new Promise((success, error) => {
          if (resp.success === false) {
            obj.declareConfig({
              alertaError : Swal.mixin({
                icon: 'error',
                title: 'Ocurrio un error',
                text: resp.message,
                showConfirmButton: false,
                confirmButtonText: 'Cerrar',
              })
            });
            error(resp);
          } else {
            success(resp);
          }
        });
      }
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success) {
        this.formCategory.reset();
        this.even_add.emit(true);
        this.FeedbackService.even_remove.emit(true);
        this.hideModal();
      }
    }).catch(error => {
      return error;
    });
  }

  updateCategory() {
    const initAjax: Init = {
      method: 'post',
      url: this.listUrl.url('update_category'),
      auth: this.auth,
      body: {
        id: this.FeedbackService.idCategory,
        estado: this.actCat.value,
        nombre: this.nameCat.value
      }
    };
    const configAjax: Config = {
      numInten : 1,
      titleLoading: `Editando Categoria`,
      titleSuccess: '',
      titleErrorNormal: 'Error',
      textErrorNormal: 'Ha ocurrido un Error.',
      confirmButtonTextError: 'Cerrar',
      validatejson: (resp: any, obj: Ajax) => {
        return new Promise((success, error) => {
          if (resp.success === false) {
            obj.declareConfig({
              alertaError : Swal.mixin({
                icon: 'error',
                title: 'Ocurrio un error',
                text: resp.message,
                showConfirmButton: false,
                confirmButtonText: 'Cerrar',
              })
            });
            error(resp);
          } else {
            success(resp);
          }
        });
      }
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      if (resp.success) {
        this.formCategory.reset();
        this.even_add.emit(true);
        this.FeedbackService.even_remove.emit(true);
        this.hideModal();
      }
    }).catch(error => {
      return error;
    });
  }

}
