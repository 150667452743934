import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-more',
  templateUrl: './button-more.component.html',
  styleUrls: ['./button-more.component.scss'],
})
export class ButtonMoreComponent implements OnInit {
  @Input() cnt_bt = '';
  @Input() load_rady = false;
  @Input() button_load_more = true;
  constructor() { }

  ngOnInit() {}

}
