import { Component, OnInit } from '@angular/core';
import { NotificacionService } from 'src/app/core/notifications/notificacion.service';
import { Router } from '@angular/router';
import { Search } from 'src/app/core/utils/search';
import { Ajax } from 'src/app/core/utils/ajax';
import { Init } from 'src/app/core/interfaces/init';
import { UrListService } from 'src/app/core/url/url.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { session } from 'src/app/core/constantes/sessiones';
import { Cache } from 'src/app/core/utils/cache';
import { Config } from 'src/app/core/interfaces/config';
import { ViewLoader } from 'src/app/core/viewloader/view-loader';
import { rutasUrl } from 'src/app/core/constantes/rutas';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent extends ViewLoader implements OnInit {
  url= rutasUrl.notificaciones;
  renderNoti: any;
  inputsearch: any;
  carga = true;
  leido = false;

  displaySearch: any;
  getScroll: any;
  scrollToTop: any;
  loadMore: any;

  resp_field = 'entities';
  remote = false;

  limit_show = 9;
  not_item = "No hay notificaciones";
  not_search = "No hay notificaciones para tu busqueda";

  constructor(public NotificacionService: NotificacionService,
    private router: Router,public auth: AuthService,
    private listUrl: UrListService) {
    super();
    this.search_in_back = false;
    this.msg_init = this.not_item;
    this.msg_search = this.not_search;
    this.msg_show = this.msg_init;
    
  }

  async ngOnInit() {
    this.NotificacionService.number_pendiente();
  }

   irBandejadeEntrada() {     
     this.router.navigate([this.url]);
   }

   vacio(){
    if (typeof this.auth.notificaciones === 'undefined') {
     return 0;
    }else{
      if(Array.isArray(this.auth.notificaciones)){
        return this.auth.notificaciones.length;
      }else{
        return 0;
      }
      
    }
   }


}
