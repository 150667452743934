import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Config } from 'src/app/core/interfaces/config';
import { Init } from 'src/app/core/interfaces/init';
import { FeedbackService } from 'src/app/core/services/feedback.service';
import { UrListService } from 'src/app/core/url/url.service';
import { Ajax } from 'src/app/core/utils/ajax';

@Component({
  selector: 'app-view-modal-feed',
  templateUrl: './view-modal-feed.component.html',
  styleUrls: ['./view-modal-feed.component.scss'],
})
export class ViewModalFeedComponent implements OnInit {

  title: string = '';
  fecha: string = '';
  category: string = '';
  titulo: string = '';
  observacion: string = '';
  sistema: string = '';
  is_leido: boolean = false;
  leido_por: string = '';
  id_leido: string = '';
  id_feedback: string = '';

  constructor(
    public FeedbackService: FeedbackService,
    public auth: AuthService,
    private listUrl: UrListService
  ) { }

  ngOnInit() {
    if (typeof this.FeedbackService.objFeed === 'object' && Object.entries(this.FeedbackService.objFeed).length > 0) {
      this.title = 'FeedBack Creado por: '+ this.FeedbackService.objFeed.fullname;
      this.fecha = this.FeedbackService.objFeed.fecha;
      this.category = this.FeedbackService.objFeed.name_categoria;
      this.titulo = this.FeedbackService.objFeed.titulo;
      this.observacion = this.FeedbackService.objFeed.obser;
      this.sistema = this.FeedbackService.objFeed.sistema;
      this.id_feedback = this.FeedbackService.objFeed.id_feedback;
      if (this.FeedbackService.updateLeido) {
        this.is_leido = true;
        this.leido_por = this.FeedbackService.objFeed.leido_por;
      } else {
        this.is_leido = false;
        this.id_leido = this.FeedbackService.objFeed.id_leido;
        this.updateCategoryReceptor();
      }
    } else {
      this.hideModal();
    }
  }

  updateCategoryReceptor() {
    const initAjax: Init = {
      method: 'post',
      url: this.listUrl.url('update_receptor'),
      auth: this.auth,
      body: {
        feedback_id: this.id_feedback,
        leido_por: this.id_leido
      }
    };
    const configAjax: Config = {
      visible: false
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(resp => {
      return resp;
    }).catch(error => {
      return error;
    });
  }

  hideModal() {
    this.FeedbackService.closeModal.hide();
  }

}
