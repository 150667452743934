import { Component, OnInit,  ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Config } from 'src/app/core/interfaces/config';
import { UrListService } from 'src/app/core/url/url.service';
import { session } from 'src/app/core/constantes/sessiones';
import { Ajax } from 'src/app/core/utils/ajax';
import { Init } from 'src/app/core/interfaces/init';
import { NotificacionService } from 'src/app/core/notifications/notificacion.service';
import * as moment from 'moment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Title } from '@angular/platform-browser';
import { EventosService } from 'src/app/core/services/eventos.service';
import { TitlesService } from 'src/app/core/services/titles.service';
import { MatAccordion } from '@angular/material/expansion';
import { ModalGrupComponent } from 'src/app/core/notifications/send-notifications/modal-grup/modal-grup.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ViewLoader } from 'src/app/core/viewloader/view-loader';


@Component({
  selector: 'app-send-notifications',
  templateUrl: './send-notifications.component.html',
  styleUrls: ['./send-notifications.component.scss'],
})
export class SendNotificationsComponent extends ViewLoader implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  bsModalRef: BsModalRef;

  formEnvioNoti: FormGroup;
  inputsearch: any;

  displaySearch: any;
  getScroll: any;
  scrollToTop: any;
  loadMore: any;

  resp_field = 'entities';
  fields_search = ['id_grupo', 'descripcion'];
  field_norepeat = (resp: any) => {
    return resp.id_grupo;
  };
  remote = false;
  is_boton_change = false;

  limit_show = 9;
  not_item = "No existen grupos guardados";
  not_search = "No se encontraron resultados para tu busqueda.";

	notificacion : any;

  title : string = 'N360 | Enviar Notificacion';

    grupo_select = [];
    grupo_select_borrar = null;

    loadingData = Swal.mixin({
    title: '',
    html: `<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`,
    allowOutsideClick: false,
    showConfirmButton: false
    });

    alertaSuccess: SweetAlertOptions = {
    icon: 'success',
    title: 'Datos descargados exitosamente',
    showConfirmButton: false,
    timer: 1500
    };

  constructor(public auth: AuthService,
  private listUrl: UrListService,
  public NotificacionService: NotificacionService,
  private titles: TitlesService,
  public EventosService: EventosService,
  public ModalGrupComponent: ModalGrupComponent,
  public BsModalService : BsModalService) {

    super();
    this.search_in_back = false;
    this.of = 0;
    this.to = 500;
    this.numItem = 500;
    this.msg_init = this.not_item;
    this.msg_search = this.not_search;
    this.msg_show = this.msg_init;

    this.formEnvioNoti = new FormGroup({
      text_notificacion: new FormControl(this.notificacion, [
        Validators.required,
         Validators.pattern('^[a-z0-9A-Z]+')
        ])
      });

    this.NotificacionService.even_grupos.subscribe( (grupos_resp)=>{
      this.charge_items(grupos_resp.grupos);
    })
    this.cargar_datos();
    
    //let location = window.location.pathname;
    //setTimeout(()=>{
    //    let location_actual = window.location.pathname;
    //    if(location_actual == location){
      this.registrar_evento();
    //}
    //  }, 15000
    //);
   }

   getId(){
   	return '24';
   }

   registrar_evento(){
    this.EventosService.evento_set(false, this.titles,'notificaciones_enviar').then(respo => {}).catch(error => {});
  }

  mostrarDescripcion(indice_seleccionado){
    if (this.NotificacionService.grupos[indice_seleccionado] != null){
        return this.NotificacionService.grupos[indice_seleccionado].descripcion;
    }else{
      return 'Sin descripcion';
    }
  }

  mostrarMiembros(indice_seleccionado){
    if (this.NotificacionService.grupos[indice_seleccionado] != null && this.NotificacionService.grupos[indice_seleccionado].miembros != ''){ 
        return this.NotificacionService.grupos[indice_seleccionado].miembros;
    }else{
      return 'Sin miembros';
    }
  }

  async cargar_datos(){
  	this.reiniciar();
  	await this.NotificacionService.getTodos_grupos(false, this.titles).then( resp =>{
    }).catch( err=>{
      let erro = err;
    });

    await this.usuario_sucursal(false, this.titles).then( resp =>{
    }).catch( err=>{
      let erro = err;
    });
  }

  reiniciar(){
  	this.NotificacionService.grupos = [];
    this.NotificacionService.usuarios_sucursal = undefined;
    this.grupo_select_borrar = null;
    this.notificacion = undefined;
    this.grupo_select = [];
  }

  ngOnInit() {
    this.is_boton_change = this.auth.check_action('change_alert_front');
    this.titles.setTitle(this.title);
    this.NotificacionService.Modal_Grup = ModalGrupComponent;
  }

  addModalView(modalidad,id){
    const initialState = {
      componentParent : this
    };
    if(modalidad == 1){
      this.NotificacionService.modo_grupo = 'nuevo';
      this.NotificacionService.grupo_id_edit = 0;
    }
    if(modalidad == 2){
      this.NotificacionService.modo_grupo = 'editar';
      this.NotificacionService.grupo_id_edit = id;
    }

    if(modalidad == 3){
      this.NotificacionService.modo_grupo = 'enviar';
      this.NotificacionService.grupo_id_edit = 0;
    }
 
    this.bsModalRef = this.BsModalService.show(this.NotificacionService.Modal_Grup, {initialState});
    this.bsModalRef.setClass('modal-dialog-centered zindex-one');
    this.NotificacionService.close_Modal_grup = this.bsModalRef;
  }

  usuario_sucursal(visible=true, titles:any={}):Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('get_users'),
        auth: this.auth,
        body: {}
      };
      const configAjax: Config = {
        visible,
        titles,
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
      	this.NotificacionService.usuarios_sucursal = resp.data;
        /*for(let i=0;i<this.NotificacionService.usuarios_sucursal.length;i++){
          if(this.NotificacionService.usuarios_sucursal[i].idusuario == this.auth.user.idusuario){
            this.NotificacionService.usuarios_sucursal.splice(i,1);
          }
        }*/
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }

  eliminarGrupo(id){
        const initAjax: Init = {
            method: 'post',
            url: this.listUrl.url('eliminar_grupo'),
            auth: this.auth,
            body: {
              'id': id          
            }
          };
        const configAjax: Config = {
            visible: true
        };
        const ajax = new Ajax(initAjax, configAjax);
        ajax.call().then(respo => {
            if(respo.success){
              for(let i = 0; i<this.NotificacionService.grupos.length; i++){
                if(this.NotificacionService.grupos[i].id_grupo == this.grupo_select_borrar){
                  this.NotificacionService.grupos.splice(i,1);
                  if(this.NotificacionService.grupos.length==1){
                    this.grupo_select_borrar =0;
                    this.grupo_select = [];  
                    this.grupo_select.push(0);

                  }
                }
              }
              this.cargar_datos();
            }
        }).catch(error => {
            console.log(error);
        });
  }

  name_grupo(grupo){
    if(grupo.activo == '2'){
      return 'Notificaciones Especiales';
    }
    else{
      return 'Grupo #' +grupo.id_grupo;
    }
  }


  alertar_usuarios(){
    const initialState = {
      componentParent : this
    };

    this.NotificacionService.modo_grupo = 'alerta';
    this.NotificacionService.grupo_id_edit = 0;
 
    this.bsModalRef = this.BsModalService.show(this.NotificacionService.Modal_Grup, {initialState});
    this.bsModalRef.setClass('modal-dialog-centered zindex-one');
    this.NotificacionService.close_Modal_grup = this.bsModalRef;
  }

}
