import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthService } from '../auth/auth.service';
import { Config } from '../interfaces/config';
import { Init } from '../interfaces/init';
import { TitlesService } from '../services/titles.service';
import { UrListService } from '../url/url.service';
import { Ajax } from '../utils/ajax';

@Component({
  selector: 'app-home',
  templateUrl: 'home.html',
  styleUrls: ['home.scss'],
})
export class Home {
  origen: any = []; //Lista de apps con sus modulos ordenada
  mostrar: any = []; //Lista de apps o modulos que se muestra
  return = false;
  bget = false;

  paramId: any;

  title = 'Bonaventura: Inicio';
  cerrar_sesion = true; //condicion para evitar duplicar alerta de cierre de sesion

  constructor(
    public auth: AuthService,
    public titles: TitlesService,
    private router: Router,
    private listUrl: UrListService,
    private route: ActivatedRoute,
  ){
    this.route.params.forEach(pr => this.paramId = pr.id || '');
    this.titles.title = this.title;
    if (this.auth.isLogin()) {
      this.organizaAppmodulo(this.auth.user.apps, this.auth.user.modulos, this.auth.user.apps_groups); //Ordena los modulos dentro de las apps y cambia los nombres de campos
    }
    if(this.paramId == ''){ //Si no hay paramId se muestra la lista de apps ordenada
      this.setMostrar(this.origen);
      this.get_user_modulos_apps();//Se llama para confirmar que la lista de apps y modulos este actualizada 
    }else{
      let modulos = this.origen.reduce((arr: any,modu: any) => {
        if (modu.idapp == this.paramId){ //Si hay paramID se comprueba de cual app es para mostrar sus modulos
          this.setTitlename(modu.name);
          arr = modu.modulos;
          this.bget = true;
        }
        // else if ( modu.isgroup ){//Se comprueba si la instancia es un grupo, y se verifica el paramID con las apps de este grupo
        //   this.bget = true;
        //   let aps = modu.modulos.reduce( (arr2: any, apps:any)=>{
        //     if(apps.idapp == this.paramId){
        //       this.setTitlename(apps.name);
        //       arr2 = apps.modulos;
        //     }
        //     return arr2;
        //   });
        //   if (aps.length > 1) {
        //     arr = aps;
        //   }
        // }
        return arr;
      }, []);
      this.return = true;
      this.mostrar = modulos;
    }
    setTimeout(() => {
      this.titles.setselectAside(0);
    }, 1);
  }
  
  ngOnInit() {
  }

  dirige(app){
    if(app.isapp){
      if(app.modulos.length == 1){
        this.titles.titlebar = app.name;
        this.router.navigate([app.modulos[0].ruta]);
      }else{
        this.titles.titlebar = app.name;
        // this.return = true;
        // this.mostrar = app.modulos;
        this.router.navigate(['/home/'+app.idapp]);
      }
    }else{
      this.router.navigate([app.ruta]);
    }
    if(app.isgroup){

    }
  }

  get_user_modulos_apps(){
    const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('get_user_apps_modulos'),
        auth: this.auth,
        body: {modulos: 1, apps: 1}
    };
    const configAjax: Config = {
        visible: false,
        titles: this.titles,
        force_update: true
    };
    const ajax = new Ajax(initAjax, configAjax);
    ajax.call().then(respo => {
        let usr = Object.assign(this.auth.user);
        usr.modulos = respo.modulos;
        usr.apps = respo.apps;
        usr.apps_groups = respo.apps_groups;
        this.auth.user = usr;
        this.organizaAppmodulo(usr.apps, usr.modulos, usr.apps_groups);
        this.titles.even_sync_appsmodulos.emit({apps: usr.apps, modulos: usr.modulos, apps_groups: usr.apps_groups});
        this.setMostrar(this.origen);
        this.bget = true;
    }).catch(error => {
      let err = error;
      //errores
    });
  }

  organizaAppmodulo(apps,moduls,grup){
    let gropo = grup.reduce((arar: any,gr: any) => {
      arar.push(this.formatoNgforGroup(gr));
      return arar;
    }, []);
    if(Array.isArray(apps) && Array.isArray(moduls)){
      let todo = apps.reduce((ar: any,app: any) => {
        let modulos = moduls.reduce((arr: any,modu: any) => {
          if (modu.aplicaciones_idapp == app.idapp){
            arr.push(this.formatoNgfor(modu,false));
          }
          return arr;
        }, []);
        let appp = this.formatoNgfor(app);
        modulos.sort((a,b)=>a.orden-b.orden);
        appp.modulos = modulos;
        if(appp.idgroup){
          let gk = gropo.findIndex( gg => { return gg.idgroup == appp.idgroup;  });
          gropo[gk].modulos.push(appp);
        }else{
          ar.push(appp);
        }
        return ar;
      }, []);
      gropo.reduce((arr: any,gr: any) => {
        if(gr.modulos.length > 1){
          todo.push(gr);
        }else if(gr.modulos.length == 1){
          gr.modulos[0].orden = gr.orden;
          todo.push(gr.modulos[0]);
        }
        return arr;
      }, []);
      todo.sort((a,b)=>a.orden-b.orden);
      this.origen = todo;
    }else{
      this.alertaCerrar();
    }
  }

  formatoNgfor(vari, tipo=true){
    // tipo: true para apps, false para modulos
    if(tipo){//para apps
      let res =  this.getTransacciones(vari);
      return {
        idapp: vari.idapp,
        idgroup: vari.idapps_group,
        ruta:  vari.ruta,
        color: vari.color_p,
        color_s: vari.color_s,
        name:  vari.nombre_app,
        desc:  vari.detalle_app,
        icono: false,
        png: vari.imagen_app,
        modulos: [],
        isapp: true,
        isgroup: false,
        istransac: res.istra,
        transac: res.respo,
        agotado: res.agotado,
        ilimitado: res.ilimitado,
        orden: vari.orden_show,
      };
    }else{//para modulos
      return {
        idapp: false,
        idgroup: false,
        ruta:  vari.ruta_modulo,
        color: vari.color_p,
        color_s: vari.color_s,
        name:  vari.nombre_modulo,
        desc:  vari.detalle_modulo,
        icono: false,
        png: vari.imagen_modulo,
        modulos: [],
        isapp: false,
        isgroup: false,
        istransac: false,
        transac: 0,
        agotado: false,
        orden: vari.orden_show,
      };
    }
  }

  formatoNgforGroup(vari){
    return {
      idapp: 1000 + vari.idapps_group,
      idgroup: vari.idapps_group,
      ruta:  '',
      color: '',
      color_s: '',
      name:  vari.nombre_group,
      desc:  vari.detalle_group,
      icono: false,
      png: vari.imagen_group,
      modulos: [],
      isapp: true,
      isgroup: true,
      istransac: false,
      transac: 0,
      agotado: false,
      orden: vari.orden_show,
    };
  }

  setMostrar(mostrar){
    if(Array.isArray(mostrar) && mostrar.length > 0){
      this.mostrar = mostrar;
    }else{
      this.alertaCerrar();
    }
  }

  getTransacciones(vari){
    let res = {
      num: 0,
      respo: "AGOTADO",
      agotado:true,
      istra: false,
      ilimitado: false,
    };
    if (!isNaN(Number(vari.transacciones)) && !isNaN(Number(vari.transacciones_adicionales)) ) {
      res.num = Number(vari.transacciones)+Number(vari.transacciones_adicionales);
    }
    if( vari.hasOwnProperty('istx') && vari.istx ){
      res.istra = true;
      if(vari.restricciones == 0){
        res.agotado = false;
        res.respo = "ILIMITADO";
        res.ilimitado = true
      }else{
        if(res.num > 0){
          res.respo = ""+res.num;
          res.agotado = false;
        }
        // if(res.num < 0){
        //   res.respo = "SIN LÍMITE";
        //   res.agotado = false;
        //   res.ilimitado = true
        // }
      }
    }
    return res;
  }

  setTitlename(name){
    setTimeout(() => {
      this.titles.titlebar = name;
    }, 1);
  }

  alertaCerrar(){
    if(this.cerrar_sesion){
      this.cerrar_sesion = false;
      let timerInterval;
      Swal.mixin({
        icon: 'error',
        title: 'No posee aplicaciones ni módulos activos',
        html: 'Cerrando Sesión en <b></b>',
        showConfirmButton: false,
        showCancelButton: false,
        timer: 5000,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          let b:any = Swal.getHtmlContainer().querySelector('b');
          timerInterval = setInterval(() => {
            b.textContent = Math.ceil(Swal.getTimerLeft()*0.001);
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      }).fire().then((result) => {
        this.auth.logout();
      });
    }
  }

}
