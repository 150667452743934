import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { SyncService } from 'src/app/vel/synchronization/sync.service';
import { Init } from 'src/app/core/interfaces/init';
import { UrlBase } from 'src/app/core/url/url';
import { ListUrl } from 'src/app/core/constantes/request';
import { Ajax } from 'src/app/core/utils/ajax';
import { Config } from 'src/app/core/interfaces/config';
import { UrListService } from 'src/app/core/url/url.service';
import * as Chart from 'chart.js';
import { TitlesService } from 'src/app/core/services/titles.service';
import { session, session_error } from 'src/app/core/constantes/sessiones';
import { Cache } from 'src/app/core/utils/cache';

@Injectable({
  providedIn: 'root'
})
export class  EventosService {
  peticion: boolean = false;
  reporte_especial: any =[];
  name_sucu = '';
  fecha_desde_trans: any;
  fecha_hasta_trans: any;
  modo_gestion_user: boolean = true;
  modo_gestion_user_vende: boolean = true;
  modo_gestion_vel_adm: boolean = true;
  modo_fecha: boolean = true;
  change_modo_fecha: boolean = true;
  respuesta_all: any = [];

  desc_pdf = '';
  boton_detalle: boolean = false;
  name_leyenda: string;
  sucu_info_aux = [];
  informacion_suc = [];
  modo: boolean = true;
  modo_2: boolean = true;
  modo_usuario: boolean = true;
  data_porcen = [];
  data_cuantitativo = [];
  title = 'Estadisticas';
  descripcion = '';
  campo1 = 'vacio';
  campo2 = 'vacio';
  campo3 = 'vacio';
  campo4 = 'vacio';
  evento = 'No definido';
  grafic_select = 0;
  valor_grafic =  ['bar','pie','doughnut' ,'polarArea' ,'line'];
  type_grafic =  ['Grafico de barras','Grafico pastel','Grafico de dona' ,'Grafico de area polar' ,'Grafico de linea'];
  data = [];
  data_grafica = [];
  label = [];
  color= ['red','blue' ,'turquoise' ,'indigo' ,'green' , 'gray' ,'pink' , 'yellow', 'orange','cyan','purple','brown','violet','fuchsia','olive','teal','navy','lime','aqua'];
  backgroundColor = [];
  type = 'bar';
  valor1= 'vacio';
  valor2= 'vacio';
  valor3= 'vacio';
  valor4= 'vacio';
  fecha_desde: any;
  fecha_hasta: any;
  Modal: any;
  closeModal: any;
  select_event: any;
  select_event_const: any;
  select_app: any;
  select_app_const: any; 
  list_event: any;
  respuesta: any;
  sucursal_mas_actividad: any;
  sucursal_menos_actividad: any;
  all_sucursales = [];
  id_sucursal: any;
  id_route: any;
  totalidad = 0;
  totalidad_360 = 0;
  totalidad_vendedores = 0;
  mensaje = 'Balance General Estadistico.';
  list_app = [
    {'id' : 0, 'nombre_app' : 'Aplicaciones n360'},
    {'id' : 1, 'nombre_app' : 'ADM Ventas En Linea'},
    {'id' : 2, 'nombre_app' : 'Gestion de Usuarios'}
  // {'evento_id' : 4, 'nombre_evento' : 'Usuarios'},
  //  {'evento_id' : 5, 'nombre_evento' : 'Aplicaciones n360'},
  ];
  list_trans = [
    {'evento_id' : 0, 'nombre_evento' : 'Transacciones en General'},
    {'evento_id' : 1, 'nombre_evento' : 'Pedidos'},
    {'evento_id' : 2, 'nombre_evento' : 'Cobranzas'},
    {'evento_id' : 3, 'nombre_evento' : 'Facturas'},
  //  {'evento_id' : 4, 'nombre_evento' : 'Usuarios'},
  //  {'evento_id' : 5, 'nombre_evento' : 'Aplicaciones n360'},
  ];

  list_app_select = [
    {'evento_id' : 0, 'nombre_evento' : 'Transacciones en General'},
    {'evento_id' : 1, 'nombre_evento' : 'Estadistica Por Aplicacion'},
    {'evento_id' : 2, 'nombre_evento' : 'Pedidos'},
    {'evento_id' : 3, 'nombre_evento' : 'Cobranzas'},
    {'evento_id' : 4, 'nombre_evento' : 'Facturas'},
    {'evento_id' : 5, 'nombre_evento' : 'Usuarios'},
  //  {'evento_id' : 5, 'nombre_evento' : 'Aplicaciones n360'},
  ];

  list_seleccionada = [];

  indice_mayor: any;
  indice_menor: any;
  grafica:any;

  //variables modal
  id_evento = []; //balance por sucursal
  frecuencia_total = 0;

  //labes de evento, cuantitativo y porcentaje de los eventos dentro de una sucursal
  descrip_sucu = [];
  cuanti_sucu = [];
  porcen_sucu = [];

  //data para grafico cuatitativo - porcentaje por sucursal 
  cuanti_grafic_sucu = [];
  porcen_grafic_sucu = [];
  //label sucursal y label por fecha (aux para guardar info)
  label_sucu = [];
  label_fecha = [];

  //label1(adm ventas en linea) y label2 (gestion de usuario) + data 1 y data 2 de Aplicaciones..... especial del selector aplicaciones n360, las variables que estan debajo del comentario pertenecen al selector
  cuanti_venta = [];
  porcen_venta = [];
  label_app_venta = [];

  label_app_usua = [];
  cuanti_usua = [];
  porcen_usua = [];

  //por sucursal app n360 y informacion por sucursal
  cuanti_grafic_sucu_venta = [];
  porcen_grafic_sucu_venta = [];

  cuanti_grafic_sucu_usua = [];
  porcen_grafic_sucu_usua = [];

  informacion_suc_app = [];
  informacion_suc_type_user = [];
  //valores para generar una grafica dado una sucursal, se detallara por separado para dar una nocion al usuario 
  cuanti_sucu_vent = [];
  porcen_sucu_vent = [];

  cuanti_sucu_usua = [];
  porcen_sucu_usua = [];

  //valores para generar una grafica dado una sucursal, se detallara por separado para dar una nocion al usuario 
  cuanti_sucu_usua_360 = [];
  porcen_sucu_usua_360 = [];

  cuanti_sucu_usua_vendedor = [];
  porcen_sucu_usua_vendedor = [];

  //info_suc_app = [];

  cuanti_grafic_usua_tipo = []; // tipo usuario
  porcen_grafic_usua_tipo = [];

  porcen_usua_tipo_n360 = [];
  cuanti_usua_tipo_n360 = [];
  porcen_usua_tipo_vendedor = [];
  cuanti_usua_tipo_vendedor = [];

  cuanti_grafic_sucu_usua_360 = [];
  porcen_grafic_sucu_usua_360 = [];

  cuanti_grafic_sucu_usua_vendedor = [];
  porcen_grafic_sucu_usua_vendedor = [];

  //------------------------------------------------------- sucursal transacciones

  graphic_mensual_usua_cuanti = [];
  graphic_mensual_usua_porcen = [];

  graphic_mensual_usua_vende_cuanti = [];
  graphic_mensual_usua_vende_porcen = [];

  graphic_mensual_vel_vende_cuanti = [];
  graphic_mensual_vel_vende_porcen = [];



  //------------------------------------- tiempo  transacciones
  graphic_data_mensual_usua_cuanti = [];
  graphic_data_mensual_usua_porcen = [];

  graphic_data_mensual_usua_vende_cuanti = [];
  graphic_data_mensual_usua_vende_porcen = [];

  graphic_data_mensual_vel_vende_cuanti = [];
  graphic_data_mensual_vel_vende_porcen = [];
  cargar = 0;


  constructor(
    private router: Router,
    public auth: AuthService,
    public sync : SyncService,
    private listUrl: UrListService,
    private titles: TitlesService,
  ){ 
  }

  loading_pedi(){
    this.descripcion = 'La informacion suministrada en esta seccion, muestra un balance estadistico de las transacciones efectuadas dentro de un rango de fechas';
    this.campo1 = 'Actividad de la sucursal';
    this.campo3 = 'Sucursales';
    this.campo4 = 'Suc. Exam -Transaccion';
    this.evento = 'Transaccion';
    this.mensaje = 'Balance Estadistico de Transacciones Aprobadas.';
    this.type = 'pie';
    this.name_leyenda = 'General';
  }

  loading_uso_app(){
    this.descripcion = 'La informacion suministrada en esta seccion, muestra un balance estadistico de uso general de los eventos dentro de un rango de fechas';
    this.campo1 = 'Actividad de la sucursal';
    this.campo3 = 'Sucursales';
    this.campo4 = 'Suc. Exam - Fr de uso';
    this.evento = 'Balance General de Eventos';
    this.type = 'pie';
    this.mensaje = 'Balance General Estadistico.';
    this.name_leyenda = 'General';
  }

  loading_uso_modulos(){
    this.descripcion = 'La informacion suministrada en esta seccion, muestra un balance estadistico del uso de un modulo en particular apartir de un rango de fechas';
    this.campo1 = 'Actividad de la sucursal'; // nombre del modulo el dia con mas uso
    this.campo3 = 'Sucursales'; // 
    this.mensaje = 'Balance de Uso Detallado Por Eventos';
    this.campo4 = 'Suc. Exam - Fr de uso'; //
    this.evento = 'Uso de un modulo';
    this.type = 'pie';
    this.name_leyenda = 'General';
    
  }

  loading_transacciones( fecha_desde , fecha_hasta){
    this.descripcion = 'La informacion suministrada en esta seccion, muestra un balance estadistico de los movimientos de las transacciones del mes en curso.';
    this.campo1 = 'S/N'; // nombre del modulo el dia con mas uso
    this.campo3 = 'S/N'; // 
    this.mensaje = 'Transacciones Sucursales';
    this.campo4 = 'S/N'; //
    this.evento = 'Transacciones';
    this.type = 'pie';
    this.name_leyenda = 'Transacciones del mes';
    
  }

  sucursal_menos(){
    let aux_menos = 0;
    let frecuencia_total = 0;
    
    for(let i=0; i< this.respuesta.length; i++){

      let empresas = this.respuesta[i].informacion;
      aux_menos = 0;
      for(let j = 0; j < empresas.length; j++){
        aux_menos = Number(empresas[j].frecuencia) + aux_menos;
      }
      if(i==0){
       this.valor2 = this.respuesta[i].sucursal['idsucursal'];
       frecuencia_total = aux_menos;
       this.sucursal_menos_actividad = this.respuesta[i].sucursal;
       this.indice_menor = i;
      }

      if(aux_menos < frecuencia_total && i!=0 && aux_menos > 0){
        this.valor2 = this.respuesta[i].sucursal['idsucursal'];
        frecuencia_total = aux_menos;
        this.sucursal_menos_actividad = this.respuesta[i].sucursal;
        this.indice_menor = i;
      } 
    }
  }

  sucursal_mas(){
    let aux_mas = 0;
    let frecuencia_total = 0;
    
    for(let i=0; i< this.respuesta.length; i++){

       let empresas = this.respuesta[i].informacion;
       aux_mas = 0;

       for(let j = 0; j < empresas.length; j++){
          aux_mas = Number(empresas[j].frecuencia) + aux_mas;
       }

       if(aux_mas > frecuencia_total){
          //this.valor1 = this.respuesta[i].sucursal['nombre_sucu'];
          //this.valor1 = this.respuesta[i].sucursal['idsucursal'];
          frecuencia_total = aux_mas;
          this.sucursal_mas_actividad = this.respuesta[i].sucursal;
          this.indice_mayor = i;
        //  this.id_sucursal = i;
        }

        const array = {
          id: this.respuesta[i].sucursal['idsucursal'],
          nombre: this.respuesta[i].sucursal['nombre_sucu'],
          cantidad: aux_mas
        }

        this.sucu_info_aux.push(array);
    }

  }

  cobranza_mas(){
    let aux_mas = 0;
    let frecuencia_total = 0;

    for(let i=0; i< this.respuesta.length; i++){

       let empresas = this.respuesta[i].informacion;
       aux_mas = 0;

       for(let j = 0; j < empresas.length; j++){
           aux_mas = 1 + aux_mas;
       }

       if(aux_mas > frecuencia_total){
          //this.valor1 = this.respuesta[i].sucursal['nombre_sucu'];
          frecuencia_total = aux_mas;
          this.sucursal_mas_actividad = this.respuesta[i].sucursal;
          this.indice_mayor = i;
        //  this.id_sucursal = i;
        }

       if(aux_mas > 0){
         const array = {
          id: this.respuesta[i].sucursal['idsucursal'],
          nombre: this.respuesta[i].sucursal['nombre_sucu'],
          cantidad: aux_mas
        }

        this.sucu_info_aux.push(array);
       }
    }
  }

  pedidos_mas(){
    let aux_mas = 0;
    let frecuencia_total = 0;
    
    for(let i=0; i< this.respuesta.length; i++){

       let empresas = this.respuesta[i].informacion;
       aux_mas = 0;

       for(let j = 0; j < empresas.length; j++){
         if( Number(empresas[j].estado)== 1){
           aux_mas = Number(empresas[j].estado) + aux_mas;
         }
       }

       if(aux_mas > frecuencia_total){
          //this.valor1 = this.respuesta[i].sucursal['nombre_sucu'];
          frecuencia_total = aux_mas;
          this.sucursal_mas_actividad = this.respuesta[i].sucursal;
          this.indice_mayor = i;
        //  this.id_sucursal = i;
        }

       if(aux_mas > 0){
         const array = {
          id: this.respuesta[i].sucursal['idsucursal'], 
          nombre: this.respuesta[i].sucursal['nombre_sucu'],
          cantidad: aux_mas
        }

        this.sucu_info_aux.push(array);
       }
    }
  }

  pedidos_menos(){
    let aux_menos = 0;
    let frecuencia_total = 0;
    
    for(let i=0; i< this.respuesta.length; i++){

       let empresas = this.respuesta[i].informacion;
       aux_menos = 0;
       for(let j = 0; j < empresas.length; j++){
         if( Number(empresas[j].estado)== 1){
           aux_menos = Number(empresas[j].estado) + aux_menos;
         }
       }
      if(i==0){
       this.valor2 = this.respuesta[i].sucursal['idsucursal'];
       frecuencia_total = aux_menos;
       this.sucursal_menos_actividad = this.respuesta[i].sucursal;
       this.indice_menor = i;
      }

      if(aux_menos < frecuencia_total && i!=0  && aux_menos>0){
        this.valor2 = this.respuesta[i].sucursal['idsucursal'];
        frecuencia_total = aux_menos;
        this.sucursal_menos_actividad = this.respuesta[i].sucursal;
        this.indice_menor = i;
      }
    }
  }

  reiniciar_variables(){
    this.reporte_especial =[];
    this.cargar = 0;
    this.peticion = false;
    this.name_sucu = '';
    this.desc_pdf = '';
    this.respuesta_all= [];
    this.select_event_const = undefined;
    this.select_app_const = undefined;
    this.name_leyenda = 'General';
    this.valor1= 'vacio';
    this.valor2='vacio';
    this.valor3='vacio';
    this.valor4='vacio';
    this.data = [];
    this.label = [];
    this.backgroundColor = [];
    this.indice_menor = undefined;
    this.indice_mayor = undefined;
    this.all_sucursales = [];
    this.data_grafica = [];
    this.data_porcen = [];
    this.data_cuantitativo = [];
    this.informacion_suc = [];
    this.sucu_info_aux = [];
    this.id_evento = [];
    this.descrip_sucu = [];
    this.cuanti_sucu = [];
    this.porcen_sucu = [];
    this.cuanti_grafic_sucu = [];
    this.porcen_grafic_sucu = [];
    this.label_sucu = [];
    this.label_fecha = [];
    this.cuanti_venta = [];
    this.porcen_venta = [];
    this.label_app_venta = [];

    this.label_app_usua = [];
    this.cuanti_usua = [];
    this.porcen_usua = [];
    this.cuanti_grafic_sucu_venta = [];
    this.porcen_grafic_sucu_venta = [];

    this.cuanti_grafic_sucu_usua = [];
    this.porcen_grafic_sucu_usua = [];
    this.informacion_suc_app = [];

    this.informacion_suc_type_user = [];


    this.cuanti_sucu_vent = [];
    this.porcen_sucu_vent = [];

    this.cuanti_sucu_usua = [];
    this.porcen_sucu_usua = [];

    this.id_sucursal = undefined;

    this.boton_detalle = false;

    this.porcen_usua_tipo_n360 = [];
    this.cuanti_usua_tipo_n360 = [];
    this.porcen_usua_tipo_vendedor = [];
    this.cuanti_usua_tipo_vendedor = [];
    this.totalidad_360 = 0;
    this.totalidad_vendedores = 0;
    this.cuanti_grafic_sucu_usua_360 = [];
    this.porcen_grafic_sucu_usua_360 = [];

    this.cuanti_grafic_sucu_usua_vendedor = [];
    this.porcen_grafic_sucu_usua_vendedor = [];

    this.cuanti_sucu_usua_360 = [];
    this.porcen_sucu_usua_360 = [];

    this.cuanti_sucu_usua_vendedor = [];
    this.porcen_sucu_usua_vendedor = [];


    this.graphic_mensual_usua_cuanti = [];
    this.graphic_mensual_usua_porcen = [];

    this.graphic_mensual_usua_vende_cuanti = [];
    this.graphic_mensual_usua_vende_porcen = [];

    this.graphic_mensual_vel_vende_cuanti = [];
    this.graphic_mensual_vel_vende_porcen = [];


    this.graphic_data_mensual_usua_cuanti = [];
    this.graphic_mensual_usua_porcen = [];

    this.graphic_data_mensual_usua_vende_cuanti = [];
    this.graphic_data_mensual_usua_vende_porcen = [];

    this.graphic_data_mensual_vel_vende_cuanti = [];
    this.graphic_data_mensual_vel_vende_porcen = [];

  }

  getTransacciones(): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        //url: this.listUrl.url('event_login'),
        url: this.listUrl.url('event_transacciones'),
        auth: this.auth,
        body: { 
          fecha_desde : moment(Cache.getLocal(session.init_date, true).strdate).format('YYYY-MM-01'),
          fecha_hasta : moment(Cache.getLocal(session.init_date, true).strdate).format('YYYY-MM-') + moment().daysInMonth(),
          nombre_app : 'Aplicaciones n360',
          nombre_evento : 'Transacciones en General',
          vel_adm : this.modo_gestion_vel_adm,
          gestion_user : this.modo_gestion_user,
          gestion_user_vende : this.modo_gestion_user_vende,
          modo_fecha : true
        }
      };
      const configAjax: Config = {
        visible: true,
        autoReNew: true,
        time_update: 600000,
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        this.informacion_suc = resp.transacciones;
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getreporte(event,idsucursal): Promise<any> {
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('get_reporte_transaccion'),
        auth: this.auth,
        body: { 
          fecha_desde : moment(Cache.getLocal(session.init_date, true).strdate).format('YYYY-MM-01'),
          fecha_hasta : moment(Cache.getLocal(session.init_date, true).strdate).format('YYYY-MM-') + moment().daysInMonth(),
          name_reporte : event,
          idsucursal: idsucursal
        }
      };
      const configAjax: Config = {
        visible: true,
        autoReNew: true,
        time_update: 600000,
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        this.reporte_especial = resp.entities;
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }


  getEstadistica(fecha_desde,fecha_hasta): Promise<any> {
    this.fecha_desde = moment(fecha_desde).format('YYYY-MM-DD');
    this.fecha_hasta = moment(fecha_hasta).format('YYYY-MM-DD');
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('get_estadistica'),
        auth: this.auth,
        body: {
          fecha_desde : this.fecha_desde,
          fecha_hasta : this.fecha_hasta,
          nombre_evento : this.select_event,
          base_dato: 'todas_bd'
        }
      };
      const configAjax: Config = {
        visible: true,
        autoReNew: true,
        time_update: 600000,
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        this.respuesta = resp.entities;

        this.respuesta = resp.entities;
        
        if(typeof resp.reporte_general != undefined && typeof resp.reporte_general != null){
          if(typeof resp.reporte_general.all_sucursales != undefined && typeof resp.reporte_general.all_sucursales != null){
            this.all_sucursales=resp.reporte_general.all_sucursales;
          }
          if(typeof resp.reporte_general.data != undefined && typeof resp.reporte_general.data != null){
            this.data = resp.reporte_general.data;
          }

          if(typeof resp.reporte_general.label != undefined && typeof resp.reporte_general.label != null){
            this.label = resp.reporte_general.label;
          }

          if(typeof resp.reporte_general.data_cuantitativo != undefined && typeof resp.reporte_general.data_cuantitativo != null){
            this.data_cuantitativo = resp.reporte_general.data_cuantitativo;
          }

          if(typeof resp.reporte_general.valor2 != undefined && typeof resp.reporte_general.valor2 != null){
            this.valor2 = resp.reporte_general.valor2;
          }

          if(typeof resp.reporte_general.sucursal_menos_actividad != undefined && typeof resp.reporte_general.sucursal_menos_actividad != null){
            this.sucursal_menos_actividad = resp.reporte_general.sucursal_menos_actividad;
          }

          if(typeof resp.reporte_general.indice_menor != undefined && typeof resp.reporte_general.indice_menor != null){
            this.indice_menor = resp.reporte_general.indice_menor;
          }

          if(typeof resp.reporte_general.sucursal_mas_actividad != undefined && typeof resp.reporte_general.sucursal_mas_actividad != null){
            this.sucursal_mas_actividad = resp.reporte_general.sucursal_mas_actividad;
          }

          if(typeof resp.reporte_general.indice_mayor != undefined && typeof resp.reporte_general.indice_mayor != null){
            this.sucursal_mas_actividad = resp.reporte_general.sucursal_mas_actividad;
          }

          if(typeof resp.reporte_general.sucu_info_aux != undefined && typeof resp.reporte_general.sucu_info_aux != null){
            this.sucu_info_aux = resp.reporte_general.sucu_info_aux;
          }

          if(typeof resp.reporte_general.grafica != undefined && typeof resp.reporte_general.grafica != null){
            this.grafica = resp.reporte_general.grafica;
          }
        }

        if(this.data.length==0){
          this.mensaje = 'No existen registros de esta actividad.';
        }else{
          this.valor1 = "Sucursales en Gnral";
          this.label_fecha = this.label;
        }
        this.porcentaje();
        this.calculo_info_sucu();
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getModulos(fecha_desde,fecha_hasta): Promise<any> {
    this.fecha_desde = moment(fecha_desde).format('YYYY-MM-DD');
    this.fecha_hasta = moment(fecha_hasta).format('YYYY-MM-DD');
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('event_modulos'),
        auth: this.auth,
        body: {
          fecha_desde : this.fecha_desde,
          fecha_hasta : this.fecha_hasta,
          nombre_evento : this.select_event,
          base_dato: 'todas_bd'
        }
      };
      const configAjax: Config = {
        visible: true,
        autoReNew: true,
        time_update: 600000,
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {

        this.select_event_const = this.select_event;
        this.respuesta = resp.entities;

        if(typeof resp.reporte_general != undefined && typeof resp.reporte_general != null){
          if(typeof resp.reporte_general.all_sucursales != undefined && typeof resp.reporte_general.all_sucursales != null){
            this.all_sucursales=resp.reporte_general.all_sucursales;
          }
          if(typeof resp.reporte_general.data != undefined && typeof resp.reporte_general.data != null){
            this.data = resp.reporte_general.data;
          }

          if(typeof resp.reporte_general.label != undefined && typeof resp.reporte_general.label != null){
            this.label = resp.reporte_general.label;
          }

          if(typeof resp.reporte_general.data_cuantitativo != undefined && typeof resp.reporte_general.data_cuantitativo != null){
            this.data_cuantitativo = resp.reporte_general.data_cuantitativo;
          }

          if(typeof resp.reporte_general.valor2 != undefined && typeof resp.reporte_general.valor2 != null){
            this.valor2 = resp.reporte_general.valor2;
          }

          if(typeof resp.reporte_general.sucursal_menos_actividad != undefined && typeof resp.reporte_general.sucursal_menos_actividad != null){
            this.sucursal_menos_actividad = resp.reporte_general.sucursal_menos_actividad;
          }

          if(typeof resp.reporte_general.indice_menor != undefined && typeof resp.reporte_general.indice_menor != null){
            this.indice_menor = resp.reporte_general.indice_menor;
          }

          if(typeof resp.reporte_general.sucursal_mas_actividad != undefined && typeof resp.reporte_general.sucursal_mas_actividad != null){
            this.sucursal_mas_actividad = resp.reporte_general.sucursal_mas_actividad;
          }

          if(typeof resp.reporte_general.indice_mayor != undefined && typeof resp.reporte_general.indice_mayor != null){
            this.sucursal_mas_actividad = resp.reporte_general.sucursal_mas_actividad;
          }

          if(typeof resp.reporte_general.sucu_info_aux != undefined && typeof resp.reporte_general.sucu_info_aux != null){
            this.sucu_info_aux = resp.reporte_general.sucu_info_aux;
          }
        }

        if(this.data.length==0){
          this.mensaje = 'No existen registros de esta actividad.';
        }else{
          this.valor1 = "Sucursales en Gnral";
          this.label_fecha = this.label;
        }
  

        this.porcentaje();
        this.calculo_info_sucu();

      }).catch(error => {
        reject(error);
      });
    });
  }


  getPedidos(fecha_desde,fecha_hasta): Promise<any> {
    this.change_modo_fecha = this.modo_fecha;
    this.fecha_desde = moment(fecha_desde).format('YYYY-MM-DD');
    this.fecha_hasta = moment(fecha_hasta).format('YYYY-MM-DD');
    return new Promise((resolve, reject) => {
      const initAjax: Init = {
        method: 'post',
        url: this.listUrl.url('reporte_pedidos'),
        auth: this.auth,
        body: {
          fecha_desde : this.fecha_desde,
          fecha_hasta : this.fecha_hasta,
          nombre_evento : this.select_event,
          nombre_app: this.select_app,
          base_dato: 'todas_bd',
          modo_fecha : this.modo_fecha
        }
      };
      const configAjax: Config = {
        visible: true,
        autoReNew: true,
        time_update: 600000,
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(resp => {
        this.respuesta = resp.entities;
        this.select_event_const = this.select_event;
        this.select_app_const = this.select_app;

        if(typeof resp.reporte_general != undefined && typeof resp.reporte_general != null){
          if(typeof resp.reporte_general.all_sucursales != undefined && typeof resp.reporte_general.all_sucursales != null){
            this.all_sucursales=resp.reporte_general.all_sucursales;
          }

          if(typeof resp.reporte_general.cuanti_usua != undefined && typeof resp.reporte_general.cuanti_usua != null){
            this.cuanti_usua = resp.reporte_general.cuanti_usua;
          }

          if(typeof resp.reporte_general.cuanti_venta != undefined && typeof resp.reporte_general.cuanti_venta != null){
            this.cuanti_venta = resp.reporte_general.cuanti_venta;
          }

          if(typeof resp.reporte_general.data != undefined && typeof resp.reporte_general.data != null){
            this.data = resp.reporte_general.data;
          }

          if(typeof resp.reporte_general.label != undefined && typeof resp.reporte_general.label != null){
            this.label = resp.reporte_general.label;
          }

          if(typeof resp.reporte_general.label_app_usua != undefined && typeof resp.reporte_general.label_app_usua != null){
            this.label_app_usua = resp.reporte_general.label_app_usua;
          }

          if(typeof resp.reporte_general.label_app_venta != undefined && typeof resp.reporte_general.label_app_venta != null){
            this.label_app_venta = resp.reporte_general.label_app_venta;
          }

          if(typeof resp.reporte_general.data_cuantitativo != undefined && typeof resp.reporte_general.data_cuantitativo != null){
            this.data_cuantitativo = resp.reporte_general.data_cuantitativo;
          }

          if(typeof resp.reporte_general.valor2 != undefined && typeof resp.reporte_general.valor2 != null){
            this.valor2 = resp.reporte_general.valor2;
          }

          if(typeof resp.reporte_general.valor1 != undefined && typeof resp.reporte_general.valor1 != null){
            this.valor1 = resp.reporte_general.valor1;
          }

          if(typeof resp.reporte_general.sucursal_menos_actividad != undefined && typeof resp.reporte_general.sucursal_menos_actividad != null){
            this.sucursal_menos_actividad = resp.reporte_general.sucursal_menos_actividad;
          }

          if(typeof resp.reporte_general.label_fecha != undefined && typeof resp.reporte_general.label_fecha != null){
            this.label_fecha = resp.reporte_general.label_fecha;
          }

          if(typeof resp.reporte_general.indice_menor != undefined && typeof resp.reporte_general.indice_menor != null){
            this.indice_menor = resp.reporte_general.indice_menor;
          }

          if(typeof resp.reporte_general.sucursal_mas_actividad != undefined && typeof resp.reporte_general.sucursal_mas_actividad != null){
            this.sucursal_mas_actividad = resp.reporte_general.sucursal_mas_actividad;
          }

          if(typeof resp.reporte_general.indice_mayor != undefined && typeof resp.reporte_general.indice_mayor != null){
            this.sucursal_mas_actividad = resp.reporte_general.sucursal_mas_actividad;
          }

          if(typeof resp.reporte_general.sucu_info_aux != undefined && typeof resp.reporte_general.sucu_info_aux != null){
            this.sucu_info_aux = resp.reporte_general.sucu_info_aux;
          }

          if(typeof resp.reporte_general.sucu_info_aux != undefined && typeof resp.reporte_general.sucu_info_aux != null){
            this.sucu_info_aux = resp.reporte_general.sucu_info_aux;
          }

          if(typeof resp.reporte_general.cuanti_usua_tipo_n360 != undefined && typeof resp.reporte_general.cuanti_usua_tipo_n360 != null){
            this.cuanti_usua_tipo_n360 = resp.reporte_general.cuanti_usua_tipo_n360;
          }

          if(typeof resp.reporte_general.cuanti_usua_tipo_vendedor != undefined && typeof resp.reporte_general.cuanti_usua_tipo_vendedor != null){
            this.cuanti_usua_tipo_vendedor = resp.reporte_general.cuanti_usua_tipo_vendedor;
          }
        }

        if(this.select_event == 'Transacciones en General'){
          this.generar_repo_transaccion_general();
          this.porcentaje();
          this.calculo_info_sucu();
        }

        if(this.select_event == 'Pedidos'){
          this.generar_repo_pedidos();
          this.porcentaje();
          this.calculo_info_sucu();
        }

        if(this.select_event == 'Cobranzas'){
          this.generar_repo_cobranza();
          this.porcentaje();
          this.calculo_info_sucu();
        }

        if(this.select_event == 'Facturas'){
          this.generar_repo_pedidos();
          this.porcentaje();
          this.calculo_info_sucu();
        }

        if(this.select_app == 'Gestion de Usuarios' || this.select_event == 'Usuarios' && this.select_app == 'Aplicaciones n360'){
          this.generar_repo_transaccion_general();
          this.calculos_sucursales_type();
          this.porcentaje();
          this.calculo_info_sucu();
        }

        if(this.select_event == 'Estadistica Por Aplicacion'){
          this.generar_repo_aplicacion();
          this.calculos_sucursales_app();
          //this.sucursal_mayor_transacciones();    
          this.porcentaje_aplicaciones();
          this.calculo_info_sucu();
        }
        
        resolve(resp.success);
      }).catch(error => {
        reject(error);
      });
    });
  }

  generar_repo_pedidos(){
    if(this.data.length==0){
      this.mensaje = 'No existen registros de esta actividad.';
    }else{
      this.valor1 = "Sucursales en Gnral";
      this.label_fecha = this.label;
    }
  }

  generar_repo_cobranza(){
    if(this.data.length==0){
      this.mensaje = 'No existen registros de esta actividad.';
    }else{
      this.valor1 = "Sucursales en Gnral";
      this.label_fecha = this.label;
    }
  }


  generar_repo_aplicacion(){
    if(this.cuanti_venta.length == 0 && this.cuanti_usua.length == 0 ){
      this.mensaje = 'No existen registros de esta actividad.';
    }else{
      this.valor1 = "Sucursales en Gnral";
      this.label_fecha = this.label;
    }
  }

  calculos_sucursales_type(){
    this.cuanti_grafic_sucu_usua_360 = [];
    this.porcen_grafic_sucu_usua_360 = [];

    this.cuanti_grafic_sucu_usua_vendedor = [];
    this.porcen_grafic_sucu_usua_vendedor = [];

    this.informacion_suc_type_user = [];
    for(let i = 0; i < this.respuesta.length ; i++){
      this.cuanti_grafic_sucu_usua_360[i]=0;
      this.cuanti_grafic_sucu_usua_vendedor[i]=0;

      let empresas = this.respuesta[i].informacion;
      let cant_360 = 0;
      let cant_vende = 0;
               
      for(let j = 0 ; j < empresas.length ; j++){
        let fecha_creado = empresas[j].fecha_creado;

        if(fecha_creado != undefined){
          if(empresas[j].tipo_usuario == 1){
            cant_360++;
          }
          if(empresas[j].tipo_usuario == 2){
            cant_vende++;
          }
        }
      }

      this.cuanti_grafic_sucu_usua_360[i]=cant_360;
      this.cuanti_grafic_sucu_usua_vendedor[i]=cant_vende;
    }
  }

  calculos_sucursales_app(){
    this.cuanti_grafic_sucu_venta = [];
    this.porcen_grafic_sucu_venta = [];

    this.cuanti_grafic_sucu_usua = [];
    this.porcen_grafic_sucu_usua = [];

    this.informacion_suc_app = [];
    for(let i = 0; i < this.respuesta.length ; i++){
      this.cuanti_grafic_sucu_venta[i]=0;
      this.cuanti_grafic_sucu_usua[i]=0;

      let empresas = this.respuesta[i].informacion;
      let cant_vent = 0;
      let cant_usua = 0;
               
      for(let j = 0 ; j < empresas.length ; j++){
        let fecha = empresas[j].fecha;
        let FECHRECI = empresas[j].FECHRECI;
        let fecha_creado = empresas[j].fecha_creado;

        if(fecha != undefined){
          if(Number(empresas[j].estado) == 1){
            cant_vent++;
          }
        }

        if(FECHRECI != undefined){
          cant_vent++; 
        }

        if(fecha_creado != undefined){
          cant_usua++;  
        }
      }

      this.cuanti_grafic_sucu_venta[i]=cant_vent;
      this.cuanti_grafic_sucu_usua[i]=cant_usua;
    }
  }

  porcentaje_aplicaciones(){

    for(let r = 0; r < this.label_app_venta.length*2; r++){
      let numer_ale = Math.floor(Math.random() * this.color.length);
      this.backgroundColor.push(this.color[numer_ale]);
    }

    let total_frecuencia_all_sucursal =0;
    this.data = this.cuanti_venta.concat(this.cuanti_usua);
    for(let c = 0; c < this.data.length; c++){
      total_frecuencia_all_sucursal = total_frecuencia_all_sucursal + Number(this.data[c]); 
    }
    this.totalidad = total_frecuencia_all_sucursal;
    if(this.totalidad>0){
      for(let c = 0; c < this.cuanti_usua.length; c++){
        let porcen = (this.cuanti_usua[c] * 100) / this.totalidad;
        if(porcen % 1 == 0){
          this.porcen_usua[c] = porcen;
        }else{
          this.porcen_usua[c] = porcen.toFixed(2);
        }  
      }
      for(let c = 0; c < this.cuanti_venta.length; c++){
        let porcen = (this.cuanti_venta[c] * 100) / this.totalidad;
        if(porcen % 1 == 0){
          this.porcen_venta[c] = porcen;
        }else{
          this.porcen_venta[c] = porcen.toFixed(2);
        }  
      }
   }
   
   this.valor4 = this.all_sucursales.length+'-' + this.totalidad;
  }

  generar_repo_transaccion_general(){
      if(this.data.length==0){
        this.mensaje = 'No existen registros de esta actividad.';
      }else{
        this.valor1 = "Sucursales en Gnral";
        this.label_fecha = this.label;
      }
  }

  sucursal_mayor_transacciones(){
    let aux_mas = 0;
    let frecuencia_total = 0;

    for(let i=0; i< this.respuesta.length; i++){

       let empresas = this.respuesta[i].informacion;
       aux_mas = 0;

       for(let j = 0; j < empresas.length; j++){
         let fecha = empresas[j].fecha;
         let FECHRECI = empresas[j].FECHRECI;
         let fecha_creado = empresas[j].fecha_creado;
          if(fecha != undefined){
            if( Number(empresas[j].estado)== 1){
             aux_mas = Number(empresas[j].estado) + aux_mas;
            }
          }
          if(FECHRECI != undefined){
            aux_mas = 1 + aux_mas;
          }

          if(fecha_creado != undefined){
            aux_mas = 1 + aux_mas;
          }
        }

       if(aux_mas > frecuencia_total){
          //this.valor1 = this.respuesta[i].sucursal['nombre_sucu'];
          frecuencia_total = aux_mas;
          this.sucursal_mas_actividad = this.respuesta[i].sucursal;
          this.indice_mayor = i;
        //  this.id_sucursal = i;
        }

       if(aux_mas > 0){
         const array = {
          id: this.respuesta[i].sucursal['idsucursal'],
          nombre: this.respuesta[i].sucursal['nombre_sucu'],
          cantidad: aux_mas
        }

        this.sucu_info_aux.push(array);
       }
    }
  }

  getEventos(visible=true, titles:any={}):Promise<any> {
    return new Promise((resolve, reject) => {
        const initAjax: Init = {
            method: 'post',
            url: this.listUrl.url('get_eventos'),
            auth: this.auth,
            body: {}
        };
        const configAjax: Config = {
            visible,
            titles,
            time_update: 600000,
        };
        const ajax = new Ajax(initAjax, configAjax);
        ajax.call().then(respo => {
            if(respo.success){

              this.list_event = respo.entities;
              
            }
            resolve(respo);
        }).catch(error => {
            reject(error);
        });
    });
  }

  color_back(){
    for(let r = 0; r < this.label.length*2; r++){
      let numer_ale = Math.floor(Math.random() * this.color.length);
      this.backgroundColor.push(this.color[numer_ale]);
    }
  }

  porcentaje(){
    
    let total_frecuencia_all_sucursal =0;
    for(let c = 0; c < this.data.length; c++){
      if((this.select_app == 'Gestion de Usuarios' || this.select_event == 'Usuarios' && this.select_app == 'Aplicaciones n360')){
        this.totalidad_360 = this.cuanti_usua_tipo_n360[c] + this.totalidad_360;
        this.totalidad_vendedores = this.cuanti_usua_tipo_vendedor[c] + this.totalidad_vendedores;
      }
      total_frecuencia_all_sucursal = total_frecuencia_all_sucursal + Number(this.data[c]); 
    }
    this.totalidad = total_frecuencia_all_sucursal;
    if(this.totalidad>0){
      if((this.select_app == 'Gestion de Usuarios' || this.select_event == 'Usuarios' && this.select_app == 'Aplicaciones n360')){
        
        for(let c = 0; c < this.data.length; c++){
          let porcen = (this.cuanti_usua_tipo_n360[c] * 100) / this.totalidad_360;
          if(porcen % 1 == 0){
            this.porcen_usua_tipo_n360[c] = porcen;
          }else{
            this.porcen_usua_tipo_n360[c] = porcen.toFixed(2);
          }  
          let porcen2 = (this.cuanti_usua_tipo_vendedor[c] * 100) / this.totalidad_vendedores;
          if(porcen2 % 1 == 0){
            this.porcen_usua_tipo_vendedor[c] = porcen2;
          }else{
            this.porcen_usua_tipo_vendedor[c] = porcen2.toFixed(2);
          }
          let porcen3 = (this.data[c] * 100) / this.totalidad;
          if(porcen3 % 1 == 0){
            this.data[c] = porcen3;
          }else{
            this.data[c] = porcen3.toFixed(2);
          }    
        }
      }else{
        for(let c = 0; c < this.data.length; c++){
          let porcen = (this.data[c] * 100) / this.totalidad;
          if(porcen % 1 == 0){
            this.data[c] = porcen;
          }else{
            this.data[c] = porcen.toFixed(2);
          }  
        }
      }
   }
   this.data_porcen = this.data;
   this.valor4 = this.all_sucursales.length+'-' + this.totalidad;
  }

  calculo_info_sucu(){
    this.cuanti_grafic_sucu = [];
    this.porcen_grafic_sucu = [];
    this.label_sucu = [];

    this.porcen_grafic_sucu_venta = [];

    this.porcen_grafic_sucu_usua = [];

    this.informacion_suc_app = [];

    this.informacion_suc_type_user = [];

    if(this.totalidad>0){
      for(let c = 0; c < this.sucu_info_aux.length; c++){
        let porcentajes = (this.sucu_info_aux[c].cantidad * 100) / this.totalidad;
        let porcen;
      
        if(porcentajes % 1 == 0){
          porcen = porcentajes;
        }else{
          porcen = porcentajes.toFixed(2);
        }

        const array = {
          nombre: this.sucu_info_aux[c].nombre,
          frecuencia: this.sucu_info_aux[c].cantidad,
          porcentaje: porcen
        }
        this.informacion_suc.push(array);
        this.label_sucu.push(this.sucu_info_aux[c].nombre);
        this.cuanti_grafic_sucu.push(this.sucu_info_aux[c].cantidad);
        this.porcen_grafic_sucu.push(porcen);
      }



      if(this.select_event == 'Estadistica Por Aplicacion'){
        let totalidad_ventas = 0;
        let totalidad_usuarios = 0;
        for(let c = 0; c < this.cuanti_grafic_sucu_venta.length; c++){
          totalidad_ventas = totalidad_ventas + this.cuanti_grafic_sucu_venta[c];
        }
        for(let c = 0; c < this.cuanti_grafic_sucu_usua.length; c++){
          totalidad_usuarios = totalidad_usuarios + this.cuanti_grafic_sucu_usua[c];
        }

        for(let c = 0; c < this.cuanti_grafic_sucu_venta.length; c++){
          let porcentajes_usua = 0;
          let porcentajes_venta = 0;

          if(totalidad_usuarios > 0){
            porcentajes_usua = (this.cuanti_grafic_sucu_usua[c] * 100) / totalidad_usuarios;
          }

          if(totalidad_ventas > 0){
            porcentajes_venta = (this.cuanti_grafic_sucu_venta[c] * 100) / totalidad_ventas;
          }
          
          
          let porcen_aux_usua;
          let porcen_aux_venta;
      
          if(porcentajes_usua % 1 == 0){
            porcen_aux_usua = porcentajes_usua;
          }else{
            porcen_aux_usua = porcentajes_usua.toFixed(2);
          }

          if(porcentajes_venta % 1 == 0){
            porcen_aux_venta = porcentajes_venta;
          }else{
            porcen_aux_venta = porcentajes_venta.toFixed(2);
          }

          this.porcen_grafic_sucu_venta.push(porcen_aux_venta);
          this.porcen_grafic_sucu_usua.push(porcen_aux_usua);

          const array = {
            nombre: this.respuesta[c].sucursal['nombre_sucu'],
            frecuencia_gestion_usuario: this.cuanti_grafic_sucu_usua[c],
            porcentaje_gestion_usuario: porcen_aux_usua,
            frecuencia_adm_ventas: this.cuanti_grafic_sucu_venta[c],
            porcentaje_adm_ventas: porcen_aux_venta
          }

          this.informacion_suc_app.push(array);
        }
        
      }

      if((this.select_app == 'Gestion de Usuarios' || this.select_event == 'Usuarios' && this.select_app == 'Aplicaciones n360')){
        let totalidad_360 = 0;
        let totalidad_vender = 0;
        for(let c = 0; c < this.cuanti_grafic_sucu_usua_360.length; c++){
          totalidad_360 = totalidad_360 + this.cuanti_grafic_sucu_usua_360[c];
        }
        for(let c = 0; c < this.cuanti_grafic_sucu_usua_vendedor.length; c++){
          totalidad_vender = totalidad_vender + this.cuanti_grafic_sucu_usua_vendedor[c];
        }

        for(let c = 0; c < this.cuanti_grafic_sucu_usua_360.length; c++){
          let porcentajes_360 = 0;
          let porcentajes_vender = 0;

          if(totalidad_360 > 0){
            porcentajes_360 = (this.cuanti_grafic_sucu_usua_360[c] * 100) / totalidad_360;
          }

          if(totalidad_vender > 0){
            porcentajes_vender = (this.cuanti_grafic_sucu_usua_vendedor[c] * 100) / totalidad_vender;
          }
          
          
          let porcen_aux_usua_360;
          let porcen_aux_usua_vender;
      
          if(porcentajes_360 % 1 == 0){
            porcen_aux_usua_360 = porcentajes_360;
          }else{
            porcen_aux_usua_360 = porcentajes_360.toFixed(2);
          }

          if(porcentajes_vender % 1 == 0){
            porcen_aux_usua_vender = porcentajes_vender;
          }else{
            porcen_aux_usua_vender = porcentajes_vender.toFixed(2);
          }

          this.porcen_grafic_sucu_usua_360.push(porcen_aux_usua_360);
          this.porcen_grafic_sucu_usua_vendedor.push(porcen_aux_usua_vender);

          const array = {
            nombre: this.respuesta[c].sucursal['nombre_sucu'],
            frecuencia_360: this.cuanti_grafic_sucu_usua_360[c],
            porcentaje_360: this.porcen_grafic_sucu_usua_360[c],
            frecuencia_vendedor: this.cuanti_grafic_sucu_usua_vendedor[c],
            porcentaje_vendedor: this.porcen_grafic_sucu_usua_vendedor[c],
          }
          this.informacion_suc_type_user.push(array);
        }
      }
    }
  }

  calcular_general(info_sucu){//calculo para generar la grafica dado una sucursal balance general
    //this.id_evento = [];
    this.descrip_sucu = [];
    this.cuanti_sucu = [];
    this.porcen_sucu = [];
    this.frecuencia_total = 0;

    for(let y=0; y< info_sucu.length; y++){
        this.frecuencia_total = this.frecuencia_total + Number(info_sucu[y].frecuencia);
    }


    for(let z=0; z< this.grafica.length; z++){
      let frecuencia :  number;
      frecuencia = 0;
      let ids = 0;
      for(let y=0; y< info_sucu.length; y++){
        if(this.grafica[z].evento == info_sucu[y].evento){
          frecuencia = frecuencia + Number(info_sucu[y].frecuencia);
          ids = Number(info_sucu[y].evento_id);
        }
      }
      let porcentajes = (frecuencia * 100) / this.frecuencia_total;
      let porcen;
      
      if(porcentajes % 1 == 0){
        porcen = porcentajes;
      }else{
        porcen = porcentajes.toFixed(2);
      }

      
      //id: ids
     // if(frecuencia>0){
        this.descrip_sucu.push(this.grafica[z].evento);
        this.cuanti_sucu.push(frecuencia);
        this.porcen_sucu.push(porcen);
     // }
      

      //this.id_evento.push(array);
    }
  }


  calcular(info_sucu){//calculo para generar la grafica dado una sucursal por evento en especifico
   // let aux_mas = 0;
   // let aux_menos = 0;
     let frecuenci = 0; // totalidad de frecuencia
     this.cuanti_sucu = [];
     this.porcen_sucu = [];
     this.frecuencia_total = 0;

    for(let k = 0; k < info_sucu.length; k++){
      let sucursal = info_sucu[k];
      frecuenci = frecuenci + Number(sucursal['frecuencia']);
    }

    this.frecuencia_total = frecuenci;

    for(let i = 0; i < this.label_fecha.length; i++){
      let indica = false;
      this.cuanti_sucu[i] = 0;
      for(let j=0 ; j < info_sucu.length ; j++){
        let FECHA = moment(info_sucu[j].fecha).format('YYYY-MM-DD');
        if(moment(this.label_fecha[i]).diff(FECHA,'days') == 0){
          indica = true;
          if(this.cuanti_sucu[i] == 0){
            this.cuanti_sucu[i] = Number(info_sucu[j].frecuencia);
          }else{
            this.cuanti_sucu[i] = this.cuanti_sucu[i] + Number(info_sucu[j].frecuencia);
          }
        }
      }

      if(indica == false){
        this.porcen_sucu[i] = 0;
        this.cuanti_sucu[i] = 0;
      }else{
        let porcentajes = (this.cuanti_sucu[i] * 100) / frecuenci;
        let porcen;
        
        if(porcentajes % 1 == 0){
          porcen = porcentajes;
        }else{
          porcen = porcentajes.toFixed(2);
        }
        this.porcen_sucu[i] = porcen;
      }
    }
  }

  calcular_cobra_transaccion(info_sucu){
    //let aux_mas = 0;
    //let aux_menos = 0;
    //let cantidad_user = [];
    let frecuenci = 0; // totalidad de frecuencia
    this.cuanti_sucu = [];
    this.porcen_sucu = [];
    this.frecuencia_total = 0;

    for(let k = 0; k < info_sucu.length; k++){ 
      frecuenci = frecuenci + 1;
    }

    this.frecuencia_total = frecuenci;

     for(let i = 0; i < this.label_fecha.length; i++){
      let indica = false;
      this.cuanti_sucu[i] = 0;
      for(let j=0 ; j < info_sucu.length ; j++){
        let FECHRECI = moment(info_sucu[j].FECHRECI).format('YYYY-MM-DD');
        if(moment(this.label_fecha[i]).diff(FECHRECI,'days') == 0){
          indica = true;
          if(this.cuanti_sucu[i] == 0){
            this.cuanti_sucu[i] = 1;
          }else{
            this.cuanti_sucu[i] = this.cuanti_sucu[i] + 1;
          }
        }
      }

    if(indica == false){
      this.porcen_sucu[i] = 0;
      this.cuanti_sucu[i] = 0;
    }else{
      let porcentajes = (this.cuanti_sucu[i] * 100) / frecuenci;
      let porcen;
        
      if(porcentajes % 1 == 0){
        porcen = porcentajes;
      }else{
        porcen = porcentajes.toFixed(2);
      }
      this.porcen_sucu[i] = porcen;
      }
    }
  }

  calcular_new_sucu_type_user(info_sucu){
    let frecuenci = 0; // totalidad de frecuencia
    this.cuanti_sucu = [];
    this.porcen_sucu = [];
    this.frecuencia_total = 0;
    this.cuanti_sucu_usua_360 = [];
    this.porcen_sucu_usua_360 = [];

    this.cuanti_sucu_usua_vendedor = [];
    this.porcen_sucu_usua_vendedor = [];

    this.frecuencia_total = 0;

    for(let k = 0; k < info_sucu.length; k++){ 
      let fecha_creado = info_sucu[k].fecha_creado;
      if(fecha_creado != undefined){
        frecuenci = frecuenci + 1;
      }
    }
    this.frecuencia_total = frecuenci;


    for(let i = 0; i < this.label_fecha.length; i++){
      let indica = false;
      this.cuanti_sucu[i] = 0;
      this.cuanti_sucu_usua_360[i] = 0;
      this.cuanti_sucu_usua_vendedor[i] = 0;

      for(let j=0 ; j < info_sucu.length ; j++){
        let fecha_creado = info_sucu[j].fecha_creado;


        if(fecha_creado != undefined){
          let fecha_creado = moment(info_sucu[j].fecha_creado).format('YYYY-MM-DD');
          if(moment(this.label_fecha[i]).diff(fecha_creado,'days') == 0){
            indica = true;

            if(info_sucu[j].tipo_usuario == 1){
              this.cuanti_sucu_usua_360[i] = 1 + this.cuanti_sucu_usua_360[i];
            }
            if(info_sucu[j].tipo_usuario == 2){
              this.cuanti_sucu_usua_vendedor[i] = 1 + this.cuanti_sucu_usua_vendedor[i];
            }
            if(this.cuanti_sucu[i] == 0){
              this.cuanti_sucu[i] = 1;
            }else{
              this.cuanti_sucu[i] = this.cuanti_sucu[i] + 1;
            }
          }
        }

      }

    if(indica == false){
      this.porcen_sucu[i] = 0;
      this.cuanti_sucu[i] = 0;
      this.porcen_sucu_usua_360[i] = 0;
      this.porcen_sucu_usua_vendedor[i] = 0;
      this.cuanti_sucu_usua_360[i] = 0;
      this.cuanti_sucu_usua_vendedor[i] = 0;
    }else{
      let porcentajes = (this.cuanti_sucu[i] * 100) / frecuenci;
      let porcen;
        
      if(porcentajes % 1 == 0){
        porcen = porcentajes;
      }else{
        porcen = porcentajes.toFixed(2);
      }
      this.porcen_sucu[i] = porcen;


      if(this.cuanti_sucu_usua_360[i]>0){
        let porcentajes = (this.cuanti_sucu_usua_360[i] * 100) / frecuenci;
        let porcen;
          
        if(porcentajes % 1 == 0){
          porcen = porcentajes;
        }else{
          porcen = porcentajes.toFixed(2);
        }
        this.porcen_sucu_usua_360[i] = porcen;

      }

      if(this.cuanti_sucu_usua_vendedor[i]>0){
        let porcentajes = (this.cuanti_sucu_usua_vendedor[i] * 100) / frecuenci;
        let porcen;
          
        if(porcentajes % 1 == 0){
          porcen = porcentajes;
        }else{
          porcen = porcentajes.toFixed(2);
        }
        this.porcen_sucu_usua_vendedor[i]  = porcen;
      }
    }
   }

  }



  calcular_general_transaccion(info_sucu){
    //let aux_mas = 0;
    //let aux_menos = 0;
    //let cantidad_user = [];
    let frecuenci = 0; // totalidad de frecuencia
    this.cuanti_sucu = [];
    this.porcen_sucu = [];
    this.frecuencia_total = 0;

    for(let k = 0; k < info_sucu.length; k++){ 
      let fecha = info_sucu[k].fecha;
      let FECHRECI = info_sucu[k].FECHRECI;
      let fecha_creado = info_sucu[k].fecha_creado;
      if(FECHRECI != undefined){
        frecuenci = frecuenci + 1;
      }
      if(fecha != undefined){
        if(Number(info_sucu[k].estado) == 1){
          frecuenci = frecuenci + 1;
        }
      }
      if(fecha_creado != undefined){
        frecuenci = frecuenci + 1;
      }
    }

    this.frecuencia_total = frecuenci;
     for(let i = 0; i < this.label_fecha.length; i++){
      let indica = false;
      this.cuanti_sucu[i] = 0;
      for(let j=0 ; j < info_sucu.length ; j++){
        let fecha = info_sucu[j].fecha;
        let FECHRECI = info_sucu[j].FECHRECI;
        let fecha_creado = info_sucu[j].fecha_creado;
        
        if(FECHRECI != undefined){
          let FECHRECI = moment(info_sucu[j].FECHRECI).format('YYYY-MM-DD');
          let fecha_label = moment(this.label_fecha[i]).format('YYYY-MM-DD');
          if(moment(fecha_label).diff(FECHRECI,'days') == 0){

            indica = true;
            if(this.cuanti_sucu[i] == 0){
              this.cuanti_sucu[i] = 1;
            }else{
              this.cuanti_sucu[i] = this.cuanti_sucu[i] + 1;
            }
          }
        }
        
        else if(fecha != undefined){
          let fecha = moment(info_sucu[j].fecha).format('YYYY-MM-DD');
          let fecha_label = moment(this.label_fecha[i]).format('YYYY-MM-DD');
          if(moment(fecha_label).diff(fecha,'days') == 0){
            indica = true;
            if(this.cuanti_sucu[i] == 0){
              this.cuanti_sucu[i] = 1;
            }else{
              this.cuanti_sucu[i] = this.cuanti_sucu[i] + 1;
            }
          }
        }

        else if(fecha_creado != undefined){
          let fecha_creado = moment(info_sucu[j].fecha_creado).format('YYYY-MM-DD');
          let fecha_label = moment(this.label_fecha[i]).format('YYYY-MM-DD');
          if(moment(fecha_label).diff(fecha_creado,'days') == 0){
            indica = true;
            if(this.cuanti_sucu[i] == 0){
              this.cuanti_sucu[i] = 1;
            }else{
              this.cuanti_sucu[i] = this.cuanti_sucu[i] + 1;
            }
          }
        }

      }

    if(indica == false){
      this.porcen_sucu[i] = 0;
      this.cuanti_sucu[i] = 0;
    }else{
      let porcentajes = (this.cuanti_sucu[i] * 100) / frecuenci;
      let porcen;
        
      if(porcentajes % 1 == 0){
        porcen = porcentajes;
      }else{
        porcen = porcentajes.toFixed(2);
      }
      this.porcen_sucu[i] = porcen;
      }
    }
  }


  calcular_transaccion_aplicacion(info_sucu){
    let frecuenci = 0; // totalidad de frecuencia
    this.cuanti_sucu_vent = [];
    this.porcen_sucu_vent = [];

    this.cuanti_sucu_usua = [];
    this.porcen_sucu_usua = [];

    this.frecuencia_total = 0;

    for(let k = 0; k < info_sucu.length; k++){ 
      let fecha = info_sucu[k].fecha;
      let FECHRECI = info_sucu[k].FECHRECI;
      let fecha_creado = info_sucu[k].fecha_creado;
      if(FECHRECI != undefined){
        frecuenci = frecuenci + 1;
      }
      if(fecha != undefined){
        if(Number(info_sucu[k].estado) == 1){
          frecuenci = frecuenci + 1;
        }
      }
      if(fecha_creado != undefined){
        frecuenci = frecuenci + 1;
      }
    }

    this.frecuencia_total = frecuenci;

     for(let i = 0; i < this.label_fecha.length; i++){

      this.cuanti_sucu_vent[i] = 0;
      this.cuanti_sucu_usua[i] = 0;

      for(let j=0 ; j < info_sucu.length ; j++){
        let fecha = info_sucu[j].fecha;
        let FECHRECI = info_sucu[j].FECHRECI;
        let fecha_creado = info_sucu[j].fecha_creado;
        let tipo_tran = info_sucu[j].TYPE_TRANSACC;
        
        if(FECHRECI != undefined){
          let FECHRECI = moment(info_sucu[j].FECHRECI).format('YYYY-MM-DD');
          if(moment(this.label_fecha[i]).diff(FECHRECI,'days') == 0){
            if(this.cuanti_sucu_vent[i] == 0){
              this.cuanti_sucu_vent[i] = 1;
            }else{
              this.cuanti_sucu_vent[i] = this.cuanti_sucu_vent[i] + 1;
            }
          }
        }
        
        if(fecha != undefined){
          let fecha = moment(info_sucu[j].fecha).format('YYYY-MM-DD');
          if(moment(this.label_fecha[i]).diff(fecha,'days') == 0){
            if(tipo_tran == 'facturas'){
              if(this.cuanti_sucu_vent[i] == 0){
                 this.cuanti_sucu_vent[i] = 1;
              }else{
                this.cuanti_sucu_vent[i] = this.cuanti_sucu_vent[i] + 1;
              }
            }else{
              if(this.cuanti_sucu_vent[i] == 0){
                this.cuanti_sucu_vent[i] = 1;
              }else{
                this.cuanti_sucu_vent[i] = this.cuanti_sucu_vent[i] + 1;
              }
            } 
          }
        }

        if(fecha_creado != undefined){
          let fecha = moment(info_sucu[j].fecha_creado).format('YYYY-MM-DD');
          if(moment(this.label_fecha[i]).diff(fecha_creado,'days') == 0){
            if(this.cuanti_sucu_usua[i] == 0){
              this.cuanti_sucu_usua[i] = 1;
            }else{
              this.cuanti_sucu_usua[i] = this.cuanti_sucu_usua[i] + 1;
            }
          }
        }
      }

      if(this.cuanti_sucu_usua[i] == 0){
        this.porcen_sucu_usua[i] = 0;
      }

      if(this.cuanti_sucu_usua[i] > 0){
        this.porcen_sucu_usua[i] = this.calculo_de_porcentaje(frecuenci,this.cuanti_sucu_usua[i]);
      }

      if(this.cuanti_sucu_vent[i] == 0){
        this.porcen_sucu_vent[i] = 0;
      }

      if(this.cuanti_sucu_vent[i] > 0){
        this.porcen_sucu_vent[i] = this.calculo_de_porcentaje(frecuenci,this.cuanti_sucu_vent[i]);
      }
    }
    
  }

  calculo_de_porcentaje(totalidad,valor){
    let porcentajes = (valor * 100) / totalidad;
    let porcen;
        
    if(porcentajes % 1 == 0){
      porcen = porcentajes;
    }else{
      porcen = porcentajes.toFixed(2);
    }
    return porcen;
  }



















  calcular_pedi_transaccion(info_sucu){
    let frecuenci = 0; // totalidad de frecuencia
    this.cuanti_sucu = [];
    this.porcen_sucu = [];
    this.frecuencia_total = 0;

    for(let k = 0; k < info_sucu.length; k++){
      let sucursal = info_sucu[k];
      if(Number(sucursal['estado']) == 1){
        frecuenci = frecuenci + 1;
      }
    }

    this.frecuencia_total = frecuenci;

    for(let i = 0; i < this.label_fecha.length; i++){
      let indica = false;
      this.cuanti_sucu[i] = 0;
      for(let j=0 ; j < info_sucu.length ; j++){
        let fecha = moment(info_sucu[j].fecha).format('YYYY-MM-DD');
        if(moment(this.label_fecha[i]).diff(fecha,'days') == 0){
          indica = true;
          if(this.cuanti_sucu[i] == 0){
            this.cuanti_sucu[i] = 1;
          }else{
            this.cuanti_sucu[i] = this.cuanti_sucu[i] + 1;
          }
        }
      }

    if(indica == false){
      this.porcen_sucu[i] = 0;
      this.cuanti_sucu[i] = 0;
    }else{
      let porcentajes = (this.cuanti_sucu[i] * 100) / frecuenci;
      let porcen;
        
      if(porcentajes % 1 == 0){
        porcen = porcentajes;
      }else{
        porcen = porcentajes.toFixed(2);
      }
      this.porcen_sucu[i] = porcen;
      }
    }
  }

  evento_set(visible=true, titles:any={}, evento):Promise<any> {
    try{
      return new Promise((resolve, reject) => {
        const initAjax: Init = {
          method: 'post',
          url: this.listUrl.url('event_register'),
          auth: this.auth,
          body: {
            'usuario_id': this.auth.user.idusuario,
            'sucursal_id': this.auth.user.idsucursal,
            'nombre_evento': evento,
            'fecha': moment(Cache.getLocal(session.init_date, true).strdate).format('YYYY-MM-DD'),
            'base_dato': 'cliente-empresa'
          }
        };
        const configAjax: Config = {
          visible
        };
        const ajax = new Ajax(initAjax, configAjax);
        ajax.call().then(resp => {
          
          resolve(resp.success);
        }).catch(error => {
          reject(error);
        });
      });
    }catch(error){
        
    }
  }

  auditar_error_vel(data: any, tipo: String){

    let diagnost_error = [];


    if(Cache.getLocal(session_error.respaldo_vel)){
      diagnost_error = Cache.getLocal(session_error.respaldo_vel, true);
    }

    if(tipo == 'pedidos_vel'){
      for (let pedido of data) {
        const p = JSON.stringify(pedido.PRODUCTOS);
        const filtro_producto = JSON.parse(p);
        let detalles_productos = '';
        for (let pd of filtro_producto) {
          detalles_productos  = detalles_productos + 
          'CODIPROD: ' + pd.CODIPROD + '\n' +
          'TIPOPREC: ' + pd.TIPOPREC + '\n' +
          'CAJAS: ' + pd.CAJAS + '\n' +
          'UNIDADES_CON_CAJA: ' + pd.UNIDADES.CONCAJA + '\n' +
          'UNIDADES_SINCAJA: ' + pd.UNIDADES.SINCAJA + '\n' +
          'DESCUENTO: ' + pd.DESCUENTO + '\n' +
          'PRECIO: ' + pd.PRECIO + '\n' +
          'PRECIOME: ' + pd.PRECIOME + '\n' +
          'DEPOSITO: ' + pd.DEPOSITO + '\n' +
          'DESCUENT2: ' + pd.DESCUENT2 + '\n' +
          'IMPUESTOS: ' + pd.IMPUESTOS + '\n' +
          'CANTCAJAS: ' + pd.CANTCAJAS + '\n' +
          'CANTUNID: ' + pd.CANTUNID + '\n' +
          'PRECIOSELECCIONADO: ' + pd.PRECIOSELECCIONADO + '\n' +' | '
          ;
        }

        let encabezado = 'Pedido sin enviar' + '\n' +
        'Numero pedido: ' + pedido.NUMEROPEDIDO + '\n' +
        'Cliente: ' + pedido.CODICLIE + '\n' +
        'Fecha: ' + pedido.FECHA + '\n' +
        'TOTAPEDI: ' + pedido.TOTAPEDI+ '\n' +
        'TOTAPEDIME: ' + pedido.TOTAPEDIME+ '\n' +
        'CODIRUTA: ' + pedido.CODIRUTA+ '\n' +
        'MENSAJE: ' + pedido.MENSAJE+ '\n' +
        'CODIGLOB: ' + pedido.CODIGLOB+ '\n' +
        'LATITUD: ' + pedido.LATITUD+ '\n' +
        'LONGITUD: ' + pedido.LONGITUD+ '\n' +
        'USUARIO_ID: ' + pedido.USUARIO_ID+ '\n' +
        'Detalles: ' + '\n' + detalles_productos;

        if(!this.check_string(encabezado)){
          diagnost_error.push(encabezado);
        }
      }
      Cache.setLocal(session_error.respaldo_vel, diagnost_error);
    }

    if(tipo == 'facturas_vel'){
      for (let facturas of data) {
        const p = JSON.stringify(facturas.PRODUCTOS);
        const filtro_producto = JSON.parse(p);
        let detalles_productos = '';
        for (let pd of filtro_producto) {
          detalles_productos  = detalles_productos + 
          'CODIPROD: ' + pd.CODIPROD + '\n' +
          'TIPOPREC: ' + pd.TIPOPREC + '\n' +
          'UNIDADES_CON_CAJA: ' + pd.UNIDADES.CONCAJA + '\n' +
          'UNIDADES_SINCAJA: ' + pd.UNIDADES.SINCAJA + '\n' +
          'DESCUENTO: ' + pd.DESCUENTO + '\n' +
          'DEPOSITO: ' + pd.DEPOSITO + '\n' +
          'DESCUENT2: ' + pd.DESCUENT2 + '\n' +
          'IMPUESTOS: ' + pd.IMPUESTOS + '\n' +
          'CANTCAJAS: ' + pd.CANTCAJAS + '\n' +
          'CANTUNID: ' + pd.CANTUNID + '\n' +
          'PRECIOSELECCIONADO: ' + pd.PRECIOSELECCIONADO + '\n' +' | '
          ;
        }

        let encabezado = 'Factura sin enviar' + '\n' +
        'Numero factura: ' + facturas.NUMEROFACTURA + '\n' +
        'Numero control: ' + facturas.NUMEROCONTROL + '\n' +
        'Cliente: ' + facturas.CODICLIE + '\n' +
        'Fecha: ' + facturas.FECHA + '\n' +
        'TOTAPEDI: ' + facturas.TOTAPEDI+ '\n' +
        'TOTAPEDIME: ' + facturas.TOTAPEDIME+ '\n' +
        'CODIRUTA: ' + facturas.CODIRUTA+ '\n' +
        'MENSAJE: ' + facturas.MENSAJE+ '\n' +
        'CODIGLOB: ' + facturas.CODIGLOB+ '\n' +
        'LATITUD: ' + facturas.LATITUD+ '\n' +
        'LONGITUD: ' + facturas.LONGITUD+ '\n' +
        'USUARIO_ID: ' + facturas.USUARIO_ID+ '\n' +
        'Detalles: ' + '\n' + detalles_productos;


        if(!this.check_string(encabezado)){
          diagnost_error.push(encabezado);
        }
      }
      Cache.setLocal(session_error.respaldo_vel, diagnost_error);
    }

    if(tipo == 'cobranza_vel'){
      for (let cobranza of data) {
        let detalles_cobranza = '';
        if(cobranza.detalles != null && cobranza.detalles != undefined){
          const p = JSON.stringify(cobranza.detalles);
          const filtro_cobranza = JSON.parse(p);
          for (let pd of filtro_cobranza) {
            detalles_cobranza  = detalles_cobranza + 
            'BANCO: ' + pd.BANCO + '\n' +
            'FECHCHEQ: ' + pd.FECHCHEQ + '\n' +
            'MONTCHEQ: ' + pd.MONTCHEQ + '\n' +
            'NUMECHEQ: ' + pd.NUMECHEQ + '\n' +
            'NUMEDEPO: ' + pd.NUMEDEPO + '\n' +
            'NUMEDOCU: ' + pd.NUMEDOCU + '\n' +
            'TIPODOCU: ' + pd.TIPODOCU + '\n' +
            'TIPOPAGO: ' + pd.TIPOPAGO + '\n' +
            'codimone: ' + pd.codimone + '\n' +
            'montoME: ' + pd.montoME + '\n' +
            'tasa: ' + pd.tasa + '\n' +' | '
            ;
          }
        }
        
        let abono_prev = ' - \n';
        let tasa = ' - \n';
        let codimone = ' - \n';

        if(cobranza.abono_prev  != null && cobranza.abono_prev != undefined){
          abono_prev = cobranza.abono_prev;
        }

        if(cobranza.tasa != null && cobranza.tasa != undefined){
          tasa = cobranza.tasa;
        }

        if(cobranza.codimone != null && cobranza.codimone != undefined){
          codimone = cobranza.codimone;
        }

        if(detalles_cobranza == ''){
          detalles_cobranza = '- \n';
        }

        let encabezado = 'Cobranzas sin enviar' + '\n' +
        'CODICLIE: ' + cobranza.CODICLIE + '\n' +
        'NUMEDOCU: ' + cobranza.NUMEDOCU + '\n' +
        'TIPODOCU: ' + cobranza.TIPODOCU + '\n' +
        'FECHRECI: ' + cobranza.FECHRECI + '\n' +
        'MONTO: ' + cobranza.MONTO + '\n' +
        'ABONOS: ' + cobranza.ABONOS + '\n' +
        'abono_prev: ' + abono_prev+ '\n' +
        'codimone: ' + codimone+ '\n' +
        'tasa: ' + tasa+ '\n' +
        'USUARIO_ID: ' + cobranza.USUARIO_ID+ '\n' +
        'CODIRUTA: ' + cobranza.CODIRUTA+ '\n' +
        'Detalles: ' + '  ' + detalles_cobranza ; 
        

        if(!this.check_string(encabezado)){
          diagnost_error.push(encabezado);
        }
      }
      Cache.setLocal(session_error.respaldo_vel, diagnost_error);
    }


    if(tipo == 'visitas_vel'){
      for (let visita of data) {
        let encabezado = 'Visitas sin enviar' + '\n' +
        'CODICLIE: ' + visita.CODICLIE + '\n' +
        'VISITA: ' + visita.FECHVISI + '\n' +
        'RUTA: ' + visita.CODIRUTA + '\n' +
        'INICIADA: ' + visita.HORAINIC + '\n' +
        'FINALIZADA: ' + visita.HORAFINA + '\n' +
        'NOCOMPRA: ' + visita.NOCOMPRA + '\n' +
        'NOVISITA: ' + visita.NOVISITA + '\n';
        if(!this.check_string(encabezado)){
          diagnost_error.push(encabezado);
        }
      }      
      Cache.setLocal(session_error.respaldo_vel, diagnost_error);
    }
  }

  check_string(log: string){
    if(Cache.getLocal(session_error.respaldo_vel)){
      let diagnost_error = Cache.getLocal(session_error.respaldo_vel, true);

       for (let diag of diagnost_error) {
         if(diag == log){
           return true;
         }
       }
    }
    return false;
  }
}
