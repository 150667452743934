import { Component, OnInit } from '@angular/core';
import { NotificacionService } from 'src/app/core/notifications/notificacion.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Config } from 'src/app/core/interfaces/config';
import { UrListService } from 'src/app/core/url/url.service';
import { session } from 'src/app/core/constantes/sessiones';
import { Ajax } from 'src/app/core/utils/ajax';
import { Init } from 'src/app/core/interfaces/init';
import * as moment from 'moment';
import { TitlesService } from 'src/app/core/services/titles.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-modal-grup',
  templateUrl: './modal-grup.component.html',
  styleUrls: ['./modal-grup.component.scss'],
})
export class ModalGrupComponent implements OnInit {
  titleModal: String;
  campoDesc = 'Descripcion del grupo';
  formNoti: FormGroup;
  descripcion: any;
  asunto: any;
  maxDesc = 150; 
  list_save_users = [];
  select_user = 0;
  placeholderDesc = 'Descripcion del grupo...';
  placeholderAsunt = 'Asunto';
  txt_desc: string | null;
  error_desc = false;
  error_id = false;
  txt_id: string | null;
  title : string = 'N360 | Enviar Notificacion';
  error_asunt = false;
  txt_asunt: string | null;
  a_usuario = false;
  a_grupo = false;
  grupo = [{'valor':null,'view': false}];
  grupo_select = [];
  usuario_a_enviar: any;
  idgrupo = [];
  titulo_2= '';
  is_boton_change = false;

  constructor(
  	public NotificacionService:NotificacionService,
  	public auth: AuthService,
  	private listUrl: UrListService,
  	private titles: TitlesService
  ) {

  	this.formNoti = new FormGroup({
      modescripcion: new FormControl(this.descripcion, [
        Validators.required
      ]),
      moasunto: new FormControl(this.asunto, [
        Validators.required,
        Validators.maxLength(this.maxDesc)
      ])
    });

  	 }

  ngOnInit() {
    this.is_boton_change = this.auth.check_action('change_alert_front');
  	if(this.NotificacionService.modo_grupo == 'nuevo'){
  		this.titleModal = "Nuevo Grupo";
  	}
  	if(this.NotificacionService.modo_grupo == 'editar'){
  		this.titleModal = "Editar Grupo";
  		this.descripcion = 'grupo editando';
  		this.cargar_miembros_grupo();
  	}

    if(this.NotificacionService.modo_grupo == 'enviar'){
      this.titulo_2 = 'Destinatario';
      this.titleModal = "Enviar notificacion";
      this.placeholderDesc = 'Escriba su mensaje...';
      this.placeholderAsunt = 'Asunto';
      this.campoDesc = 'Mensaje';
      this.maxDesc = 255; 
    }

    if(this.NotificacionService.modo_grupo == 'alerta'){
      this.titulo_2 = 'Mensajes Predeterminado';
      this.titleModal = "Notificacion a los Usuarios";
      this.placeholderDesc = 'Escriba su mensaje...';
      this.placeholderAsunt = 'Asunto';
      this.campoDesc = 'Personalizar mensaje';
      this.maxDesc = 255; 
    }
  	this.titles.setTitle(this.title);
  }

  closeModal() {
    this.NotificacionService.close_Modal_grup.hide();
  }

  new_grupo(){
    this.list_save_users = [];
  }

  edit_grupo(){
    this.list_save_users = [];
  }

  mostrar_nombre(id){
    for(let i=0; i< this.NotificacionService.usuarios_sucursal.length; i++){
      if(this.NotificacionService.usuarios_sucursal[i]['idusuario'] == id){
        return this.NotificacionService.usuarios_sucursal[i]['nombre_user'];
      }
    }
    if(this.auth.user.idusuario == id){
      return 'Tú';  
    }
    return 'no definido';
  }

  seleccionar_usuario(){
    for(let j=0 ;j<this.list_save_users.length; j++){
       if(this.list_save_users[j]== this.select_user){
            return ' ';
       }
    }
    this.list_save_users.push(this.select_user);
  }

  formatdesc() {
    this.error_id = false;
    this.txt_id = '';
    this.error_desc = false;
    this.txt_desc = '';
  }

  formatasunt() {
    this.error_id = false;
    this.txt_id = '';
    this.error_asunt = false;
    this.txt_asunt = '';
  }

  quitarUsuario(id){
    if(this.auth.user.idusuario != id){
      let value = [];
      for(let j=0 ;j<this.list_save_users.length; j++){
         if(this.list_save_users[j]==id){
              this.select_user = undefined;
         }
         if(this.list_save_users[j]!=id){
           value.push(this.list_save_users[j]);
         }
       }
       this.list_save_users = value;
    }
  }

  cargar_miembros_grupo(){
    this.select_user = undefined;
    this.list_save_users = [];

    if(this.NotificacionService.grupo_id_edit != undefined){
      for(let i=0; i< this.NotificacionService.grupos.length ; i++){
      	
        if(this.NotificacionService.grupos[i].id_grupo == this.NotificacionService.grupo_id_edit){
        	
          this.list_save_users = this.NotificacionService.grupos[i].id_users;
        }
      }
    }
  }

  saveObject(){
  	if(this.NotificacionService.modo_grupo == 'nuevo'){
  		this.crearGrupo();
  	}
  	if(this.NotificacionService.modo_grupo == 'editar'){
  		this.actualizarGrupo();
  	}

    if(this.NotificacionService.modo_grupo == 'enviar'){
      if(!this.a_usuario){
        this.enviar_a_grupo();
      }else{
        this.enviar_usuario();
      }
    }

    if(this.NotificacionService.modo_grupo == 'alerta'){
      this.enviar_alerta();
    }
  	//console.log(this.NotificacionService.modo_grupo);
  }

  enviar_alerta(){
    const initAjax: Init = {
      method: 'post',
      url: this.listUrl.url('alert_change'),
      auth: this.auth,
      body: {
       'usuario_id' :  this.auth.user.idusuario,
       'notificacion': this.descripcion,
       'asunto': this.asunto,
       'fecha': moment().format('YYYY-MM-DD HH:mm:ss')
      }
    };
    const configAjax: Config = {
      visible: true
    };
    const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(respo => {
        if(respo.success){
          this.NotificacionService.close_Modal_grup.hide();
          this.NotificacionService.even_nuevos_msj.emit({});
        }
      }).catch(error => {
        console.log(error);
    });
  }

  verifica_usuario_enviar(){
    if(this.usuario_a_enviar!= undefined){
      return true;
    }
    return false;
  }

  enviar_usuario(){
    const initAjax: Init = {
      method: 'post',
      url: this.listUrl.url('crear_notificacion_user'),
      auth: this.auth,
      body: {
        'id': this.usuario_a_enviar,
        'fecha' : moment().format('YYYY-MM-DD HH:mm:ss'),
        'usuario_id' :  this.auth.user.idusuario,
        'notificacion': this.descripcion,
        'asunto': this.asunto
        }
      };
     const configAjax: Config = {
       visible: true
     };
     const ajax = new Ajax(initAjax, configAjax);
     ajax.call().then(respo => {
       if(respo.success){
         this.NotificacionService.close_Modal_grup.hide();
         this.NotificacionService.even_nuevos_msj.emit({});
         // console.log(respo);
        }
     }).catch(error => {
         console.log(error);
    });
  }

  enviar_a_grupo(){
    this.idgrupo = [];
    if(this.NotificacionService.grupos.length == 1){
      this.idgrupo.push(this.NotificacionService.grupos[this.grupo_select[0]].id_grupo);
    }else{
      for(let i=0; i < this.grupo_select.length; i++){
          this.idgrupo.push(this.NotificacionService.grupos[this.grupo_select[i]].id_grupo);     
      }
    }

      
    const initAjax: Init = {
      method: 'post',
      url: this.listUrl.url('crear_notificacion'),
      auth: this.auth,
      body: {
        'idgrupo': this.idgrupo,
        'fecha' : moment().format('YYYY-MM-DD HH:mm:ss'),
        'usuario_id' :  this.auth.user.idusuario,
        'notificacion': this.descripcion,
        'asunto': this.asunto   
        }
      };
     const configAjax: Config = {
       visible: true
     };
     const ajax = new Ajax(initAjax, configAjax);
     ajax.call().then(respo => {
       if(respo.success){
         // console.log(respo);
         this.NotificacionService.close_Modal_grup.hide();
         this.NotificacionService.even_nuevos_msj.emit({});
        }
     }).catch(error => {
         console.log(error);
    });
  }



  crearGrupo(){
    //this.usuarios = ['10'];
    if(this.list_save_users == undefined || this.list_save_users.length ==0 || this.list_save_users==null){
        this.list_save_users=[];
      }
     
     const initAjax: Init = {
      method: 'post',
      url: this.listUrl.url('crear_grupo'),
      auth: this.auth,
      body: {
         'descripcion_del_grupo': this.descripcion,
         'usuarios': this.list_save_users,
         'fecha' : moment().format('YYYY-MM-DD HH:mm:ss'),
         'creador' :  this.auth.user.idusuario    
        }
      };
      const configAjax: Config = {
        visible: true
      };
      const ajax = new Ajax(initAjax, configAjax);
      ajax.call().then(respo => {
            if(respo.success){
                //this.cargar_datos();
                this.NotificacionService.getTodos_grupos(false, this.titles).then( resp =>{
				    }).catch( err=>{
				      let erro = err;
				    });
          this.NotificacionService.even_nuevos_msj.emit({});  
    			this.NotificacionService.close_Modal_grup.hide();
            }
        }).catch(error => {
            console.log(error);
        });
    }

   
   actualizarGrupo(){

      if(this.list_save_users == undefined || this.list_save_users.length ==0 || this.list_save_users==null){
        this.list_save_users=[];
      }

        const initAjax: Init = {
            method: 'post',
            url: this.listUrl.url('actualizar_usuario_grupo'),
            auth: this.auth,
            body: {
              'id': this.NotificacionService.grupo_id_edit,
              'usuarios': this.list_save_users,
              'fecha' : moment().format('YYYY-MM-DD HH:mm:ss'),
              'creador' :  this.auth.user.idusuario  
            }
        };
        const configAjax: Config = {
            visible: true
        };
        const ajax = new Ajax(initAjax, configAjax);
        ajax.call().then(respo => {
            if(respo.success){
                this.NotificacionService.getTodos_grupos(false, this.titles).then( resp =>{
				    }).catch( err=>{
				      let erro = err;
				    });
          this.NotificacionService.even_nuevos_msj.emit({});  
				  this.NotificacionService.close_Modal_grup.hide();
            }
        }).catch(error => {
            console.log(error);
        });
    }

    for_user(){
      this.a_usuario = !this.a_usuario;
      this.a_grupo = false;
    }

    for_grupo(){
      this.a_usuario = false;
      this.a_grupo = !this.a_grupo;
    }

    add_grupo(){
      if(this.grupo.length < this.NotificacionService.grupos.length){
          this.grupo_select.push(0);
          this.grupo.push({'valor':null,'view': false});
      }
    }

  quit_grupo(index){
    if(this.grupo.length > 1){    
          this.grupo.splice(index,1);
          this.grupo_select.splice(index,1);
    }
  }

  grupo_repetido(indice){
    for(let i=0 ; i < this.grupo_select.length ; i++){
      if(indice != i){
        if(this.grupo_select[indice] == this.grupo_select[i]){
          return true;
        }
      }
    }
    return false;
  }

  grupo_vacio(index){
    if(this.NotificacionService.grupos[this.grupo_select[index]] != undefined){

      if(this.NotificacionService.grupos[this.grupo_select[index]].id_users.length == 0){
        return true;
      }
    }

    return false;
  }

  verificarMiembro(){

   if(this.NotificacionService.grupos.length == 1){

     if(this.grupo_vacio(0) == false){

       if(this.grupo_select.length > 0){
         return true;
       }

     }
   }
    if(this.NotificacionService.grupos[this.grupo_select[0]] == undefined){

      return false;
    }

    for(let i=0 ; i < this.grupo_select.length ; i++){
      if(this.NotificacionService.grupos[this.grupo_select[i]].id_users.length==0 || this.NotificacionService.grupos[this.grupo_select[i]].id_users == undefined){
        return false;
      }
    }
    return true;
  }

  view_grupo(index){
   this.grupo[index].view = !this.grupo[index].view;  
  }

  mostrarDescripcion(indice_seleccionado){
    if (this.NotificacionService.grupos[indice_seleccionado] != null){
        return this.NotificacionService.grupos[indice_seleccionado].descripcion;
    }else{
      return 'Sin descripcion';
    }
  }

  mostrarMiembros(indice_seleccionado){
    if (this.NotificacionService.grupos[indice_seleccionado] != null && this.NotificacionService.grupos[indice_seleccionado].miembros != ''){ 
        return this.NotificacionService.grupos[indice_seleccionado].miembros;
    }else{
      return 'Sin miembros';
    }
  }

  cargar_texto(id = null){
    if(id == 1){
      this.asunto = 'Notificacion de cambios recientes en la app';
      this.descripcion = 'Estimado usuario, se ha detectado un cambio recientemente en la aplicacion. Por favor guarde los cambios e ingrese al sistema nuevamente.\n Gracias por su colaboracion';
    }
  }


}
