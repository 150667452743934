import { Subject, Observable } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';

import { session } from 'src/app/core/constantes/sessiones';
import { Agendas } from 'src/app/core/interfaces/vel/agenda';
import { Clientes } from 'src/app/core/interfaces/cliente';
import { Plans } from 'src/app/core/interfaces/vel/plan';
import { Planificaciones } from 'src/app/core/interfaces/vel/planificacion';
import { Cache } from 'src/app/core/utils/cache';
import { Cuentas, TipoCobros } from 'src/app/core/interfaces/vel/cuenta';
import { TipoClientes } from 'src/app/core/interfaces/vel/tipo-cliente';
import { BancoNacionales, BancoPrivados } from 'src/app/core/interfaces/vel/bancos';
import { Filtro } from 'src/app/core/interfaces/vel/filtro';
import { Motivo } from 'src/app/core/interfaces/vel/motivo';
import { Visitas } from 'src/app/core/interfaces/vel/visita';
import { Productos } from 'src/app/core/interfaces/vel/producto';
import { Pedidos } from 'src/app/core/interfaces/vel/pedidos';
import { Cobrado, Cobranza } from 'src/app/core/interfaces/vel/cobranza';
import { CatalogPedido } from 'src/app/core/interfaces/vel/catalog_pedidos';
import { CartPedidos } from 'src/app/core/interfaces/vel/cart_pedidos';
import { Facturas } from 'src/app/core/interfaces/vel/factura';
import { ActFactura } from 'src/app/core/interfaces/vel/act-factura';

import Swal from 'sweetalert2';
import { Monedas } from 'src/app/core/interfaces/vel/monedas';
import { Descuentos } from 'src/app/core/interfaces/vel/descuentos';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DataSendModalComponent } from '../modals/data-send-modal/data-send-modal.component';

@Injectable({
  providedIn: 'root'
})
export class DataVelService {
  private _clientes: Clientes | null;
  private _agenda: Agendas | null;
  private _planificacion: Planificaciones | null;
  private _plan: Plans | null;
  private _cuentas: Cuentas | null;
  private _tcliente: TipoClientes | null;
  private _bancoNacional: BancoNacionales | null;
  private _bancoPrivado: BancoPrivados | null;
  private _tipoCobro: TipoCobros | null;
  private _filtros: Filtro | null;
  private _motivos: Motivo | null;
  private _visitas: Visitas | null;
  private _productos: Productos | null;
  private _pedidos: Pedidos | null;
  private _facturas_autoventa: any;
  private _control_autoventa: any;
  private _cobranzas: any = {
    encabezados: [],
    detalles: [],
    ruta: "",
  } as Cobranza;
  private _monedas: Monedas | null;
  private _descuentos: Descuentos | null;
  public _catalog_pedidos: CatalogPedido;
  public _cart_pedidos: CartPedidos;
  public _facturas: Facturas;
  public _act_factura: ActFactura;
  public _redir: number | null;
  public _all_products: boolean;
  public _type_prices: boolean;
  public _saldore_pedidos: boolean;
  public _multiplos_pedidos: boolean;
  public _precios_iva: boolean;
  public _config_bloq_pedi: boolean;
  private _recibos: any = {
    recibos: [],
    ruta: "",
  } as Cobrado;

  even_cliente: EventEmitter<any> = new EventEmitter();
  even_planificaciones: EventEmitter<any> = new EventEmitter();
  even_plan_visitas: EventEmitter<any> = new EventEmitter();
  even_cuentas_cobrar: EventEmitter<any> = new EventEmitter();
  even_tipo_cliente: EventEmitter<any> = new EventEmitter();
  even_bancos_nac: EventEmitter<any> = new EventEmitter();
  even_bancos_priv: EventEmitter<any> = new EventEmitter();
  even_productos: EventEmitter<any> = new EventEmitter();
  even_tipo_cobro: EventEmitter<any> = new EventEmitter();
  even_filtros: EventEmitter<any> = new EventEmitter();
  even_motivo_visita: EventEmitter<any> = new EventEmitter();
  even_monedas: EventEmitter<any> = new EventEmitter(); //Actualmente no se usa
  even_descuentos: EventEmitter<any> = new EventEmitter();
  even_cambio_ruta: EventEmitter<any> = new EventEmitter();
  even_sync_end: EventEmitter<any> = new EventEmitter();
  even_format_mone: EventEmitter<any> = new EventEmitter();
  even_send_data_end: EventEmitter<any> = new EventEmitter();

  confirmarDelete = Swal.mixin({
    icon: 'info',
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Continuar',
    confirmButtonAriaLabel: 'Cancelar',
    cancelButtonText: 'Cancelar',
    cancelButtonAriaLabel: 'Cancelar',
  });

  constructor() { 
    if (Cache.getLocal(session.clientes)) {
      this.clientes = Cache.getLocal(session.clientes, true) as Clientes;
    } else {
      this.clientes = null;
    }

    if (Cache.getLocal(session.agenda)) {
      this.agenda = Cache.getLocal(session.agenda, true) as Agendas;
    } else {
      this.agenda = null;
    }

    if (Cache.getLocal(session.monedas)) {
      this.monedas = Cache.getLocal(session.monedas, true) as Monedas;
    } else {
      this.monedas = null;
    }

    if (Cache.getLocal(session.descuentos)) {
      this.descuentos = Cache.getLocal(session.descuentos, true) as Descuentos;
    } else {
      this.descuentos = [];
    }

    if (Cache.getLocal(session.planificacion)) {
      this.planificacion = Cache.getLocal(session.planificacion, true) as Planificaciones;
    } else {
      this.planificacion = null;
    }

    if (Cache.getLocal(session.plan)) {
      this.plan = Cache.getLocal(session.plan, true) as Plans;
    } else {
      this.plan = null;
    }

    if (Cache.getLocal(session.cuentas)) {
      this.cuentas = Cache.getLocal(session.cuentas, true) as Cuentas;
    } else {
      this.cuentas = null;
    }

    if (Cache.getLocal(session.tcliente)) {
      this.tcliente = Cache.getLocal(session.tcliente, true) as TipoClientes;
    } else {
      this.tcliente = null;
    }

    if (Cache.getLocal(session.bancoNacional)) {
      this.bancoNacional = Cache.getLocal(session.bancoNacional, true) as BancoNacionales;
    } else {
      this.bancoNacional = null;
    }

    if (Cache.getLocal(session.bancoPrivado)) {
      this.bancoPrivado = Cache.getLocal(session.bancoPrivado, true) as BancoPrivados;
    } else {
      this.bancoPrivado = null;
    }

    if (Cache.getLocal(session.tipoCobro)) {
      this.tipoCobro = Cache.getLocal(session.tipoCobro, true) as TipoCobros;
    } else {
      this.tipoCobro = null;
    }

    if (Cache.getLocal(session.filtros)) {
      this.filtros = Cache.getLocal(session.filtros, true) as Filtro;
    } else {
      this.filtros = null;
    }

    if (Cache.getLocal(session.motivos)) {
      this.motivos = Cache.getLocal(session.motivos, true) as Motivo;
    } else {
      this.motivos = null;
    }

    if (Cache.getLocal(session.visitas)) {
      this.visitas = Cache.getLocal(session.visitas, true) as Visitas;
    } else {
      this.visitas = null;
    }
    
    if (Cache.getLocal(session.productos)) {
      this.productos = Cache.getLocal(session.productos, true) as Productos;
    } else {
      this.productos = null;
    }

    if (Cache.getLocal(session.pedidos)) {
      this.pedidos = Cache.getLocal(session.pedidos, true) as Pedidos;
    } else {
      this.pedidos = null;
    }

    if (Cache.getLocal(session.facturas_autoventa)) {
      this.facturas_autoventa = Cache.getLocal(session.facturas_autoventa, true);
    } else {
      this.facturas_autoventa = null;
    }

    if (Cache.getLocal(session.redir)) {
      this.redir = Cache.getLocal(session.redir, true) as number;
    } else {
      this.redir = null;
    }

    if (Cache.getLocal(session.cobranzas)) {
      this.cobranzas = Cache.getLocal(session.cobranzas, true) as Cobranza;
    } else {
      this.cobranzas = null;
    }
  }
  
  poseeDatosPorEnviar(logout:boolean) {

    let hasData: boolean = false;

    if (Cache.getLocal(session.pedidos, true)) {
      if (Cache.getLocal(session.pedidos, true).length) {
        hasData = true;
      }
    }
    if (Cache.getLocal(session.recibos, true)) {
      if (this.recibos.recibos.length) {//4.0 No deberia confirmar si estan enviados?
        hasData = true;
      }
    }
    if (Cache.getLocal(session.visitas, true)) {
      if (Cache.getLocal(session.visitas, true).length) {
        hasData = true;
      }
    }
    if (logout) {
      //Comentario para luis mejias
      if (Cache.getLocal(session.list_pen)) {
        const lista_category = Cache.getLocal(session.list_pen, true)
        const arr = lista_category.reduce((a, b) => {
          if (typeof lista_category[b.name] !== 'undefined' && lista_category[b.name].length){
            a.push(b);
          }
          return a;
        }, []);
        if (arr.length) {
          hasData = true;
        }
      }
    }

    return hasData
  }

  confir(action: () => void, title: string, templa: string) {
    this.confirmarDelete.fire({
      preConfirm: action,
      html: templa,
      title: `<strong>${title}</strong>`
    });
  }

  confirmDeleteData(value, callback: any = false) {
    let msj = '';
    if (value == 'ruta') {
      msj = 'Si cambias de Ruta, todos los datos que no hayan sido guardados se perderán. ¿Desea continuar?';
    } else if(value == 'logout') {
      msj = 'Tienes Pedidos o Cobranzas pendientes por sincronizar. ¿Desea continuar?'
    }
    this.confir(() => {
      this.close_confirmar().then(() => {
        callback(true);
      });
    }, 'Información por enviar.',
    msj);
  }

  close_confirmar(): Promise<boolean> {
    return new Promise((success, error) => {
      let checkAlert = setInterval(() => {
        if (!this.confirmarDelete.isVisible()) {
          success(true);
          clearInterval(checkAlert);
          checkAlert = null;
        }
      }, 500);
    });
  }

  removeDataCache() {
    Cache.removeItem(session.clientes);
    Cache.removeItem(session.agenda);
    Cache.removeItem(session.planificacion);
    Cache.removeItem(session.plan);
    Cache.removeItem(session.cuentas);
    Cache.removeItem(session.tcliente);
    Cache.removeItem(session.bancoNacional);
    Cache.removeItem(session.bancoPrivado);
    Cache.removeItem(session.tipoCobro);
    Cache.removeItem(session.filtros);
    Cache.removeItem(session.motivos);
    Cache.removeItem(session.visitas);
    Cache.removeItem(session.productos);
    Cache.removeItem(session.cobranzas);
    Cache.removeItem(session.recibos);
    Cache.removeItem(session.cart_pedidos);
    Cache.removeItem(session.catalog_pedidos);
    Cache.removeItem(session.control_autoventa);
    Cache.removeItem(session.facturas_autoventa);
    Cache.removeItem(session.monedas);
    Cache.removeItem(session.descuentos);
    Cache.removeItem(session.visitados);
    Cache.removeItem(session.noVisitados);
    Cache.removeItem(session.visitaActiva);
    Cache.removeItem(session.redir);
  }

  deleteDataCache(callback: any = false, tipo='ruta') {
    if (this.poseeDatosPorEnviar(false)) {
      if(tipo == 'elegir_modo'){
        this.removeDataCache();
        callback(true);
      }else{
        this.confirmDeleteData(tipo, (call)=> {
          if (call) {
            this.removeDataCache();
            callback(true);
          }
        });
      }
    } else {
      this.removeDataCache();
      callback(true);
    }
  }

  removeData() {
    this.clientes = null;
    this.agenda = null;
    this.planificacion = null;
    this.plan = null;
    this.cuentas = null;
    this.tcliente = null;
    this.bancoNacional = null;
    this.bancoPrivado = null;
    this.tipoCobro = null;
    this.filtros = null;
    this.motivos = null;
    this.visitas = null;
    this.productos = null;
    this.pedidos = null;
    this.cobranzas = null;
    this.monedas = null;
    this.facturas = null;
    this.descuentos = [];
    this.redir = null;
  }

  set cart_pedidos(cart: CartPedidos) {
    Cache.setLocal(session.cart_pedidos, cart);
  }

  get cart_pedidos(): CartPedidos {
    return Cache.getLocal(session.cart_pedidos, true);
  }

  set catalog_pedidos(catalog: any) {
    Cache.setLocal(session.catalog_pedidos, catalog);
  }

  get catalog_pedidos(): any {
    return Cache.getLocal(session.catalog_pedidos, true);
  }

  set clientes(cli: Clientes) {
    Cache.setLocal(session.clientes, cli);
    this._clientes = cli;
  }

  get clientes(): Clientes {
    if (Cache.getLocal(session.clientes, true) === null) {
      return this._clientes;
    } else {
      return Cache.getLocal(session.clientes, true).obj;
    }
  }

  get all_products(): boolean {
    if (Cache.getLocal(session.all_products, true) === null) {
      return this._all_products;
    } else {
      return Cache.getLocal(session.all_products, true);
    }
  }

  set all_products(all_products: boolean) {
    Cache.setLocal(session.all_products, all_products);
    this._all_products = all_products;
  }

  get type_prices(): boolean {
    if (Cache.getLocal(session.type_prices, true) === null) {
      return this._type_prices;
    } else {
      return Cache.getLocal(session.type_prices, true);
    }
  }

  set type_prices(type_prices: boolean) {
    Cache.setLocal(session.type_prices, type_prices);
    this._all_products = type_prices;
  }

  get saldore_pedidos(): boolean {
    if (Cache.getLocal(session.saldore_pedidos, true) === null) {
      return this._saldore_pedidos;
    } else {
      return Cache.getLocal(session.saldore_pedidos, true);
    }
  }

  set saldore_pedidos(saldore_pedidos: boolean) {
    Cache.setLocal(session.saldore_pedidos, saldore_pedidos);
    this._saldore_pedidos = saldore_pedidos;
  }

  get precios_iva(): boolean {
    if (Cache.getLocal(session.precios_iva, true) === null) {
      return this._precios_iva;
    } else {
      return Cache.getLocal(session.precios_iva, true);
    }
  }

  set precios_iva(precios_iva: boolean) {
    Cache.setLocal(session.precios_iva, precios_iva);
    this._precios_iva = precios_iva;
  }

  get config_bloq_pedi(): boolean {
    if (Cache.getLocal(session.config_bloq_pedi, true) === null) {
      return this._config_bloq_pedi;
    } else {
      return Cache.getLocal(session.config_bloq_pedi, true);
    }
  }

  set config_bloq_pedi(config_bloq_pedi: boolean) {
    Cache.setLocal(session.config_bloq_pedi, config_bloq_pedi);
    this._config_bloq_pedi = config_bloq_pedi;
  }

  get multiplos_pedidos(): boolean {
    if (Cache.getLocal(session.multiplos_pedidos, true) === null) {
      return this._multiplos_pedidos;
    } else {
      return Cache.getLocal(session.multiplos_pedidos, true);
    }
  }

  set multiplos_pedidos(multiplos_pedidos: boolean) {
    Cache.setLocal(session.multiplos_pedidos, multiplos_pedidos);
    this._multiplos_pedidos = multiplos_pedidos;
  }

  set agenda(agen: Agendas) {
    Cache.setLocal(session.agenda, agen);
    this._agenda = agen;
  }

  get agenda(): Agendas {
    if (Cache.getLocal(session.agenda, true) === null) {
      return this._agenda;
    } else {
      return Cache.getLocal(session.agenda, true).obj;
    }
  }

  set monedas(mone: Monedas) {
    Cache.setLocal(session.monedas, mone);
    this._monedas = mone;
  }

  get monedas(): Monedas {
    if (Cache.getLocal(session.monedas, true) === null) {
      return this._monedas;
    } else {
      return Cache.getLocal(session.monedas, true).obj;
    }
  }

  set descuentos(mone: Descuentos) {
    Cache.setLocal(session.descuentos, mone);
    this._descuentos = mone;
  }

  get descuentos(): Descuentos {
    if (Cache.getLocal(session.descuentos, true) === null) {
      return this._descuentos;
    } else {
      return Cache.getLocal(session.descuentos, true).obj;
    }
  }

  set redir(link: number) {
    Cache.setLocal(session.redir, link);
    this._redir = link;
  }

  get redir(): number {
    if (Cache.getLocal(session.redir, true) === null) {
      return this._redir;
    } else {
      return Cache.getLocal(session.redir, true);
    }
  }

  set control_autoventa(fact: any) {
    Cache.setLocal(session.control_autoventa, fact);
    this._control_autoventa = fact;
  }

  get control_autoventa() {
    if (Cache.getLocal(session.control_autoventa, true) === null) {
      return this._control_autoventa;
    } else {
      return Cache.getLocal(session.control_autoventa, true);
    }
  }

  set facturas_autoventa(fact: any) {
    Cache.setLocal(session.facturas_autoventa, fact);
    this._facturas_autoventa = fact;
  }

  get facturas_autoventa() {
    if (Cache.getLocal(session.facturas_autoventa, true) === null) {
      return this._facturas_autoventa;
    } else {
      return Cache.getLocal(session.facturas_autoventa, true);
    }
  }

  get facturas(): Facturas {
    if (Cache.getLocal(session.facturas, true) === null) {
      return this._facturas;
    } else {
      return Cache.getLocal(session.facturas, true);
    }
  }

  set facturas(fact: Facturas) {
    Cache.setLocal(session.facturas, fact);
    this._facturas = fact;
  }

  get act_factura(): ActFactura {
    if (Cache.getLocal(session.act_factura, true) === null) {
      return this._act_factura;
    } else {
      return Cache.getLocal(session.act_factura, true);
    }
  }

  set act_factura(actfact: ActFactura) {
    Cache.setLocal(session.act_factura, actfact);
    this._act_factura = actfact;
  }

  set planificacion(plan: Planificaciones) {
    Cache.setLocal(session.planificacion, plan);
    this._planificacion = plan;
  }

  get planificacion(): Planificaciones {
    if (Cache.getLocal(session.planificacion, true) === null) {
      return this._planificacion;
    } else {
      return Cache.getLocal(session.planificacion, true).obj;
    }
  }

  set plan(plan: Plans) {
    Cache.setLocal(session.plan, plan);
    this._plan = plan;
  }

  get plan(): Plans {
    if (Cache.getLocal(session.plan, true) === null) {
      return this._plan;
    } else {
      return Cache.getLocal(session.plan, true).obj;
    }
  }

  set cuentas(cuentas: Cuentas) {
    Cache.setLocal(session.cuentas, cuentas);
    this._cuentas = cuentas;
  }

  get cuentas(): Cuentas {
    if (Cache.getLocal(session.cuentas, true) === null) {
      return this._cuentas;
    }else{
      return Cache.getLocal(session.cuentas, true).obj;
    }
  }

  set tcliente(tcliente: TipoClientes) {
    Cache.setLocal(session.tcliente, tcliente);
    this._tcliente = tcliente;
  }

  get tcliente(): TipoClientes {
    if (Cache.getLocal(session.tcliente, true) === null) {
      return this._tcliente;
    } else {
      return Cache.getLocal(session.tcliente, true).obj;
    }
  }

  set bancoNacional(bancoNacional: BancoNacionales) {
    Cache.setLocal(session.bancoNacional, bancoNacional);
    this._bancoNacional = bancoNacional;
  }

  get bancoNacional(): BancoNacionales {
    if (Cache.getLocal(session.bancoNacional, true) === null) {
      return this._bancoNacional;
    } else {
      return Cache.getLocal(session.bancoNacional, true).obj;
    }
  }

  set bancoPrivado(bancoPrivado: BancoPrivados) {
    Cache.setLocal(session.bancoPrivado, bancoPrivado);
    this._bancoPrivado = bancoPrivado;
  }

  get bancoPrivado(): BancoPrivados {
    if (Cache.getLocal(session.bancoPrivado, true) === null) {
      return this._bancoPrivado;
    } else {
      return Cache.getLocal(session.bancoPrivado, true).obj;
    }
  }

  set tipoCobro(tipoCobro: TipoCobros) {
    Cache.setLocal(session.tipoCobro, tipoCobro);
    this._tipoCobro = tipoCobro;
  }

  get tipoCobro(): TipoCobros {
    if (Cache.getLocal(session.tipoCobro, true) === null) {
      return this._tipoCobro;
    } else {
      return Cache.getLocal(session.tipoCobro, true).obj;
    }
  }

  set filtros(filtros: Filtro) {
    Cache.setLocal(session.filtros, filtros);
    this._filtros = filtros;
  }

  get filtros(): Filtro {
    if (Cache.getLocal(session.filtros, true) === null) {
      return this._filtros;
    } else {
      return Cache.getLocal(session.filtros, true).obj;
    }
  }

  set motivos(motivos: Motivo) {
    Cache.setLocal(session.motivos, motivos);
    this._motivos = motivos;
  }

  get motivos(): Motivo {
    if (Cache.getLocal(session.motivos, true) === null) {
      return this._motivos;
    } else {
      return Cache.getLocal(session.motivos, true).obj;
    }
  }

  set visitas(visitas: Visitas) {
    Cache.setLocal(session.visitas, visitas);
    this._visitas = visitas;
  }

  get visitas(): Visitas {
    if (Cache.getLocal(session.visitas, true) === null) {
      return this._visitas;
    } else {
      return Cache.getLocal(session.visitas, true);
    }
  }

  set productos(productos: Productos) {
    Cache.setLocal(session.productos, productos);
    this._productos = productos;
  }

  get productos(): Productos {
    if (Cache.getLocal(session.productos, true) === null) {
      return this._productos;
    } else {
      return Cache.getLocal(session.productos, true).obj;
    }
  }

  set pedidos(pedidos: Pedidos) {
    Cache.setLocal(session.pedidos, pedidos);
    this._pedidos = pedidos;
  }

  get pedidos(): Pedidos {
    if (Cache.getLocal(session.pedidos, true) === null) {
      return this._pedidos;
    } else {
      return Cache.getLocal(session.pedidos, true);
    }
  }

  
  set cobranzas(cobranzas: Cobranza) {
    Cache.setLocal(session.cobranzas, cobranzas);
    this._cobranzas = cobranzas;
  }

  get cobranzas(): Cobranza {
    if (Cache.getLocal(session.cobranzas, true) === null) {
      return this._cobranzas;
    } else {
      return Cache.getLocal(session.cobranzas, true);
    }
  }

  set recibos(recibos: Cobrado) {
    Cache.setLocal(session.recibos, recibos);
    this._recibos = recibos;
  }

  get recibos(): Cobrado {
    if (Cache.getLocal(session.recibos, true) === null) {
      return this._recibos;
    } else {
      return Cache.getLocal(session.recibos, true);
    }
  }
}
