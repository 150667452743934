//Constantes para las rutas o urls de las vistas

export const rooturl = {//Ruta base, ruta de apps, modulos y apartados fijos
    bn360: 'n360',
    btdp: 'vel',
    vel_adm: 'vel_adm',
    catalogos: 'catalogos',
}

export const rutabase = {//Usualmente para los routing.modules
    //Rutas especiales
    notfound: 'notfound',
    //Para negocio 360
    bn360: rooturl.bn360,
    visitas_adm: `${rooturl.vel_adm}/visitas`,
    cobranzas_adm: `${rooturl.vel_adm}/cobranzas`,
    asignarutas: `${rooturl.vel_adm}/configuracion`,
    gestion_almacen: `${rooturl.vel_adm}/gestion_almacen`,
    pedidos_adm: `${rooturl.vel_adm}/pedidos`,
    reportes_adm: `${rooturl.vel_adm}/reportes`,
    facturas_adm: `${rooturl.vel_adm}/facturas/:id`,
    autoventa_adm: `${rooturl.vel_adm}/autoventa/:id`,
    autoventa_formato: `${rooturl.vel_adm}/formato_facturacion`,
    estadistica_adm: `${rooturl.vel_adm}/estadistica/balance_transaccion`,
    libro_de_rutas_adm: `${rooturl.vel_adm}/libro_de_rutas`,
    notificaciones: `${rooturl.bn360}/notificaciones`,
    toma_inventario: `${rooturl.bn360}/gestionar-almacen/toma-inventario`,
    rep_gerenciales_av: `reportes_gerenciales/analisis_de_vencimiento`,
    rep_gerenciales_vd: `reportes_gerenciales/ventas_diarias`,
    //Para app TDP
    btdp: rooturl.btdp,
    elegir_modo: `${rooturl.btdp}/elegir_modo`, //Url especial
    espera_indb: `${rooturl.btdp}/espera_indb`, //Url especial
    select_ruta: `selectruta`,
    cat_clientes: `${rooturl.catalogos}/clientes`,
    cat_clientes_detalle: `${rooturl.catalogos}/clientes/detalle`,
    cat_productos: `${rooturl.catalogos}/productos`,
    cat_visitas: `${rooturl.catalogos}/visitas`,
    cat_visitas_detalle: `${rooturl.catalogos}/visitas/detalle`,
    cat_pedidos: `${rooturl.catalogos}/pedidos`,
    cat_autoventa: `${rooturl.catalogos}/autoventa`,
    cat_facturas: `${rooturl.catalogos}/facturas`,
    reportes: `reports`,
    orders: `orders`,
    autosell: `autosell`,
    invoice: `invoice`,
    barscanner: 'barscanner',
    visitas: 'visitas',
    cat_cobranzas: `${rooturl.catalogos}/cobranzas`,
}

export const rutasUrl = {//Usualmente para los router (redirecciones)
    //Rutas especiales
    notfound: `/${rutabase.notfound}/`,
    //Para negocio 360
    homen360: `/${rutabase.bn360}/`,
    notificaciones: `/${rutabase.notificaciones}/`,
    rep_gerenciales_av: `/${rutabase.rep_gerenciales_av}/`,
    rep_gerenciales_vd: `/${rutabase.rep_gerenciales_vd}/`,
    //Para app TDP
    hometdp: `/${rutabase.btdp}/`,
    elegir_modo: `/${rutabase.elegir_modo}/`,
    espera_indb: `/${rutabase.espera_indb}/`,
    select_ruta: `/${rutabase.btdp}/${rutabase.select_ruta}/`,
    cat_clientes: `/${rutabase.btdp}/${rutabase.cat_clientes}/`,
    cat_clientes_detalle: `/${rutabase.btdp}/${rutabase.cat_clientes_detalle}/`,
    cat_productos: `/${rutabase.btdp}/${rutabase.cat_productos}/`,
    cat_visitas: `/${rutabase.btdp}/${rutabase.cat_visitas}/`,
    cat_visitas_detalle: `/${rutabase.btdp}/${rutabase.cat_visitas_detalle}/`,
    cat_facturas: `/${rutabase.btdp}/${rutabase.cat_facturas}/`,
    cat_pedidos: `/${rutabase.btdp}/${rutabase.cat_pedidos}/`,
    cat_autoventa: `/${rutabase.btdp}/${rutabase.cat_autoventa}/`,
    reportes: `/${rutabase.btdp}/${rutabase.reportes}/`,
    orders: `/${rutabase.btdp}/${rutabase.orders}/`,
    invoice: `/${rutabase.btdp}/${rutabase.invoice}/`,
    autosell: `/${rutabase.btdp}/${rutabase.autosell}/`,
    visitas: `/${rutabase.btdp}/${rutabase.visitas}/`,
    cat_cobranzas: `/${rutabase.btdp}/${rutabase.cat_cobranzas}/`,
    toma_inventario: `${rooturl.bn360}/gestionar-almacen/toma-inventario`,
}